import React, { useState, useEffect } from 'react';
import {
  TextField, Button, MenuItem, Select, FormControl, InputLabel,
  Avatar, Box, Typography, IconButton, Chip, Checkbox, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Menu, Autocomplete,
  FormHelperText, useTheme
} from '@mui/material';
import { PhotoCamera, HelpOutline, Edit, Clear } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// === Imports des deux Select personnalisés ===
import SelectSelectionNational from '../components/SelectSelectionNational.js';
import NationalitiesSelect from '../components/NationalitiesSelect';
import NationalitiesSelect2 from '../components/NationalitiesSelect2';

// Composant pour capturer une photo
const PhotoCapture = ({ onCapture }) => {
  const handlePhotoCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Redimensionnement de l'image avant l'envoi
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          const MAX_DIMENSION = 800;
          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onCapture(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-capture"
        type="file"
        capture="environment"
        onChange={handlePhotoCapture}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-capture">
        <Button
          variant="contained"
          component="span"
          startIcon={<PhotoCamera />}
          fullWidth
        >
          Tomar foto
        </Button>
      </label>
    </Box>
  );
};

// Composant pour sélectionner une photo depuis la bibliothèque
const PhotoSelection = ({ onSelect }) => {
  const handlePhotoSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Même logique de redimensionnement que dans PhotoCapture
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          const MAX_DIMENSION = 800;
          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onSelect(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-select"
        type="file"
        onChange={handlePhotoSelect}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-select">
        <Button
          variant="contained"
          component="span"
          startIcon={<Edit />}
          fullWidth
        >
          Seleccionar foto
        </Button>
      </label>
    </Box>
  );
};

// Composant pour le dialogue de modification de photo
const PhotoEditDialog = ({ open, onClose, onCapture, onSelect }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        Cambiar foto
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <PhotoCapture onCapture={(file) => {
            onCapture(file);
            onClose();
          }} />
          <PhotoSelection onSelect={(file) => {
            onSelect(file);
            onClose();
          }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

// Rôles défensifs
const defensiveRoles = [
  "1x1 Intenso",
  "Defensor Perimetral", 
  "Wing Stopper",
  "Switch Defensor",
  "Anchor Big",
  "Reboteador Defensivo",
  "Rim Protector",
  "No Defensor"
];

const JEAttributes = [
  "1x1",
  "3-Level Scorer",
  "Actividad sin balón",
  "Atlético",
  "Capacidad en el Pick & Roll",
  "Capacidad para sacar faltas",
  "Clutch",
  "Duro",
  "Energía",
  "Envergadura",
  "Físico",
  "Hustle",
  "IQ/Visión de juego",
  "Juego en el poste bajo",
  "Paint Touches",
  "Penetrador",
  "Puntos",
  "Primer paso",
  "Producción constante",
  "Rebote",
  "Talento",
  "Tirador",
  "Tiro en Catch & Shoot (OTC 3)",
  "Tiro desde la esquina (Corner 3)",
  "Tiro tras Bote",
  "Transiciones/Ritmo en la pista",
  "Trabajo sucio (Blue Collar)",
  "Velocidad/Agilidad",
  "Versatilidad",
  "Verticalidad"
];

const JIAttributes = [
  "1x1",
  "Actividad sin balón",
  "Atlético",
  "Bloqueo, continuación y finalización",
  "Bloqueos eficaces",
  "Buen tacto",
  "Clutch",
  "Duro",
  "Energía",
  "Envergadura",
  "Footwork",
  "Físico",
  "Hustle",
  "IQ/Visión de juego",
  "Juego poste bajo/Juego de cara",
  "Lob Threat",
  "Puntos",
  "Producción constante",
  "Protector del aro",
  "Rebote",
  "Rim-Runner",
  "Talento",
  "Tirador",
  "Tiro en Catch & Shoot (OTC 3)",
  "Tiro desde la esquina + P&Pop",
  "Tiro tras bote",
  "Trabajo sucio (Blue Collar)",
  "Transiciones/Ritmo en la pista",
  "Varios esfuerzos en la misma acción",
  "Velocidad/Agilidad",
  "Versatilidad",
  "Verticalidad"
];

// On définit la liste de propositions pour "Rango Salarial"
const priceRanges = [
  "0-50k€",
  "50-100k€",
  "100-150k€",
  "150-200k€",
  "200-250k€",
  "250-300k€",
  "300-350k€",
  "350-400k€",
  "400-450k€",
  "450-500k€",
  "500-550k€",
  "550-600k€",
  "600-650k€",
  "650-700k€",
  "700-750k€",
  "750-800k€",
  "800-850k€",
  "850-900k€",
  "900-950k€",
  "950k€-1M€",
  "1M€-1.2M€",
  "1.2M€-1.4M€",
  "1.4M€-1.6M€",
  "1.6M€-1.8M€",
  "1.8M€-2M€",
  "2M€-2.2M€",
  "2.2M€-2.4M€",
  "2.4M€-2.5M€"
];

// On définit la liste de propositions pour "Contrato"
const contratos = [
  "2-Way",
  "Exhibit-10",
  "1 año",
  "1+1",
  "2 años",
  "3 años",
  "On Loan"
];

// *** Nouveau champ ***: Buyout
const buyoutOptions = [
  "0-50k€",
  "50-100k€",
  "100-150k€",
  "150-200k€",
  "200-250k€",
  "250-300k€",
  "300-350k€",
  "350-400k€",
  "400-450k€",
  "450-500k€",
  "500-550k€",
  "550-600k€",
  "600-650k€",
  "650-700k€",
  "700-750k€",
  "750-800k€",
  "800-850k€",
  "850-900k€",
  "900-950k€",
  "950k€-1M€",
  "1M€-1.2M€",
  "1.2M€-1.4M€",
  "1.4M€-1.6M€",
  "1.6M€-1.8M€",
  "1.8M€-2M€",
  "2M€-2.2M€",
  "2.2M€-2.4M€",
  "2.4M€-2.5M€"
];

// *** Nouveau champ ***: Origen Genético
const origenGeneticoOptions = [
  "Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.",
  "Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.",
  "Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.",
  "Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.",
  "Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.",
  "Nordafricanos - Tunez, Marruecos, Egipto, etc.",
  "Norteamericanos - Estados Unidos, Canada, etc."
];

const CrearJugador = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // --- État pour détecter si le formulaire a été modifié (dirty) ---
  const [isDirty, setIsDirty] = useState(false);
  const markDirty = () => {
    setIsDirty(true);
  };

  /**
   * GESTION DU BLOCKAGE DE NAVIGATION :
   * - beforeunload (fermeture / reload)
   * - popstate (bouton retour)
   * - pushState / replaceState (navigation interne via React Router)
   */
  useEffect(() => {
    // -------- 1) BLOQUER LA FERMETURE / RELOAD (beforeunload) --------
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue =
          'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // -------- 2) BLOQUER LE BOUTON RETOUR (popstate) --------
    const handlePopState = (event) => {
      if (isDirty) {
        const confirmLeave = window.confirm(
          'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
        );
        if (!confirmLeave) {
          // Annule l'action de retour en "repoussant" l'historique
          window.history.pushState(null, '', window.location.pathname);
        }
      }
    };
    window.addEventListener('popstate', handlePopState);

    // -------- 3) BLOQUER LES NAVIGATIONS INTERNES (pushState / replaceState) --------
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    const confirmNavigation = () => {
      if (!isDirty) return true;
      return window.confirm(
        'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
      );
    };

    window.history.pushState = function (...args) {
      if (confirmNavigation()) {
        return originalPushState.apply(this, args);
      }
      return null;
    };

    window.history.replaceState = function (...args) {
      if (confirmNavigation()) {
        return originalReplaceState.apply(this, args);
      }
      return null;
    };

    // Cleanup
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [isDirty]);

  // Champs existants
  const [nombre, setNombre] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [altura, setAltura] = useState('');
  const [peso, setPeso] = useState('');
  const [puesto, setPuesto] = useState('');
  const [tipo, setTipo] = useState('');
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupos, setSelectedGrupos] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  // Nouveaux champs
  const [nacionalidad, setNacionalidad] = useState('');
  const [segundaNacionalidad, setSegundaNacionalidad] = useState('');
  const [seleccionNacional, setSeleccionNacional] = useState('');
  const [jeJiType, setJeJiType] = useState('');
  const [jeJiAttributes, setJeJiAttributes] = useState([]);
  const [mano, setMano] = useState('');
  const [rolOfensivo, setRolOfensivo] = useState([]);
  const [rolDefensivo, setRolDefensivo] = useState([]);
  const [pasaporte, setPasaporte] = useState('');   // <-- Licencia
  const [estatus, setEstatus] = useState('');
  const [equipo, setEquipo] = useState('');
  const [liga1, setLiga1] = useState('');
  const [liga2, setLiga2] = useState('');
  const [equipoPreDraft, setEquipoPreDraft] = useState('');
  const [añoDraft, setAñoDraft] = useState('');
  const [posicionDraft, setPosicionDraft] = useState('');
  const [experienciaAcb, setExperienciaAcb] = useState('');
  const [nbaExperience, setNbaExperience] = useState('');
  const [nivel, setNivel] = useState('');
  const [proyeccion, setProyeccion] = useState('');
  const [agencia, setAgencia] = useState('');
  const [comparacionPro, setComparacionPro] = useState('');
  const [comportamiento, setComportamiento] = useState('');
  const [injury, setInjury] = useState('');
  const [rangoPrecios, setRangoPrecios] = useState('');
  const [contrato, setContrato] = useState('');
  const [url, setUrl] = useState('');
  const [pit, setPit] = useState('');
  const [eurocamp, setEurocamp] = useState('');

  // *** Nouveaux champs ajoutés récemment ***
  const [experiencaEuropa, setExperiencaEuropa] = useState('');
  const [shootingRange, setShootingRange] = useState('');
  const [competicionEuropa, setCompeticionEuropa] = useState('');

  // *** Nouveau champ ***: SL Vegas
  const [slVegas, setSlVegas] = useState('');

  // *** Nouveau champ ***: Experiencia Fuera de su País
  const [experienciaFuera, setExperienciaFuera] = useState('');

  // *** Nouveau champ ***: Experiencia NCAA
  const [experiencaNcaa, setExperiencaNcaa] = useState('');

  // *** Nouveau champ ***: Origen Genético
  const [origenGenetico, setOrigenGenetico] = useState('');

  // *** Nouveau champ ***: Buyout
  const [buyout, setBuyout] = useState('');

  const [createdPlayerId, setCreatedPlayerId] = useState(null);

  // Gestion photo
  const [selectedImage, setSelectedImage] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);

  // Spinner de chargement
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Données backend
  const [teams, setTeams] = useState([]);
  const [leagues1, setLeagues1] = useState([]);
  const [leagues2, setLeagues2] = useState([]);
  const [agencies, setAgencies] = useState([]);

  // Menu après création
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleMenuItemClick = (type) => {
    if (!createdPlayerId) {
      alert("No hay jugador creado aún.");
      return;
    }
    navigate(`/crear-ficha?playerId=${createdPlayerId}&cardType=${type}`);
    setAnchorEl(null);
    setSuccessModalOpen(false);
  };

  // useEffect : chargement Teams, Leagues, Agencies, Groups
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/teams`, { params: { section } });
        setTeams(response.data.teams || []);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchLeagues1 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues1`, { params: { section } });
        setLeagues1(response.data.leagues1 || []);
      } catch (error) {
        console.error('Error fetching leagues1:', error);
      }
    };

    const fetchLeagues2 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues2`, { params: { section } });
        setLeagues2(response.data.leagues2 || []);
      } catch (error) {
        console.error('Error fetching leagues2:', error);
      }
    };

    const fetchAgencies = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/agencies`, { params: { section } });
        setAgencies(response.data.agencies || []);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    fetchTeams();
    fetchLeagues1();
    fetchLeagues2();
    fetchAgencies();
  }, [backendUrl, section]);

  useEffect(() => {
    const fetchGrupos = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/groups`, { params: { section } });
        setGrupos(response.data);
      } catch (error) {
        console.error("Error al obtener los grupos:", error);
      }
    };
    fetchGrupos();
  }, [backendUrl, section]);

  // Calcul auto de l'année de naissance
  const calcularAñoNacimiento = (fecha) => {
    if (!fecha) return '';
    return new Date(fecha).getFullYear().toString();
  };

  // Reset du formulaire
  const resetForm = () => {
    setNombre('');
    setFechaNacimiento('');
    setAltura('');
    setPeso('');
    setPuesto('');
    setTipo('');
    setSelectedGrupos([]);
    setSelectedImage(null);
    setAvatarPreview(null);
    setNacionalidad('');
    setSegundaNacionalidad('');
    setSeleccionNacional('');
    setJeJiType('');
    setJeJiAttributes([]);
    setMano('');
    setRolOfensivo([]);
    setRolDefensivo([]);
    setPasaporte('');
    setEstatus('');
    setEquipo('');
    setLiga1('');
    setLiga2('');
    setEquipoPreDraft('');
    setAñoDraft('');
    setPosicionDraft('');
    setExperienciaAcb('');
    setNbaExperience('');
    setNivel('');
    setProyeccion('');
    setAgencia('');
    setComparacionPro('');
    setComportamiento('');
    setInjury('');
    setRangoPrecios('');
    setContrato('');
    setUrl('');
    setPit('');
    setEurocamp('');

    setExperiencaEuropa('');
    setShootingRange('');
    setCompeticionEuropa('');

    setSlVegas('');
    setExperienciaFuera('');
    setExperiencaNcaa('');
    setOrigenGenetico('');
    setBuyout('');

    // On remet isDirty à false
    setIsDirty(false);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCloseModal = () => {
    setErrorModalOpen(false);
    setErrorMessage('');
  };

  // Soumission du formulaire
  const handleSubmit = async () => {
    // Validations minimalistes
    if (!nombre.trim()) {
      setErrorMessage('El nombre es obligatorio');
      setErrorModalOpen(true);
      return;
    }
    if (!tipo) {
      setErrorMessage('El tipo de jugador es obligatorio');
      setErrorModalOpen(true);
      return;
    }
    if (!fechaNacimiento) {
      setErrorMessage('La fecha de nacimiento es obligatoria');
      setErrorModalOpen(true);
      return;
    }
    if (!altura) {
      setErrorMessage('La altura es obligatoria');
      setErrorModalOpen(true);
      return;
    }
    if (!puesto) {
      setErrorMessage('La posición es obligatoria');
      setErrorModalOpen(true);
      return;
    }
    if (tipo === 'cantera' && selectedGrupos.length === 0) {
      setErrorMessage('Debe seleccionar al menos un grupo para un jugador de tipo cantera');
      setErrorModalOpen(true);
      return;
    }

    const formData = new FormData();

    formData.append('nom', nombre.trim());
    formData.append('annee_naissance', calcularAñoNacimiento(fechaNacimiento));
    formData.append('date_naissance', fechaNacimiento);
    formData.append('taille', altura || null);
    formData.append('poids', peso || null);
    formData.append('poste', puesto || null);
    formData.append('type', tipo);
    formData.append('section', section);

    // Nationalités
    formData.append('nationalite', nacionalidad || null);
    formData.append('nationalite_2', segundaNacionalidad || null);
    formData.append('selection_national', seleccionNacional || null);

    // Origen Genético
    formData.append('origen_genetico', origenGenetico || null);

    // JE / JI
    formData.append('je_ji', jeJiType || '');
    const jeJiAttrValue = jeJiAttributes.length > 0 ? jeJiAttributes.join(',') : null;
    formData.append('selection_je_ji', jeJiAttrValue || '');

    // Rôles
    formData.append('mano', mano || null);
    if (rolOfensivo.length > 0) {
      formData.append('offensive_role', rolOfensivo.join(','));
    }
    if (rolDefensivo.length > 0) {
      formData.append('defensive_role', rolDefensivo.join(','));
    }

    // Licencia
    formData.append('passport', pasaporte || null);

    formData.append('statut', estatus || null);
    formData.append('team', equipo || '');
    formData.append('league_1', liga1 || '');
    formData.append('league_2', liga2 || null);

    formData.append('pre_draft_team', equipoPreDraft || null);
    formData.append('draft_year', añoDraft || null);
    formData.append('draft_pick', posicionDraft || null);

    formData.append('acb_experience', experienciaAcb || null);
    formData.append('nba_experience', nbaExperience || null);

    formData.append('level', nivel || null);
    formData.append('proyeccion', proyeccion || null);
    formData.append('agency', agencia || null);
    formData.append('pro_comparaison', comparacionPro || null);
    formData.append('flag_behaviour', comportamiento || null);
    formData.append('injury', injury || null);
    formData.append('price_range', rangoPrecios || null);
    formData.append('contract', contrato || null);
    formData.append('url', url || null);
    formData.append('pit', pit || null);
    formData.append('eurocamp', eurocamp || null);

    // SL Vegas
    formData.append('slvegas', slVegas || null);

    // Expériences
    formData.append('experienca_europa', experiencaEuropa || null);
    formData.append('shooting_range', shootingRange || null);
    formData.append('competicion_europa', competicionEuropa || null);

    formData.append('experienca_fuera', experienciaFuera || null);
    formData.append('experienca_ncaa', experiencaNcaa || null);

    // Buyout
    formData.append('buyout', buyout || null);

    // Groupes
    formData.append('groupes', JSON.stringify(selectedGrupos));

    // Image
    if (selectedImage) {
      formData.append('avatar', selectedImage);
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${backendUrl}/api/players?section=${section}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      const createdPlayer = response.data;
      const newPlayerId = createdPlayer.playerId;

      setIsSubmitting(false);
      setCreatedPlayerId(newPlayerId);
      setSuccessModalOpen(true);

      // On remet tout à zéro (et isDirty à false)
      resetForm();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      setIsSubmitting(false);
      setErrorMessage(error.response?.data?.message || 'Error al crear el jugador');
      setErrorModalOpen(true);
    }
  };

  // Gestion de la capture photo
  const handlePhotoCapture = (file) => {
    setSelectedImage(file);
    setAvatarPreview(URL.createObjectURL(file));
    markDirty();
    setOpenPhotoDialog(false);
  };

  // Gestion de la sélection photo
  const handlePhotoSelect = (file) => {
    setSelectedImage(file);
    setAvatarPreview(URL.createObjectURL(file));
    markDirty();
    setOpenPhotoDialog(false);
  };

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', mt: 4, p: 3, border: '1px solid #ddd', borderRadius: '8px' }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2
      }}>
        <Typography variant="h5">Crear Jugador</Typography>
        <IconButton 
          onClick={() => setTutorialOpen(true)} 
          color="primary"
          sx={{ ml: 2 }}
        >
          <HelpOutline />
        </IconButton>
      </Box>

      {/* Avatar */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Box sx={{ position: 'relative' }}>
          <Avatar
            sx={{ width: 100, height: 100 }}
            src={avatarPreview}
            alt="Avatar"
          />
          <IconButton
            sx={{
              position: 'absolute',
              bottom: -10,
              right: -10,
              backgroundColor: 'background.paper',
              boxShadow: 1,
              '&:hover': { backgroundColor: 'background.paper' }
            }}
            onClick={() => setOpenPhotoDialog(true)}
          >
            <Edit />
          </IconButton>
        </Box>
      </Box>

      <PhotoEditDialog
        open={openPhotoDialog}
        onClose={() => setOpenPhotoDialog(false)}
        onCapture={handlePhotoCapture}
        onSelect={handlePhotoSelect}
      />

      {/* 1. INFORMACIÓN BÁSICA */}
      <Typography variant="h6" sx={{ mb: 2 }}>Información Básica</Typography>

      {/* Ligne 1 : Nombre - Tipo */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)'
          }
        }}
      >
        {/* Nombre */}
        <TextField
          label="Nombre"
          fullWidth
          value={nombre}
          onChange={(e) => {
            setNombre(e.target.value);
            markDirty();
          }}
          error={nombre.trim() === ''}
          helperText={nombre.trim() === '' ? 'El nombre es obligatorio' : ''}
          inputProps={{ style: { textTransform: 'uppercase' } }}
        />

        {/* Grupo (= Tipo) */}
        <FormControl fullWidth error={!tipo}>
          <InputLabel>Grupo </InputLabel>
          <Select
            value={tipo}
            onChange={(e) => {
              setTipo(e.target.value);
              markDirty();
            }}
            label="Grupo "
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setTipo('');
                  markDirty();
                }}
                sx={{ 
                  visibility: tipo ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="pro">Pro</MenuItem>
            <MenuItem value="cantera">Cantera</MenuItem>
          </Select>
          {!tipo && (
            <FormHelperText>El tipo de jugador es obligatorio</FormHelperText>
          )}
        </FormControl>
      </Box>

      {/* Sélecteur de groupes (multiple) */}
      <FormControl 
        fullWidth
        sx={{ mt: 2 }}
        error={tipo === 'cantera' && selectedGrupos.length === 0}
      >
        <InputLabel>Grupos</InputLabel>
        <Select
          multiple
          value={selectedGrupos}
          onChange={(e) => {
            setSelectedGrupos(e.target.value);
            markDirty();
          }}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((id) => {
                const grupo = grupos.find((g) => g.id === id);
                return (
                  <Chip
                    key={id}
                    label={grupo ? grupo.libelle : ''}
                    sx={{
                      backgroundColor: grupo ? grupo.code_couleur : 'grey',
                      color: '#fff'
                    }}
                  />
                );
              })}
            </Box>
          )}
          endAdornment={
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedGrupos([]);
                markDirty();
              }}
              sx={{ 
                visibility: selectedGrupos.length > 0 ? 'visible' : 'hidden',
                position: 'absolute',
                right: 32
              }}
              size="small"
            >
              <Clear fontSize="small" />
            </IconButton>
          }
        >
          {grupos.map((grupo) => (
            <MenuItem key={grupo.id} value={grupo.id}>
              <Checkbox checked={selectedGrupos.indexOf(grupo.id) > -1} />
              <ListItemText primary={grupo.libelle} />
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  backgroundColor: grupo.code_couleur,
                  borderRadius: '50%',
                  marginLeft: 1,
                }}
              />
            </MenuItem>
          ))}
        </Select>
        {tipo === 'cantera' && selectedGrupos.length === 0 && (
          <FormHelperText>Debe seleccionar al menos un grupo</FormHelperText>
        )}
      </FormControl>

      {/* Ligne 2 : Peso - Altura */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr',
            lg: '1fr 1fr 1fr'
          }
        }}
      >
        {/* Peso (kg) */}
        <TextField
          label="Peso (kg)"
          type="number"
          fullWidth
          value={peso}
          onChange={(e) => {
            setPeso(e.target.value);
            markDirty();
          }}
        />

        {/* Altura (cm) */}
        <TextField
          label="Altura (cm)"
          type="number"
          fullWidth
          value={altura}
          onChange={(e) => {
            setAltura(e.target.value);
            markDirty();
          }}
          error={!altura}
          helperText={!altura ? 'La altura es obligatoria' : ''}
        />
      </Box>

      {/* Ligne 3 : Nacionalidad / 2a Nacionalidad / Origen Genético */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr 1fr'
          }
        }}
      >
        <Box>
          <NationalitiesSelect 
            value={nacionalidad}
            onChange={(e) => {
              setNacionalidad(e.target.value);
              markDirty();
            }}
          />
        </Box>

        <Box>
          <NationalitiesSelect2 
            value={segundaNacionalidad}
            onChange={(e) => {
              setSegundaNacionalidad(e.target.value);
              markDirty();
            }}
          />
        </Box>

        {/* *** Origen Genético *** */}
        <FormControl fullWidth>
          <InputLabel>Origen Genético</InputLabel>
          <Select
            value={origenGenetico}
            onChange={(e) => {
              setOrigenGenetico(e.target.value);
              markDirty();
            }}
            label="Origen Genético"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOrigenGenetico('');
                  markDirty();
                }}
                sx={{ 
                  visibility: origenGenetico ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            {origenGeneticoOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Ligne 4 : Fecha de nacimiento + Licencia */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr'
          }
        }}
      >
        {/* Fecha de Nacimiento */}
        <TextField
          label="Fecha de nacimiento"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={fechaNacimiento}
          onChange={(e) => {
            setFechaNacimiento(e.target.value);
            markDirty();
          }}
          error={!fechaNacimiento}
          helperText={!fechaNacimiento ? 'La fecha de nacimiento es obligatoria' : ''}
        />

        {/* Licencia */}
        <FormControl fullWidth>
          <InputLabel>Licencia</InputLabel>
          <Select
            value={pasaporte}
            onChange={(e) => {
              setPasaporte(e.target.value);
              markDirty();
            }}
            label="Licencia"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setPasaporte('');
                  markDirty();
                }}
                sx={{ 
                  visibility: pasaporte ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="EXT">EXT</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="JFL">JFL</MenuItem>
            <MenuItem value="COT">COT</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* 2. CARACTERÍSTICAS DEL JUGADOR */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Características del Jugador</Typography>

      {/* Fila 1 : Posición - Mano Dominante - Shooting Range */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(3, 1fr)',
          }
        }}
      >
        {/* Posición */}
        <FormControl fullWidth error={!puesto}>
          <InputLabel>Posición</InputLabel>
          <Select
            value={puesto}
            onChange={(e) => {
              setPuesto(e.target.value);
              markDirty();
            }}
            label="Posición"
            error={!puesto}
            endAdornment={
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation();
                  setPuesto('');
                  markDirty();
                }}
                sx={{ 
                  visibility: puesto ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="Base">Base</MenuItem>
            <MenuItem value="Escolta">Escolta</MenuItem>
            <MenuItem value="Alero">Alero</MenuItem>
            <MenuItem value="Ala-Pívot">Ala-Pívot</MenuItem>
            <MenuItem value="Pívot">Pívot</MenuItem>
          </Select>
          {!puesto && (
            <FormHelperText>La posición es obligatoria</FormHelperText>
          )}
        </FormControl>

        {/* Mano dominante */}
        <FormControl fullWidth>
          <InputLabel>Mano Dominante</InputLabel>
          <Select
            value={mano}
            onChange={(e) => {
              setMano(e.target.value);
              markDirty();
            }}
            label="Mano Dominante"
            endAdornment={
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation();
                  setMano('');
                  markDirty();
                }}
                sx={{ 
                  visibility: mano ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="Derecha">Derecha</MenuItem>
            <MenuItem value="Izquierda">Izquierda</MenuItem>
            <MenuItem value="Ambas">Ambas</MenuItem>
          </Select>
        </FormControl>

        {/* Shooting Range */}
        <FormControl fullWidth>
          <InputLabel>Shooting Range</InputLabel>
          <Select
            value={shootingRange}
            onChange={(e) => {
              setShootingRange(e.target.value);
              markDirty();
            }}
            label="Shooting Range"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setShootingRange('');
                  markDirty();
                }}
                sx={{ 
                  visibility: shootingRange ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="Rim">Rim</MenuItem>
            <MenuItem value="Mid-Range">Mid-Range</MenuItem>
            <MenuItem value="3Pts">3Pts</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Fila 2 : Perfil Ofensivo - Perfil Defensivo */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr'
          }
        }}
      >
        {/* Perfil Ofensivo */}
        <FormControl fullWidth>
          <InputLabel>Perfil Ofensivo</InputLabel>
          <Select
            multiple
            value={rolOfensivo}
            onChange={(e) => {
              setRolOfensivo(e.target.value);
              markDirty();
            }}
            label="Perfil Ofensivo"
            renderValue={(selected) => selected.join(', ')}
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setRolOfensivo([]);
                  markDirty();
                }}
                sx={{ 
                  visibility: rolOfensivo.length > 0 ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            {/* Guard */}
            <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
              Guard
            </MenuItem>
            <MenuItem value="Generador con balón">Generador con balón</MenuItem>
            <MenuItem value="Generador secundario con balón">Generador secundario con balón</MenuItem>
            <MenuItem value="Anotador Dominante">Anotador Dominante</MenuItem>
            <MenuItem value="Base Director">Base Director</MenuItem>
            <MenuItem value="Tirador Especialista">Tirador Especialista</MenuItem>

            {/* Wing */}
            <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
              Wing
            </MenuItem>
            <MenuItem value="Alero Generador">Alero Generador</MenuItem>
            <MenuItem value="Ala Versátil">Ala Versátil</MenuItem>
            <MenuItem value="Alero Fuerte">Alero Fuerte</MenuItem>
            <MenuItem value="Alero Slasher">Alero Slasher</MenuItem>
            <MenuItem value="Alero Spot-up">Alero Spot-up</MenuItem>
            <MenuItem value="3&D">3&D</MenuItem>

            {/* Forward */}
            <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
              Forward
            </MenuItem>
            <MenuItem value="Ala-Pívot Tradicional">Ala-Pívot Tradicional</MenuItem>
            <MenuItem value="Skilled Forward">Skilled Forward</MenuItem>
            <MenuItem value="4 Abierto">4 Abierto</MenuItem>

            {/* Big */}
            <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
              Big
            </MenuItem>
            <MenuItem value="Pívot Generador">Pívot Generador</MenuItem>
            <MenuItem value="Pívot Versátil">Pívot Versátil</MenuItem>
            <MenuItem value="Pívot Mid-Range">Pívot Mid-Range</MenuItem>
            <MenuItem value="5 Abierto">5 Abierto</MenuItem>
            <MenuItem value="Anotador en el poste bajo">Anotador en el poste bajo</MenuItem>
            <MenuItem value="Pívot finalizador cerca del aro">Pívot finalizador cerca del aro</MenuItem>
            <MenuItem value="Pívot de bloqueo y continuación">Pívot de bloqueo y continuación</MenuItem>
            <MenuItem value="Rol Ofensivo Marginal">Rol Ofensivo Marginal</MenuItem>
          </Select>
        </FormControl>

        {/* Perfil Defensivo */}
        <FormControl fullWidth>
          <InputLabel>Perfil Defensivo</InputLabel>
          <Select
            multiple
            value={rolDefensivo}
            onChange={(e) => {
              setRolDefensivo(e.target.value);
              markDirty();
            }}
            label="Perfil Defensivo"
            renderValue={(selected) => selected.join(', ')}
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setRolDefensivo([]);
                  markDirty();
                }}
                sx={{ 
                  visibility: rolDefensivo.length > 0 ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            {defensiveRoles.map((role) => (
              <MenuItem key={role} value={role}>
                <Checkbox checked={rolDefensivo.indexOf(role) > -1} />
                <ListItemText primary={role} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Fila 3 : Skills JE/JI - Skills JE ou JI */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr'
          }
        }}
      >
        {/* JE / JI */}
        <FormControl fullWidth>
          <InputLabel>Skills JE/JI</InputLabel>
          <Select
            value={jeJiType}
            onChange={(e) => {
              setJeJiType(e.target.value);
              setJeJiAttributes([]);
              markDirty();
            }}
            label="Skills JE/JI"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setJeJiType('');
                  setJeJiAttributes([]);
                  markDirty();
                }}
                sx={{ 
                  visibility: jeJiType ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="JE">JE (Juego  Exterior)</MenuItem>
            <MenuItem value="JI">JI (Juego  Interior)</MenuItem>
          </Select>
        </FormControl>

        {/* Atributos JE/JI */}
        {jeJiType && (
          <FormControl fullWidth>
            <InputLabel>Skills JE ou JI </InputLabel>
            <Select
              multiple
              value={jeJiAttributes}
              onChange={(e) => {
                setJeJiAttributes(e.target.value);
                markDirty();
              }}
              renderValue={(selected) => selected.join(', ')}
              label="Skills JE ou JI "
              endAdornment={
                <IconButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    setJeJiAttributes([]);
                    markDirty();
                  }}
                  sx={{ 
                    visibility: jeJiAttributes.length > 0 ? 'visible' : 'hidden',
                    position: 'absolute',
                    right: 32
                  }}
                  size="small"
                >
                  <Clear fontSize="small" />
                </IconButton>
              }
            >
              {(jeJiType === 'JE' ? JEAttributes : JIAttributes).map((attr) => (
                <MenuItem key={attr} value={attr}>
                  <Checkbox checked={jeJiAttributes.indexOf(attr) > -1} />
                  <ListItemText primary={attr} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {/* Fila 4 : Current VBC Rating - Proyección VBC Rating */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr'
          }
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Current VBC Rating</InputLabel>
          <Select
            value={nivel}
            onChange={(e) => {
              setNivel(e.target.value);
              markDirty();
            }}
            label="Current VBC Rating"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setNivel('');
                  markDirty();
                }}
                sx={{ 
                  visibility: nivel ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            {/* Proyectos */}
            <MenuItem value="Proyecto Nivel Bajo">Proyecto Nivel Bajo</MenuItem>
            <MenuItem value="Proyecto Nivel Medio">Proyecto Nivel Medio</MenuItem>
            <MenuItem value="Proyecto Nivel Bueno">Proyecto Nivel Bueno</MenuItem>
            <MenuItem value="Proyecto Top">Proyecto Top</MenuItem>
            <MenuItem value="Futura Estrella">Futura Estrella</MenuItem>
            {/* EBA */}
            <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
            <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
            {/* LEB */}
            <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
            <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
            {/* ACB */}
            <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
            {/* BCL/Eurocup */}
            <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
            <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
            <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
            {/* Euroliga */}
            <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
            <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
            {/* NBA */}
            <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
            <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
            {/* College */}
            <MenuItem value="College Nivel Bajo">College Nivel Bajo</MenuItem>
            <MenuItem value="College Nivel Medio">College Nivel Medio</MenuItem>
            <MenuItem value="College Top">College Top</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Proyección VBC Rating</InputLabel>
          <Select
            value={proyeccion}
            onChange={(e) => {
              setProyeccion(e.target.value);
              markDirty();
            }}
            label="Proyección VBC Rating"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setProyeccion('');
                  markDirty();
                }}
                sx={{ 
                  visibility: proyeccion ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            {/* EBA */}
            <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
            <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
            {/* LEB */}
            <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
            <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
            {/* ACB */}
            <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
            {/* BCL/Eurocup */}
            <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
            <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
            <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
            {/* Euroliga */}
            <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
            <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
            {/* NBA */}
            <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
            <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Fila 5 : Flag Behaviour - Injury Prone */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr 1fr'
          }
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Flag Behaviour</InputLabel>
          <Select
            value={comportamiento}
            onChange={(e) => {
              setComportamiento(e.target.value);
              markDirty();
            }}
            label="Flag Behaviour"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setComportamiento('');
                  markDirty();
                }}
                sx={{ 
                  visibility: comportamiento ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="Green">Green</MenuItem>
            <MenuItem value="Orange">Orange</MenuItem>
            <MenuItem value="Red">Red</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Injury Prone</InputLabel>
          <Select
            value={injury}
            onChange={(e) => {
              setInjury(e.target.value);
              markDirty();
            }}
            label="Injury Prone"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setInjury('');
                  markDirty();
                }}
                sx={{ 
                  visibility: injury ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="Green">Green</MenuItem>
            <MenuItem value="Orange">Orange</MenuItem>
            <MenuItem value="Red">Red</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Fila 6 : Comparación con un Jugador Pro */}
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Comparación con un Jugador Pro"
          fullWidth
          value={comparacionPro}
          onChange={(e) => {
            setComparacionPro(e.target.value);
            markDirty();
          }}
        />
      </Box>

      {/* 3. SITUACIÓN ACTUAL */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Situación Actual</Typography>

      {/* Fila 1 : Selección Nacional - Equipo Actual */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)'
          }
        }}
      >
        {/* Selección Nacional */}
        <Box>
          <SelectSelectionNational
            value={seleccionNacional}
            onChange={(e) => {
              setSeleccionNacional(e.target.value);
              markDirty();
            }}
          />
        </Box>

        {/* Equipo Actual */}
        <Autocomplete
          freeSolo
          options={teams}
          inputValue={equipo}
          onInputChange={(event, newInputValue) => {
            setEquipo(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Equipo Actual"
              fullWidth
            />
          )}
        />
      </Box>

      {/* Fila 2 : Competición Europea - Liga Principal - Liga Secundaria */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(3, 1fr)'
          }
        }}
      >
        {/* Competición Europea */}
        <FormControl fullWidth>
          <InputLabel>Competición Europea</InputLabel>
          <Select
            value={competicionEuropa}
            onChange={(e) => {
              setCompeticionEuropa(e.target.value);
              markDirty();
            }}
            label="Competición Europea"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setCompeticionEuropa('');
                  markDirty();
                }}
                sx={{ 
                  visibility: competicionEuropa ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="EuroLeague">EuroLeague</MenuItem>
            <MenuItem value="EuroCup">EuroCup</MenuItem>
            <MenuItem value="FIBA BCL">FIBA BCL</MenuItem>
            <MenuItem value="FIBA EuropeCup">FIBA EuropeCup</MenuItem>
            <MenuItem value="Lat-Est">Lat-Est</MenuItem>
            <MenuItem value="ENBL">ENBL</MenuItem>
          </Select>
        </FormControl>

        {/* Liga Principal */}
        <Autocomplete
          freeSolo
          options={leagues1}
          inputValue={liga1}
          onInputChange={(event, newInputValue) => {
            setLiga1(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Liga Principal"
              fullWidth
            />
          )}
        />

        {/* Liga Secundaria */}
        <Autocomplete
          freeSolo
          options={leagues2}
          inputValue={liga2}
          onInputChange={(event, newInputValue) => {
            setLiga2(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Liga Secundaria"
              fullWidth
            />
          )}
        />
      </Box>

      {/* 4. EXPERIENCIA PROFESIONAL */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Experiencia Profesional</Typography>

      {/* Fila 1 : Año del Draft - Posición en el Draft - Equipo Pre-Draft */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(3, 1fr)'
          }
        }}
      >
        <TextField
          label="Año del Draft"
          type="number"
          fullWidth
          value={añoDraft}
          onChange={(e) => {
            setAñoDraft(e.target.value);
            markDirty();
          }}
        />

        <FormControl fullWidth>
          <InputLabel>Posición en el Draft</InputLabel>
          <Select
            value={posicionDraft}
            onChange={(e) => {
              setPosicionDraft(e.target.value);
              markDirty();
            }}
            label="Posición en el Draft"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setPosicionDraft('');
                  markDirty();
                }}
                sx={{ 
                  visibility: posicionDraft ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="1st Round, #1">1st Round, #1</MenuItem>
            <MenuItem value="1st Round, #2">1st Round, #2</MenuItem>
            <MenuItem value="1st Round, #3">1st Round, #3</MenuItem>
            <MenuItem value="1st Round, #4">1st Round, #4</MenuItem>
            <MenuItem value="1st Round, #5">1st Round, #5</MenuItem>
            <MenuItem value="1st Round, #6">1st Round, #6</MenuItem>
            <MenuItem value="2nd Round, #60">2nd Round, #60</MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          freeSolo
          options={teams}
          inputValue={equipoPreDraft}
          onInputChange={(event, newInputValue) => {
            setEquipoPreDraft(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Pre-Draft"
              fullWidth
            />
          )}
        />
      </Box>

      {/* Fila 2 : PIT - Eurocamp - SL Vegas */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(3, 1fr)'
          }
        }}
      >
        <TextField
          label="PIT"
          type="number"
          fullWidth
          value={pit}
          onChange={(e) => {
            setPit(e.target.value);
            markDirty();
          }}
        />

        <TextField
          label="Eurocamp"
          type="number"
          fullWidth
          value={eurocamp}
          onChange={(e) => {
            setEurocamp(e.target.value);
            markDirty();
          }}
        />

        <TextField
          label="SL Vegas"
          type="number"
          fullWidth
          value={slVegas}
          onChange={(e) => {
            setSlVegas(e.target.value);
            markDirty();
          }}
        />
      </Box>

      {/* Fila 3 : Experiencia NBA - Experiencia ACB - Experiencia Europa - Experiencia Fuera - Experiencia NCAA */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(5, 1fr)'
          }
        }}
      >
        <FormControl fullWidth>
          <InputLabel>Experiencia NBA</InputLabel>
          <Select
            value={nbaExperience}
            onChange={(e) => {
              setNbaExperience(e.target.value);
              markDirty();
            }}
            label="Experiencia NBA"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setNbaExperience('');
                  markDirty();
                }}
                sx={{ 
                  visibility: nbaExperience ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Experiencia ACB</InputLabel>
          <Select
            value={experienciaAcb}
            onChange={(e) => {
              setExperienciaAcb(e.target.value);
              markDirty();
            }}
            label="Experiencia ACB"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setExperienciaAcb('');
                  markDirty();
                }}
                sx={{ 
                  visibility: experienciaAcb ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Experiencia Europa</InputLabel>
          <Select
            value={experiencaEuropa}
            onChange={(e) => {
              setExperiencaEuropa(e.target.value);
              markDirty();
            }}
            label="Experiencia Europa"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setExperiencaEuropa('');
                  markDirty();
                }}
                sx={{ 
                  visibility: experiencaEuropa ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Experiencia Fuera de su País</InputLabel>
          <Select
            value={experienciaFuera}
            onChange={(e) => {
              setExperienciaFuera(e.target.value);
              markDirty();
            }}
            label="Experiencia Fuera de su País"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setExperienciaFuera('');
                  markDirty();
                }}
                sx={{ 
                  visibility: experienciaFuera ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Experiencia NCAA</InputLabel>
          <Select
            value={experiencaNcaa}
            onChange={(e) => {
              setExperiencaNcaa(e.target.value);
              markDirty();
            }}
            label="Experiencia NCAA"
            endAdornment={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setExperiencaNcaa('');
                  markDirty();
                }}
                sx={{ 
                  visibility: experiencaNcaa ? 'visible' : 'hidden',
                  position: 'absolute',
                  right: 32
                }}
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            }
          >
            <MenuItem value="SI">SI</MenuItem>
            <MenuItem value="NO">NO</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* 5. INFORMACIÓN CONTRACTUAL */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Información Contractual</Typography>

      {/* Fila 1 : Agencia - Rango Salarial - Contrato */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(3, 1fr)'
          }
        }}
      >
        {/* Agencia */}
        <Autocomplete
          freeSolo
          options={agencies}
          inputValue={agencia}
          onInputChange={(event, newInputValue) => {
            setAgencia(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Agencia"
              fullWidth
            />
          )}
        />

        {/* Rango Salarial */}
        <Autocomplete
          freeSolo
          options={priceRanges}
          inputValue={rangoPrecios}
          onInputChange={(event, newInputValue) => {
            setRangoPrecios(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rango Salarial"
              fullWidth
            />
          )}
        />

        {/* Contrato */}
        <Autocomplete
          freeSolo
          options={contratos}
          inputValue={contrato}
          onInputChange={(event, newInputValue) => {
            setContrato(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Contrato"
              fullWidth
            />
          )}
        />
      </Box>

      {/* Fila 2 : Buyout */}
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          mt: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr'
          }
        }}
      >
        {/* Buyout */}
        <Autocomplete
          freeSolo
          options={buyoutOptions}
          inputValue={buyout}
          onInputChange={(event, newInputValue) => {
            setBuyout(newInputValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Buyout"
              fullWidth
            />
          )}
        />
      </Box>

      {/* 6. INFORMACIÓN ADICIONAL */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Información Adicional</Typography>

      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr'
          }
        }}
      >
        {/* URL Estadística */}
        <TextField
          label="URL Estadística"
          fullWidth
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
            markDirty();
          }}
        />
      </Box>

      {/* Boutons */}
      <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
        {isSubmitting && <CircularProgress size={24} />}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => window.history.back()}
          disabled={isSubmitting}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Creando...' : 'Crear Jugador'}
        </Button>
      </Box>

      {/* Modal tutorial */}
      <Dialog open={tutorialOpen} onClose={() => setTutorialOpen(false)} maxWidth="md">
        <DialogTitle>Tutorial - Crear Jugador</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Bienvenido al formulario de creación de jugadores. Aquí hay algunas indicaciones importantes:
          </Typography>
          <Typography variant="body1" paragraph>
            • Los campos marcados en rojo son obligatorios:
            - Nombre del jugador  
            - Tipo de jugador (Pro o Cantera)  
            - Fecha de nacimiento  
            - Altura  
            - Posición  
          </Typography>
          <Typography variant="body1" paragraph>
            • Para los jugadores de cantera, debe seleccionar al menos un grupo
          </Typography>
          <Typography variant="body1" paragraph>
            • Puede subir una foto del jugador haciendo clic en el icono de la cámara
          </Typography>
          <Typography variant="body1" paragraph>
            • Todos los demás campos son opcionales pero ayudan a tener una ficha más completa del jugador
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTutorialOpen(false)}>Entendido</Button>
        </DialogActions>
      </Dialog>

      {/* Modal d'erreur */}
      <Dialog open={errorModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de succès */}
      <Dialog open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
        <DialogTitle>¡Éxito!</DialogTitle>
        <DialogContent>
          <Typography>
            El jugador ha sido registrado correctamente en la base de datos.
            ¿Qué desea hacer a continuación?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 1, p: 2 }}>
          <Button 
            onClick={() => {
              setSuccessModalOpen(false);
              resetForm();
            }} 
            variant="outlined" 
            color="primary"
          >
            Crear otro jugador
          </Button>
          
          <Box>
            <Button 
              variant="contained" 
              color="secondary"
              size="small"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              sx={{ 
                whiteSpace: 'nowrap',
                fontSize: '0.85rem'
              }}
            >
              Crear Informe / Intel / Ficha Técnica / Media
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleMenuItemClick('informe')}>Informe</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('intel')}>INTEL</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('techniques')}>Ficha Técnica</MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('video')}>Media</MenuItem>

            </Menu>
          </Box>

          <Button 
            onClick={() => window.history.back()} 
            variant="outlined" 
            color="primary"
          >
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CrearJugador;

class Player {
  constructor({
    id,
    nom,
    anneeNaissance,
    dateNaissance,
    taille,
    poids,
    poste,
    type,
    groupes = [],
    nationalite,
    jeJi,
    seleccionJeJi,
    mano,
    rolOfensivo,
    rolDefensivo,
    pasaporte,
    estatus,
    equipo,
    liga1,
    liga2,
    equipoPreDraft,
    añoDraft,
    posicionDraft,
    grado,
    experienciaAcb,
    nivel,
    proyeccion,
    agencia,
    comparacionPro,
    comportamiento,
    rangoPrecios,
    contrato,
    url,
    team,
    league_1,
    league_2,
    // Champs directement issus de l'API 
    in_big_board,
    in_informe,
    in_intel,
    in_follow_list,
    in_fiche_technique,
    in_video, // <-- Nouveau champ pour la fiche vidéo

    origen_genetico,
    competicion_europa,
  }) {
    this.id = id;
    this.nom = nom;
    this.anneeNaissance = anneeNaissance;
    this.dateNaissance = dateNaissance;
    this.taille = taille;
    this.poids = poids;
    this.poste = poste;
    this.type = type;
    this.groupes = groupes;
    this.nationalite = nationalite;
    this.jeJi = jeJi;
    this.seleccionJeJi = seleccionJeJi;
    this.mano = mano;
    this.rolOfensivo = rolOfensivo;
    this.rolDefensivo = rolDefensivo;
    this.pasaporte = pasaporte;
    this.estatus = estatus;
    this.equipo = equipo;
    this.liga1 = liga1;
    this.liga2 = liga2;
    this.equipoPreDraft = equipoPreDraft;
    this.añoDraft = añoDraft;
    this.posicionDraft = posicionDraft;
    this.grado = grado;
    this.experienciaAcb = experienciaAcb;
    this.nivel = nivel;
    this.proyeccion = proyeccion;
    this.agencia = agencia;
    this.comparacionPro = comparacionPro;
    this.comportamiento = comportamiento;
    this.rangoPrecios = rangoPrecios;
    this.contrato = contrato;
    this.url = url;
    this.team = team;
    this.league_1 = league_1;
    this.league_2 = league_2;
    // Champs directement issus de l'API
    this.in_big_board = in_big_board;
    this.in_informe = in_informe;
    this.in_intel = in_intel;
    this.in_follow_list = in_follow_list;
    this.in_fiche_technique = in_fiche_technique;
    this.in_video = in_video; // <-- Affectation du nouveau champ

    this.origen_genetico = origen_genetico;
    this.competicion_europa = competicion_europa;
  }

  static fromJSON(json) {
    return new Player({
      id: json.id,
      nom: json.nom,
      anneeNaissance: json.annee_naissance,
      dateNaissance: json.date_naissance,
      taille: json.taille,
      poids: json.poids,
      poste: json.poste,
      type: json.type,
      groupes: json.groupes || [],
      nationalite: json.nationalite,
      jeJi: json.je_ji,
      seleccionJeJi: json.selection_je_ji,
      mano: json.mano,
      rolOfensivo: json.offensive_role,
      rolDefensivo: json.defensive_role,
      pasaporte: json.passport,
      estatus: json.statut,
      equipo: json.team,
      liga1: json.league_1,
      liga2: json.league_2,
      equipoPreDraft: json.pre_draft_team,
      añoDraft: json.draft_year,
      posicionDraft: json.draft_pick,
      grado: json.grade,
      experienciaAcb: json.acb_experience,
      nivel: json.level,
      proyeccion: json.proyeccion,
      agencia: json.agency,
      comparacionPro: json.pro_comparaison,
      comportamiento: json.flag_behaviour,
      rangoPrecios: json.price_range,
      contrato: json.contract,
      url: json.url,
      team: json.team,
      league_1: json.league_1,
      league_2: json.league_2,
      // Champs directement issus de l'API
      in_big_board: json.is_in_big_board,
      in_informe: json.is_in_informe,
      in_intel: json.is_in_intel,
      in_follow_list: json.is_in_follow_list,
      in_fiche_technique: json.is_in_fiche_technique,
      in_video: json.is_in_video, // <-- Mapping du nouveau champ

      origen_genetico: json.origen_genetico,
      competicion_europa: json.competicion_europa
    });
  }
}

export default Player;

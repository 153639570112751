import React, { useEffect, useState, useMemo } from 'react';
import {
  useNavigate,
  useSearchParams,
  useLocation,
  useNavigationType
} from 'react-router-dom';

import {
  Box,
  Paper,
  Typography,
  IconButton,
  Avatar,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
  SwipeableDrawer,
  Divider,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import {
  ExpandMore,
  FilterList,
  Edit,
  Delete as DeleteIcon,
  Launch,
  Close
} from '@mui/icons-material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import axios from 'axios';

import { useSeguimientoTable } from '../SeguimientoTableContext';
import ListboxComponent from '../components/ListboxComponent ';

/** =========================================================
 *                UTILS
 * ========================================================= */
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const joinWithDash = (...items) => items.filter(Boolean).join(' - ');

/** =========================================================
 *  HOOK CUSTOM : détecte si l'action de navigation est "POP"
 * ========================================================= */
function useOnPop(callback) {
  const navigationType = useNavigationType(); // "PUSH", "POP", "REPLACE"
  useEffect(() => {
    if (navigationType === 'POP') {
      callback();
    }
  }, [navigationType, callback]);
}

/** =========================================================
 *                AVATAR UTILISATEUR
 * ========================================================= */
const UserAvatar = ({ user, mediaUrl, size = 24 }) => (
  <Tooltip
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'white',
          '& .MuiTooltip-arrow': { color: 'white' },
          boxShadow: '0px 2px 8px rgba(0,0,0,0.15)'
        }
      }
    }}
    title={
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          src={`${mediaUrl}/users/${user.id}.png`}
          sx={{ width: 40, height: 40 }}
        />
        <Box>
          <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
            {user.prenom} {user.nom}
          </Typography>
          {user.username && (
            <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.7)' }}>
              {user.username}
            </Typography>
          )}
        </Box>
      </Box>
    }
  >
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: `${size * 0.5}px`,
        bgcolor: getAvatarColor(user.nom + user.prenom)
      }}
    >
      {getInitials(user.prenom, user.nom)}
    </Avatar>
  </Tooltip>
);

/** =========================================================
 *                PLAYER CARD
 * ========================================================= */
const PlayerCard = ({
  player,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  disablePlayerLink
}) => {
  const line2 = joinWithDash(
    player.poste,
    player.annee_naissance,
    player.taille ? `${player.taille}cm` : null
  );

  const passportPart = player.nationalite
    ? player.nationalite + (player.passport ? ` - (${player.passport})` : '')
    : '';

  const line3 = joinWithDash(
    passportPart,
    player.team,
    [player.league_1, player.league_2, player.competicion_europa]
      .filter(Boolean)
      .join(' - ')
  );

  return (
    <ListItem
      button
      onClick={!disablePlayerLink ? () => onPlayerClick(player) : undefined}
      sx={{
        mb: 1,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: disablePlayerLink ? 'background.paper' : 'action.hover'
        },
        py: 2,
        cursor: disablePlayerLink ? 'default' : 'pointer'
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={`${mediaUrl}/photos/${player.id}.png`}
          alt={player.nom}
          sx={{ width: 48, height: 48 }}
        />
      </ListItemAvatar>

      <Box sx={{ ml: 1, flexGrow: 1 }}>
        <Typography
          variant="subtitle1"
          color="text.primary"
          sx={{
            fontSize: '1.1rem',
            ...(disablePlayerLink
              ? {}
              : {
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'underline'
                  }
                })
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (!disablePlayerLink) onPlayerClick(player);
          }}
        >
          {player.nom}
        </Typography>

        {line2 && (
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1rem' }}>
            {line2}
          </Typography>
        )}
        {line3 && (
          <Typography variant="body2" color="textSecondary" sx={{ fontSize: '1rem' }}>
            {line3}
          </Typography>
        )}
      </Box>

      {!disablePlayerLink && player.url && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onPlayerUrlClick(player.url, e);
            }}
          >
            <Launch fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

/** =========================================================
 *  Composant qui affiche 1 seguimiento dans un Paper
 * ========================================================= */
const SeguimientoContent = ({
  seguimiento,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  disablePlayerLink
}) => {
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <UserAvatar user={seguimiento.user} mediaUrl={mediaUrl} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {seguimiento.libelle}
          </Typography>
        </Box>

        <Box>
          <IconButton
            size="small"
            onClick={() => onEdit(seguimiento.id)}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(seguimiento)} color="error">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <List disablePadding>
        {seguimiento.players
          .sort((a, b) => a.nom.localeCompare(b.nom))
          .map((player) => (
            <PlayerCard
              key={player.id}
              player={player}
              onPlayerClick={onPlayerClick}
              onPlayerUrlClick={onPlayerUrlClick}
              mediaUrl={mediaUrl}
              disablePlayerLink={disablePlayerLink}
            />
          ))}
      </List>
    </Paper>
  );
};

/** =========================================================
 *   ACCORDION PAR TAG 
 *   (on affiche les "seguimientos" sur 1, 2 ou 3 colonnes)
 * ========================================================= */
const TagAccordion = ({
  tag,
  seguimientos,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  expandedTags,
  setExpandedTags,
  updateSearchParams,
  disablePlayerLink,
  onGeneratePDFForTag
}) => {
  const isExpanded = expandedTags.has(tag.id);

  // On calcule le total de jugadores pour l'afficher à côté
  const totalPlayers = seguimientos.reduce(
    (acc, seg) => acc + seg.players.length,
    0
  );

  // On décide combien de colonnes en fonction du nombre de seguimientos
  const nbSeguimientos = seguimientos.length;
  let mdSize;
  if (nbSeguimientos === 1) mdSize = 12;
  else if (nbSeguimientos === 2) mdSize = 6;
  else mdSize = 4;

  const handleChange = () => {
    const newSet = new Set(expandedTags);
    if (newSet.has(tag.id)) {
      newSet.delete(tag.id);
    } else {
      newSet.add(tag.id);
    }
    setExpandedTags(newSet);
    // On met à jour l’URL, si vous conservez la logique des expanded dans searchParams
    updateSearchParams({ expandedTags: Array.from(newSet) });
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleChange} sx={{ mb: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          bgcolor: 'background.default',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        {/* Résumé de l'accordéon : Chip + nombre de joueurs + bouton PDF */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Chip
              label={tag.libelle}
              style={{
                backgroundColor: tag.code_couleur,
                color: getContrastColor(tag.code_couleur),
                fontSize: '1.25rem',
                height: '48px',
                borderRadius: '8px'
              }}
            />
            <Typography color="text.secondary">
              {totalPlayers} Jugador@s
            </Typography>
          </Box>

          <Button
            variant="contained"
            startIcon={<PictureAsPdfIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onGeneratePDFForTag(tag, seguimientos);
            }}
          >
            PDF
          </Button>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {seguimientos.map((seg) => (
            <Grid item xs={12} md={mdSize} key={seg.id}>
              <SeguimientoContent
                seguimiento={seg}
                onEdit={onEdit}
                onDelete={onDelete}
                onPlayerClick={onPlayerClick}
                onPlayerUrlClick={onPlayerUrlClick}
                mediaUrl={mediaUrl}
                disablePlayerLink={disablePlayerLink}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

/** =========================================================
 *                FILTER DRAWER (MOBILE)
 * ========================================================= */
const FilterDrawer = ({
  open,
  onClose,
  players,
  users,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
  mediaUrl,
  tags,
  selectedTags,
  onTagsChange,
  userRole,
  seguimientoTypeFilter,
  setSeguimientoTypeFilter
}) => {
  const isSuperAdmin = userRole === 'super admin';

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
          pb: 2
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          {/* Cases à cocher Pro/Cantera (uniquement super admin) */}
          {isSuperAdmin && (
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={seguimientoTypeFilter === 'pro'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSeguimientoTypeFilter('pro');
                          } else {
                            setSeguimientoTypeFilter('');
                          }
                        }}
                        name="pro"
                      />
                    }
                    label="Pro"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={seguimientoTypeFilter === 'cantera'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSeguimientoTypeFilter('cantera');
                          } else {
                            setSeguimientoTypeFilter('');
                          }
                        }}
                        name="cantera"
                      />
                    }
                    label="Cantera"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={players.players || []}
              getOptionLabel={(player) => player?.nom || ''}
              value={selectedPlayer}
              onChange={onPlayerChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Jugador" />
              )}
              renderOption={(props, player) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Avatar
                    src={`${mediaUrl}/photos/${player.id}.png`}
                    alt={player.nom}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography noWrap>{player.nom}</Typography>
                </Box>
              )}
              ListboxComponent={ListboxComponent}
              clearOnEscape
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={users}
              getOptionLabel={(user) =>
                user ? `${user.prenom} ${user.nom}` : ''
              }
              value={selectedUser}
              onChange={onUserChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Usuario" />
              )}
              renderOption={(props, user) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                  <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                </Box>
              )}
              clearOnEscape
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              fullWidth
              options={tags}
              getOptionLabel={(tag) => tag.libelle}
              value={selectedTags}
              onChange={onTagsChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Tags" />
              )}
              renderOption={(props, tag) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Chip
                    size="small"
                    label={tag.libelle}
                    style={{
                      backgroundColor: tag.code_couleur,
                      color: getContrastColor(tag.code_couleur)
                    }}
                  />
                </Box>
              )}
              renderTags={(value, getTagProps) =>
                value.map((t, index) => (
                  <Chip
                    key={t.id}
                    size="small"
                    label={t.libelle}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: t.code_couleur,
                      color: getContrastColor(t.code_couleur)
                    }}
                  />
                ))
              }
              clearOnEscape
            />
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

/** =========================================================
 *   addPageTemplate : Entête/Pied de page PDF
 * ========================================================= */
const addPageTemplate = (doc) => {
  const pageNumber = doc.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  doc.setDrawColor('#EA7F00');
  doc.setLineWidth(2);
  doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

  const bandHeight = 20;
  const bandY = pageHeight - 10 - bandHeight;
  doc.setFillColor(234, 127, 0);
  doc.rect(10, bandY, pageWidth - 20, bandHeight, 'F');

  const logoSize = 16;
  try {
    const logoY = bandY + (bandHeight - logoSize) / 2;

    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`,
      'PNG',
      15,
      logoY,
      logoSize,
      logoSize
    );

    doc.setTextColor(255, 255, 255);
    doc.setFontSize(9);
    doc.text(
      'VALENCIA BASKET - DEPARTAMENTO DE SCOUTING',
      pageWidth / 2,
      bandY + bandHeight / 2 + 3,
      { align: 'center' }
    );

    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`,
      'PNG',
      pageWidth - 15 - logoSize,
      logoY,
      logoSize,
      logoSize
    );

    doc.setFontSize(8);
    doc.text(pageNumber.toString(), pageWidth / 2, bandY + bandHeight - 3, {
      align: 'center'
    });
  } catch (error) {
    console.error('Error adding images to PDF page:', error);
  }
  doc.setTextColor(0);
};

/** =========================================================
 *                SEGUIMIENTO TABLE
 * ========================================================= */
const SeguimientoTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  // Infos utilisateur
  const userData = JSON.parse(localStorage.getItem('user'));
  const currentUserId = userData?.id || null;
  const userRole = (userData?.role || '').toLowerCase();

  // État local
  const [seguimientoTypeFilter, setSeguimientoTypeFilter] = useState('all');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [seguimientoToDelete, setSeguimientoToDelete] = useState(null);
  const [players, setPlayers] = useState([]);
  const [users, setUsers] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  // Récupération depuis le context
  const {
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    tags,
    setTags,
    expandedTags,
    setExpandedTags,
    scrollPosition,
    setScrollPosition
  } = useSeguimientoTable();

  // Filtre "tags" sélectionnés
  const [selectedTags, setSelectedTags] = useState([]);

  // Variables pour restaurer la sélection depuis l’URL
  const [pendingPlayerId, setPendingPlayerId] = useState(null);
  const [pendingUserId, setPendingUserId] = useState(null);
  const [pendingTagIds, setPendingTagIds] = useState([]);

  // Détecte le "POP" (retour nav)
  useOnPop(() => {
    // Si on a un scrollPosition mémorisé, on le restaure
    if (scrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, scrollPosition);
      }, 0);
    }
  });

  // Gère le cas BFCache (Chrome/Safari)
  useEffect(() => {
    function handlePageShow(e) {
      if (e.persisted) {
        setTimeout(() => {
          window.scrollTo(0, scrollPosition);
        }, 0);
      }
    }
    window.addEventListener('pageshow', handlePageShow);
    return () => window.removeEventListener('pageshow', handlePageShow);
  }, [scrollPosition]);

  // =====================================================
  // CHARGEMENT DES DONNÉES
  // =====================================================
  const refreshData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendUrl}/api/seguimiento`, {
        params: { section }
      });
      setData(response.data);
      setError(null);
    } catch (err) {
      console.error('Error al cargar los Seguimientos:', err);
      setError('No se pueden cargar los Seguimientos.');
    } finally {
      setLoading(false);
    }
  };

  // Lecture des paramètres URL au montage
  useEffect(() => {
    const expandedParam = searchParams.get('expanded');
    if (expandedParam) {
      const ids = expandedParam
        .split(',')
        .filter((id) => id.trim() !== '')
        .map((id) => parseInt(id, 10));
      // On met ça dans le set du context
      setExpandedTags(new Set(ids));
    }

    const playerId = searchParams.get('playerId');
    if (playerId) setPendingPlayerId(parseInt(playerId, 10));

    const userId = searchParams.get('userId');
    if (userId) setPendingUserId(parseInt(userId, 10));

    const tagIds = searchParams.get('tagIds');
    if (tagIds) {
      const splitted = tagIds.split(',').map((tid) => parseInt(tid, 10));
      setPendingTagIds(splitted);
    }
    // eslint-disable-next-line
  }, []);

  // Chargement initial
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [tagsResp, playersResp, usersResp] = await Promise.all([
          axios.get(`${backendUrl}/api/tags`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/select`, { params: { section } }),
          axios.get(`${backendUrl}/api/users`, { params: { section } })
        ]);

        setTags(tagsResp.data);
        setPlayers(playersResp.data);
        setUsers(Array.isArray(usersResp.data) ? usersResp.data : []);

        // Restauration du player
        if (pendingPlayerId && playersResp.data.players) {
          const foundP = playersResp.data.players.find(
            (p) => p.id === pendingPlayerId
          );
          if (foundP) setSelectedPlayer(foundP);
        }
        // Restauration du user
        if (pendingUserId && usersResp.data) {
          const foundU = usersResp.data.find((u) => u.id === pendingUserId);
          if (foundU) setSelectedUser(foundU);
        }
        // Restauration des tags
        if (pendingTagIds.length > 0 && tagsResp.data) {
          const foundTags = tagsResp.data.filter((t) =>
            pendingTagIds.includes(t.id)
          );
          if (foundTags.length > 0) setSelectedTags(foundTags);
        }
      } catch (err) {
        console.error('Error al cargar datos iniciales:', err);
      }
      // Ensuite on refresh la liste de seguimientos
      refreshData();
    };
    fetchInitialData();
    // eslint-disable-next-line
  }, []);

  // Re-charger sur le focus
  useEffect(() => {
    const handleFocus = () => {
      refreshData();
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [refreshData]);

  // =====================================================
  // HANDLERS CRUD
  // =====================================================
  const handleEditClick = (seguimientoId) => {
    navigate(`/seguimiento/edit/${seguimientoId}`);
  };

  const handleDelete = async (seguimientoId) => {
    try {
      await axios.delete(`${backendUrl}/api/seguimiento/${seguimientoId}`, {
        params: { section }
      });
      await refreshData();
      setOpenDeleteDialog(false);
      setSeguimientoToDelete(null);
    } catch (err) {
      console.error('Error al eliminar el Seguimiento:', err);
      setError('Error al eliminar el Seguimiento.');
    }
  };

  // Mémoriser la position scroll avant d’aller sur la fiche joueur
  const handlePlayerClick = (player) => {
    setScrollPosition(window.scrollY);
    navigate(`/players/${player.id}`);
  };

  const handlePlayerUrlClick = (url, event) => {
    event.stopPropagation();
    window.open(url, '_blank');
  };

  // =====================================================
  // FILTRAGE
  // =====================================================
  const filteredData = useMemo(() => {
    if (!data) return [];

    return data.filter((seg) => {
      const segTypeLower = seg.type ? seg.type.toLowerCase() : '';

      let passTypeCheck = true;
      if (userRole === 'super admin') {
        if (seguimientoTypeFilter === 'pro') {
          passTypeCheck = segTypeLower === 'pro';
        } else if (seguimientoTypeFilter === 'cantera') {
          passTypeCheck = segTypeLower === 'cantera';
        }
      } else if (userRole === 'admin pro') {
        passTypeCheck = segTypeLower === 'pro';
      } else if (userRole === 'admin cantera') {
        passTypeCheck = segTypeLower === 'cantera';
      } else if (userRole === 'entrenador' || userRole === 'pdp') {
        passTypeCheck = seg.user.id === currentUserId;
      }

      if (!passTypeCheck) return false;

      // Filtre joueur
      const matchesPlayer =
        !selectedPlayer ||
        seg.players.some((p) => p.id === selectedPlayer.id);
      if (!matchesPlayer) return false;

      // Filtre user
      const matchesUser = !selectedUser || seg.user.id === selectedUser.id;
      if (!matchesUser) return false;

      // Filtre tags
      const matchesTags =
        selectedTags.length === 0 ||
        seg.tags.some((t) => selectedTags.some((st) => st.id === t.id));

      return matchesTags;
    });
  }, [
    data,
    selectedPlayer,
    selectedUser,
    selectedTags,
    userRole,
    currentUserId,
    seguimientoTypeFilter
  ]);

  // Regrouper par tag
  const groupedByTag = useMemo(() => {
    if (!tags) return [];
    const map = new Map();
    tags.forEach((t) => {
      map.set(t.id, { tag: t, seguimientos: [] });
    });

    filteredData.forEach((seguimiento) => {
      seguimiento.tags.forEach((t) => {
        if (map.has(t.id)) {
          map.get(t.id).seguimientos.push(seguimiento);
        }
      });
    });
    // Ne garder que les tags qui ont au moins 1 seguimiento
    return Array.from(map.values()).filter((g) => g.seguimientos.length > 0);
  }, [tags, filteredData]);

  // =====================================================
  // MISE À JOUR DES URL PARAMS
  // =====================================================
  const updateSearchParams = (newState) => {
    const params = new URLSearchParams(searchParams.toString());

    // expanded
    if (newState.expandedTags) {
      if (newState.expandedTags.length > 0) {
        params.set('expanded', newState.expandedTags.join(','));
      } else {
        params.delete('expanded');
      }
    } else if (expandedTags.size > 0) {
      params.set('expanded', Array.from(expandedTags).join(','));
    } else {
      params.delete('expanded');
    }

    // selectedPlayer
    if (newState.selectedPlayer !== undefined) {
      const val = newState.selectedPlayer ? newState.selectedPlayer.id : '';
      val ? params.set('playerId', val) : params.delete('playerId');
    } else if (selectedPlayer) {
      params.set('playerId', selectedPlayer.id);
    } else {
      params.delete('playerId');
    }

    // selectedUser
    if (newState.selectedUser !== undefined) {
      const val = newState.selectedUser ? newState.selectedUser.id : '';
      val ? params.set('userId', val) : params.delete('userId');
    } else if (selectedUser) {
      params.set('userId', selectedUser.id);
    } else {
      params.delete('userId');
    }

    // selectedTags
    if (newState.selectedTags !== undefined) {
      const tIds = newState.selectedTags.map((tg) => tg.id);
      if (tIds.length > 0) {
        params.set('tagIds', tIds.join(','));
      } else {
        params.delete('tagIds');
      }
    } else if (selectedTags.length > 0) {
      params.set(
        'tagIds',
        selectedTags.map((tg) => tg.id).join(',')
      );
    } else {
      params.delete('tagIds');
    }

    setSearchParams(params, { replace: true });
  };

  useEffect(() => {
    // On met à jour l’URL dès que la sélection ou l'expansion change
    updateSearchParams({});
    // eslint-disable-next-line
  }, [selectedPlayer, selectedUser, selectedTags, expandedTags]);

  // =====================================================
  // GÉNÉRATION PDF
  // =====================================================
  const generatePDFForTag = async (tag, seguimientos) => {
    try {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
      const dateStr = `${day}-${month}-${year}`;

      const doc = new jsPDF();
      addPageTemplate(doc);

      let yPos = 20;
      doc.setFontSize(18);
      doc.setTextColor(0);
      doc.text(
        `VBC SEGUIMIENTO - ${tag.libelle} - ${dateStr}`,
        doc.internal.pageSize.width / 2,
        yPos,
        { align: 'center' }
      );
      yPos += 15;

      for (let sIndex = 0; sIndex < seguimientos.length; sIndex++) {
        const seguimiento = seguimientos[sIndex];

        if (yPos > doc.internal.pageSize.height - 50) {
          doc.addPage();
          addPageTemplate(doc);
          yPos = 20;
        }

        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0);
        doc.text(seguimiento.libelle, 20, yPos);
        yPos += 8;

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(9);
        doc.setTextColor(0);

        const playersSorted = seguimiento.players.sort((a, b) =>
          a.nom.localeCompare(b.nom)
        );

        const rowDataArray = playersSorted.map((p) => {
          const parts = [];
          if (p.poste) parts.push(p.poste);
          if (p.annee_naissance) parts.push(p.annee_naissance);
          if (p.taille) parts.push(`${p.taille}cm`);

          if (p.nationalite) {
            const natPassport = p.passport
              ? `${p.nationalite} - ${p.passport}`
              : p.nationalite;
            parts.push(natPassport);
          }
          if (p.team) parts.push(p.team);

          const leagues = [p.league_1, p.league_2, p.competicion_europa].filter(
            Boolean
          );
          if (leagues.length > 0) {
            parts.push(leagues.join(' - '));
          }

          const infoLine = parts.join(' - ');
          return [p.nom, infoLine];
        });

        doc.autoTable({
          startY: yPos,
          head: [['Nombre', 'Infos']],
          body: rowDataArray,
          theme: 'grid',
          headStyles: {
            fillColor: [234, 127, 0],
            textColor: [255, 255, 255],
            fontSize: 9,
            fontStyle: 'bold'
          },
          styles: {
            fontSize: 8,
            cellPadding: 2,
            overflow: 'linebreak'
          },
          margin: { left: 15, right: 15, bottom: 30 },
          didDrawPage: () => {
            addPageTemplate(doc);
          }
        });

        yPos = doc.lastAutoTable.finalY + 10;
      }

      doc.save(`VBC SEGUIMIENTO - ${tag.libelle} - ${dateStr}.pdf`);
    } catch (err) {
      console.error('Erreur génération PDF:', err);
    }
  };

  // =====================================================
  // RENDER
  // =====================================================
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }
  if (!data || data.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay Seguimientos disponibles
      </Alert>
    );
  }

  // Pour les rôles "entrenador" / "pdp", désactiver lien vers la fiche joueur
  const disablePlayerLink = userRole === 'entrenador' || userRole === 'pdp';

  return (
    <Paper elevation={2}>
      <Box sx={{ p: 2, pb: isMobile ? 7 : 2 }}>
        {/* Filtros (Desktop) */}
        {!isMobile && (
          <>
            {userRole === 'super admin' && (
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={seguimientoTypeFilter === 'pro'}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSeguimientoTypeFilter('pro');
                              } else {
                                setSeguimientoTypeFilter('');
                              }
                            }}
                            name="pro"
                          />
                        }
                        label="Pro"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={seguimientoTypeFilter === 'cantera'}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSeguimientoTypeFilter('cantera');
                              } else {
                                setSeguimientoTypeFilter('');
                              }
                            }}
                            name="cantera"
                          />
                        }
                        label="Cantera"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  fullWidth
                  options={players.players || []}
                  getOptionLabel={(player) => player?.nom || ''}
                  value={selectedPlayer}
                  onChange={(_, newValue) => {
                    setSelectedPlayer(newValue);
                    updateSearchParams({ selectedPlayer: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Filtrar por Jugador" />
                  )}
                  renderOption={(props, p) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <Avatar
                        src={`${mediaUrl}/photos/${p.id}.png`}
                        alt={p.nom}
                        sx={{ width: 32, height: 32 }}
                      />
                      <Typography noWrap>{p.nom}</Typography>
                    </Box>
                  )}
                  ListboxComponent={ListboxComponent}
                  clearOnEscape
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  fullWidth
                  options={users}
                  getOptionLabel={(u) => (u ? `${u.prenom} ${u.nom}` : '')}
                  value={selectedUser}
                  onChange={(_, newValue) => {
                    setSelectedUser(newValue);
                    updateSearchParams({ selectedUser: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Filtrar por Usuario" />
                  )}
                  renderOption={(props, u) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <UserAvatar user={u} mediaUrl={mediaUrl} size={32} />
                      <Typography noWrap>{`${u.prenom} ${u.nom}`}</Typography>
                    </Box>
                  )}
                  clearOnEscape
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  options={tags}
                  getOptionLabel={(t) => t.libelle}
                  value={selectedTags}
                  onChange={(_, newValue) => {
                    setSelectedTags(newValue);
                    updateSearchParams({ selectedTags: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Filtrar por Tags" />
                  )}
                  renderOption={(props, t) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <Chip
                        size="small"
                        label={t.libelle}
                        style={{
                          backgroundColor: t.code_couleur,
                          color: getContrastColor(t.code_couleur)
                        }}
                      />
                    </Box>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((tg, index) => (
                      <Chip
                        key={tg.id}
                        size="small"
                        label={tg.libelle}
                        {...getTagProps({ index })}
                        style={{
                          backgroundColor: tg.code_couleur,
                          color: getContrastColor(tg.code_couleur)
                        }}
                      />
                    ))
                  }
                  clearOnEscape
                />
              </Grid>
            </Grid>
          </>
        )}

        {/* Petite bannière "x tags trouvés" */}
        <Box
          sx={{
            mx: 2,
            mt: 2,
            mb: 1,
            display: 'flex',
            alignItems: 'center',
            p: 2,
            bgcolor: theme.palette.primary.main,
            color: 'white',
            borderRadius: 1,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          <Box
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '50%',
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mr: 2,
              fontWeight: 'bold',
              fontSize: '1.1rem'
            }}
          >
            {groupedByTag.length}
          </Box>
          <Typography variant="subtitle1">Seguimientos Encontrados</Typography>
        </Box>

        {/* Liste des TagAccordion */}
        {groupedByTag.map(({ tag, seguimientos }) => (
          <TagAccordion
            key={tag.id}
            tag={tag}
            seguimientos={seguimientos}
            onEdit={handleEditClick}
            onDelete={(s) => {
              setSeguimientoToDelete(s);
              setOpenDeleteDialog(true);
            }}
            onPlayerClick={handlePlayerClick}
            onPlayerUrlClick={handlePlayerUrlClick}
            mediaUrl={mediaUrl}
            expandedTags={expandedTags}
            setExpandedTags={setExpandedTags}
            updateSearchParams={updateSearchParams}
            disablePlayerLink={disablePlayerLink}
            onGeneratePDFForTag={generatePDFForTag}
          />
        ))}

        {/* Bouton flottant (mobile) */}
        {isMobile && (
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterList />
          </Fab>
        )}
      </Box>

      {/* Filter Drawer (mobile) */}
      <FilterDrawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        players={players}
        users={users}
        selectedPlayer={selectedPlayer}
        selectedUser={selectedUser}
        onPlayerChange={(_, newValue) => {
          setSelectedPlayer(newValue);
          updateSearchParams({ selectedPlayer: newValue });
        }}
        onUserChange={(_, newValue) => {
          setSelectedUser(newValue);
          updateSearchParams({ selectedUser: newValue });
        }}
        mediaUrl={mediaUrl}
        tags={tags}
        selectedTags={selectedTags}
        onTagsChange={(_, newValue) => {
          setSelectedTags(newValue);
          updateSearchParams({ selectedTags: newValue });
        }}
        userRole={userRole}
        seguimientoTypeFilter={seguimientoTypeFilter}
        setSeguimientoTypeFilter={setSeguimientoTypeFilter}
      />

      {/* Dialog confirmation de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setSeguimientoToDelete(null);
        }}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro de que desea eliminar el Seguimiento "
            {seguimientoToDelete?.libelle}"? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setSeguimientoToDelete(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            color="error"
            onClick={() => handleDelete(seguimientoToDelete?.id)}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SeguimientoTable;

import React, { useEffect, useState, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Grid,
  useTheme,
  useMediaQuery,
  SwipeableDrawer,
  Fab,
  Divider,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Tooltip,
  Autocomplete,
  Checkbox,
  CircularProgress,
  Pagination,
  TablePagination,
} from '@mui/material';

import { Link } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import ReactPlayer from 'react-player';
import { Tweet } from 'react-twitter-widgets';
import parse from 'html-react-parser';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// ===============
// Autocomplete virtualisé (optionnel)
const LISTBOX_PADDING = 8;

function renderRow(props) {
  const { data, index, style } = props;
  const item = data[index];
  return React.cloneElement(item, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
      style={{
        ...props.style,
        overflowX: 'hidden',
        overflowY: 'auto',
        maxWidth: '100%',
      }}
    />
  );
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// ===============
// Fonctions utilitaires
const getPlayerAvatarUrl = (playerId) => {
  return playerId
    ? `${process.env.REACT_APP_MEDIA_URL}/photos/${playerId}.png?${new Date().getTime()}`
    : '/path/to/default-player-avatar.png';
};

const getSourceAvatarUrl = (source) => {
  if (!source) return '/assets/default.png'; // fallback si pas de source
  return `/assets/${source}.png`;            // ex: /assets/BeBasket.png
};

// Calcul de l'âge en décimal
const getDecimalAge = (dob) => {
  if (!dob) return '';
  const now = new Date();
  const birthDate = new Date(dob);
  const diffMs = now - birthDate;
  if (diffMs < 0) return '';
  const diffDays = diffMs / (1000 * 60 * 60 * 24);
  const diffYears = diffDays / 365;
  return diffYears.toFixed(1);
};

const getShortNationality = (nat) => {
  if (!nat) return '';
  const match = nat.match(/\((.*?)\)/);
  return match ? match[1] : nat;
};

// ===========================
// Parser et nettoyage du contenu
// ===========================
function parseHtmlContent(htmlString) {
  if (!htmlString) return null;

  const getTweetIdFromUrl = (url) => {
    const match = url.match(/status\/(\d+)/);
    return match ? match[1] : null;
  };

  return parse(htmlString, {
    replace: (domNode) => {
      // Supprimer <img>
      if (domNode.name === 'img') {
        return null;
      }

      // iframe YouTube
      if (domNode.name === 'iframe' && domNode.attribs) {
        const src = domNode.attribs.src || '';
        if (src.includes('youtube.com') || src.includes('youtu.be')) {
          return (
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '800px',
                  paddingTop: '56.25%', // ratio 16:9
                }}
              >
                <ReactPlayer
                  url={src}
                  controls
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </Box>
            </Box>
          );
        }
        return null;
      }

      // Liens vers YouTube et Tweets
      if (domNode.name === 'a' && domNode.attribs?.href) {
        const href = domNode.attribs.href;
        if (href.includes('youtube.com') || href.includes('youtu.be')) {
          return (
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '800px',
                  paddingTop: '56.25%',
                }}
              >
                <ReactPlayer
                  url={href}
                  controls
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </Box>
            </Box>
          );
        }
        if (href.includes('twitter.com') && href.includes('/status/')) {
          const tweetId = getTweetIdFromUrl(href);
          if (tweetId) {
            return (
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  width: { xs: '100%', sm: '100%' },
                  height: { xs: '100%', sm: '100%' },
                }}
              >
                <Tweet tweetId={tweetId} options={{ width: '100%' }} />
              </Box>
            );
          }
        }
      }

      return undefined; // ne pas remplacer le reste
    },
  });
}

// ========================
// Carte mobile (nouvelles)
// ========================
const MobileNewsCard = ({ news, onEdit, onDelete, open, onToggle }) => {
  const theme = useTheme();
  const decimalAge = getDecimalAge(news.player_date_naissance);
  const shortNat = getShortNationality(news.player_nationalite);

  return (
    <Card
      sx={{
        mb: 2,
        boxShadow: theme.shadows[2],
        maxWidth: '100%',
      }}
    >
      <CardHeader
        avatar={
          <Avatar src={getPlayerAvatarUrl(news.player_id)} alt={news.player_nom} />
        }
        title={
          <Typography
            variant="h6"
            sx={{ backgroundColor: '#e0f7fa', padding: '4px', borderRadius: '4px' }}
          >
            {news.title}
          </Typography>
        }
        subheader={new Date(news.updated_at).toLocaleDateString()}
        action={
          <IconButton onClick={() => onToggle(news.id)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            overflow: 'visible',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            width: '100%',
            p: 2,
            pr: 4,
          }}
        >
          {/* Infos joueur */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Jugador:</strong>{' '}
              <Link to={`/players/${news.player_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                {news.player_nom}
              </Link>
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Posición:</strong> {news.player_poste}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Edad:</strong>{' '}
              {news.player_date_naissance ? `${decimalAge} años` : 'N/D'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Nacionalidad:</strong> {shortNat}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Equipo:</strong> {news.player_team || 'N/D'}
            </Typography>
          </Box>

          {/* Avatar de la source + tooltip */}
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle1" sx={{ mr: 1, fontWeight: 'bold' }}>
              Fuente:
            </Typography>
            <Tooltip title={news.source || ''}>
              <Avatar
                src={getSourceAvatarUrl(news.source)}
                alt={news.source}
                sx={{ width: 40, height: 40 }}
              />
            </Tooltip>
          </Box>

          {/* Contenu de la noticia */}
          <Box
            sx={{
              backgroundColor: '#FFECB3',
              p: 2,
              borderRadius: '4px',
              mt: 1,
              mb: 2,
              overflow: 'visible',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              width: '100%',
            }}
          >
            {parseHtmlContent(news.content)}
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            size="small"
            startIcon={<OpenInNewIcon />}
            onClick={() => news.url && window.open(news.url, '_blank')}
          >
            Ver artículo
          </Button>
          <Button size="small" startIcon={<EditIcon />} onClick={() => onEdit(news)}>
            Modificar
          </Button>
          <Button size="small" color="error" startIcon={<DeleteIcon />} onClick={() => onDelete(news)}>
            Eliminar
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

// ================================
// Tiroir (drawer) de filtre mobile
// ================================
const MobileFilterDrawer = ({
  open,
  onClose,
  players,
  sources,
  selectedPlayer,
  selectedSource,
  onPlayerChange,
  onSourceChange,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={players}
                getOptionLabel={(player) => player.nom}
                value={selectedPlayer}
                onChange={onPlayerChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccionar un jugador" variant="outlined" />
                )}
                renderOption={(props, player) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={getPlayerAvatarUrl(player.id)}
                      alt={player.nom}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {player.nom}
                  </Box>
                )}
                clearOnEscape
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={sources}
                getOptionLabel={(source) => source}
                value={selectedSource}
                onChange={onSourceChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccionar una fuente" variant="outlined" />
                )}
                renderOption={(props, sourceOption) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={getSourceAvatarUrl(sourceOption)}
                      alt={sourceOption}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {sourceOption}
                  </Box>
                )}
                clearOnEscape
                freeSolo
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

// ===========================
// Composant principal: News
// ===========================
const News = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [newsData, setNewsData] = useState([]);
  const [allNewsData, setAllNewsData] = useState([]);

  // Filtres
  const [players, setPlayers] = useState([]);
  const [sources, setSources] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedSource, setSelectedSource] = useState('');

  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  // Drawer de filtre en mobile
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  // Lignes ouvertes (collapse)
  const [openRows, setOpenRows] = useState({});

  // Chargement
  const [loading, setLoading] = useState(true);

  // Dialog d'édition
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [editedSource, setEditedSource] = useState('');
  const [editedUrl, setEditedUrl] = useState('');

  // Dialog de suppression
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // Charger les données (news, players, sources)
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const [resNews, resPlayers, resSources] = await Promise.all([
          axios.get(`${backendUrl}/api/news`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/select`, { params: { section } }),
          axios.get(`${backendUrl}/api/news/sources`, { params: { section } }),
        ]);

        const news = resNews.data || [];
        setAllNewsData(news);
        setNewsData(news);

        const _players = Array.isArray(resPlayers.data)
          ? resPlayers.data
          : resPlayers.data.players || [];
        setPlayers(_players);

        const _sources = resSources.data || [];
        setSources(_sources);
      } catch (error) {
        console.error('Error al cargar los datos (noticias):', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [backendUrl, section]);

  // Filtrage local
  useEffect(() => {
    let filteredData = allNewsData;

    if (selectedPlayer) {
      filteredData = filteredData.filter((item) => item.player_id === selectedPlayer.id);
    }

    if (selectedSource) {
      filteredData = filteredData.filter(
        (item) => item.source && item.source.toLowerCase().includes(selectedSource.toLowerCase())
      );
    }

    setNewsData(filteredData);
    setPage(0);
  }, [allNewsData, selectedPlayer, selectedSource]);

  // Pagination
  const totalResults = newsData.length;
  const paginatedData = React.useMemo(() => {
    if (rowsPerPage <= 0) return newsData;
    return newsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [newsData, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newVal = parseInt(event.target.value, 10);
    setRowsPerPage(newVal);
    setPage(0);
  };

  const handleChangeMobilePage = (event, newValue) => {
    setPage(newValue - 1);
  };

  const toggleRow = (rowId) => {
    setOpenRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  // Édition
  const handleEditClick = (newsItem) => {
    setCurrentNews(newsItem);
    setEditedTitle(newsItem.title || '');
    setEditedContent(newsItem.content || '');
    setEditedSource(newsItem.source || '');
    setEditedUrl(newsItem.url || '');
    setOpenEditDialog(true);
  };

  const handleEditSave = async () => {
    if (!currentNews) return;
    try {
      const transformedContent = editedContent
        .replace(/<p><br><\/p>/g, '<br>')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '<br>')
        .replace(/<br><br>/g, '<br>');

      const updateData = {
        title: editedTitle,
        content: transformedContent,
        source: editedSource,
        url: editedUrl,
      };

      await axios.put(`${backendUrl}/api/news/${currentNews.id}`, updateData, {
        params: { section },
      });

      setAllNewsData((prev) =>
        prev.map((n) =>
          n.id === currentNews.id ? { ...n, ...updateData, content: transformedContent } : n
        )
      );

      setOpenEditDialog(false);
      alert('¡Noticia actualizada con éxito!');
    } catch (error) {
      console.error('Error al actualizar la noticia:', error);
      alert('Error al actualizar la noticia.');
    }
  };

  // Suppression
  const handleDeleteClick = (newsItem) => {
    setNewsToDelete(newsItem);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (!newsToDelete) return;
    try {
      await axios.delete(`${backendUrl}/api/news/${newsToDelete.id}`, {
        params: { section },
      });
      setAllNewsData((prev) => prev.filter((n) => n.id !== newsToDelete.id));
      setOpenDeleteDialog(false);
      alert('¡Noticia eliminada con éxito!');
    } catch (error) {
      console.error('Error al eliminar la noticia:', error);
      alert('Error al eliminar la noticia.');
    }
  };

  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setNewsToDelete(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', pb: isMobile ? 7 : 0 }}>
      {isMobile ? (
        <>
          {/* Vue mobile */}
          <Box
            sx={{
              mx: 2,
              mt: 2,
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              p: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              {totalResults}
            </Box>
            <Typography variant="subtitle1">
              {totalResults <= 1 ? 'Noticia encontrada' : 'Noticias encontradas'}
            </Typography>
          </Box>

          <Box sx={{ p: 2 }}>
            {paginatedData.map((n) => (
              <MobileNewsCard
                key={n.id}
                news={n}
                open={openRows[n.id] || false}
                onToggle={toggleRow}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
              />
            ))}
          </Box>

          {rowsPerPage !== -1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <Pagination
                color="primary"
                count={Math.ceil(totalResults / rowsPerPage)}
                page={page + 1}
                onChange={handleChangeMobilePage}
              />
            </Box>
          )}

          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab,
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterListIcon />
          </Fab>

          <MobileFilterDrawer
            open={openFilterDrawer}
            onClose={() => setOpenFilterDrawer(false)}
            players={players}
            sources={sources}
            selectedPlayer={selectedPlayer}
            selectedSource={selectedSource}
            onPlayerChange={(_, newValue) => setSelectedPlayer(newValue)}
            onSourceChange={(_, newValue) => setSelectedSource(newValue || '')}
          />
        </>
      ) : (
        <>
          {/* Vue desktop */}
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <Autocomplete
                  options={players}
                  getOptionLabel={(player) => player.nom}
                  value={selectedPlayer}
                  onChange={(_, newValue) => setSelectedPlayer(newValue)}
                  ListboxComponent={ListboxComponent}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccionar un jugador" variant="outlined" />
                  )}
                  renderOption={(props, player) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={getPlayerAvatarUrl(player.id)}
                        alt={player.nom}
                        sx={{ mr: 1 }}
                      />
                      {player.nom}
                    </Box>
                  )}
                  clearOnEscape
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                options={sources}
                getOptionLabel={(source) => source}
                value={selectedSource}
                onChange={(_, newValue) => setSelectedSource(newValue || '')}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccionar una fuente" variant="outlined" />
                )}
                renderOption={(props, sourceOption) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={getSourceAvatarUrl(sourceOption)}
                      alt={sourceOption}
                      sx={{ mr: 1 }}
                    />
                    {sourceOption}
                  </Box>
                )}
                freeSolo
                clearOnEscape
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              mb: 3,
              display: 'flex',
              alignItems: 'center',
              p: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: 48,
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}
            >
              {totalResults}
            </Box>
            <Typography variant="h6">
              {totalResults <= 1 ? 'Noticia encontrada' : 'Noticias encontradas'}
            </Typography>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Título</TableCell>
                  <TableCell align="center">Actualización</TableCell>
                  <TableCell>Jugador</TableCell>
                  <TableCell align="center">Pos.</TableCell>
                  <TableCell align="center">Edad</TableCell>
                  <TableCell align="center">Nacionalidad</TableCell>
                  <TableCell align="center">Equipo</TableCell>
                  <TableCell align="center">Fuente</TableCell>
                  <TableCell align="center">Ver más</TableCell>
                  <TableCell align="center">Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row) => {
                  const decimalAge = getDecimalAge(row.player_date_naissance);
                  const shortNat = getShortNationality(row.player_nationalite);

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell align="center">
                          <Box
                            sx={{
                              backgroundColor: '#e0f7fa',
                              padding: '4px',
                              borderRadius: '4px',
                              display: 'inline-block',
                            }}
                          >
                            {row.title}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {new Date(row.updated_at).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={getPlayerAvatarUrl(row.player_id)}
                              alt={row.player_nom}
                              sx={{ mr: 1 }}
                            />
                            <Typography variant="body2">
                              <Link
                                to={`/players/${row.player_id}`}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                              >
                                {row.player_nom}
                              </Link>
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">{row.player_poste}</TableCell>
                        <TableCell align="center">
                          {decimalAge ? `${decimalAge}` : 'N/D'}
                        </TableCell>
                        <TableCell align="center">{shortNat}</TableCell>
                        <TableCell align="center">{row.player_team || 'N/D'}</TableCell>

                        {/* Avatar de la source + Tooltip */}
                        <TableCell align="center">
                          <Tooltip title={row.source || ''}>
                            <Avatar
                              src={getSourceAvatarUrl(row.source)}
                              alt={row.source}
                              sx={{ margin: '0 auto' }}
                            />
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <IconButton onClick={() => toggleRow(row.id)}>
                            {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          {row.url && (
                            <IconButton onClick={() => window.open(row.url, '_blank')}>
                              <OpenInNewIcon />
                            </IconButton>
                          )}
                          <IconButton onClick={() => handleEditClick(row)} color="primary">
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteClick(row)} sx={{ color: 'red' }}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {/* Row collapse avec le contenu */}
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                          <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                            <Box
                              margin={1}
                              sx={{
                                overflow: 'visible',
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: '#FFECB3',
                                  padding: '10px',
                                  pr: 2,
                                  borderRadius: '4px',
                                  overflow: 'visible',
                                  wordWrap: 'break-word',
                                  whiteSpace: 'pre-wrap',
                                  width: '100%',
                                }}
                              >
                                {parseHtmlContent(row.content)}
                              </Box>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
              component="div"
              count={totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}

      {/* Dialog d'édition */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Modificar la noticia</DialogTitle>
        <DialogContent>
          {currentNews && (
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Título"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
              />

              <TextField
                label="Fuente"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={editedSource}
                onChange={(e) => setEditedSource(e.target.value)}
              />

              <TextField
                label="URL"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={editedUrl}
                onChange={(e) => setEditedUrl(e.target.value)}
              />

              <Typography variant="subtitle1" gutterBottom>
                Contenido:
              </Typography>
              <ReactQuill
                theme="snow"
                value={editedContent}
                onChange={setEditedContent}
                placeholder="Modificar el contenido de la noticia..."
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Eliminar la noticia</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que deseas eliminar esta noticia?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default News;

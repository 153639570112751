// components/SituacionActual.js

import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Autocomplete
} from '@mui/material';
import { Clear } from '@mui/icons-material';

// IMPORTA TU SELECT DE SELECCIÓN NACIONAL
import SelectSelectionNational from '../SelectSelectionNational';

function SituacionActual({
  player,
  editablePlayer,
  setEditablePlayer,
  isEditing,
  isMobile,
  teams,       // array de equipos
  leagues1,    // array para "Liga Principal"
  leagues2,    // array para "Liga Secundaria"
  handleChange
}) {
  // Función para limpiar un campo Select al hacer clic en el icono Clear
  const clearSelectValue = (e, fieldName) => {
    e.stopPropagation();
    setEditablePlayer((prev) => ({
      ...prev,
      [fieldName]: ''
    }));
  };

  // === Render para MOBILE ===
  if (isMobile) {
    return (
      <Box
        sx={{
          mb: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            px: 2,
            py: 1,
            backgroundColor: 'background.paper'
          }}
        >
          3. Situación Actual
        </Typography>

        <Box sx={{ p: 2 }}>
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Selección Nacional */}
              <Grid item xs={12}>
                <SelectSelectionNational
                  value={editablePlayer.seleccionNacional || ''}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      seleccionNacional: e.target.value
                    }))
                  }
                />
              </Grid>

              {/* Equipo Actual (Autocomplete) */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={teams}
                  inputValue={editablePlayer.equipo || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      equipo: newInputValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Equipo Actual" fullWidth />
                  )}
                />
              </Grid>

              {/* Competición Europea */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Competición Europea</InputLabel>
                  <Select
                    name="competicionEuropa"
                    value={editablePlayer.competicionEuropa || ''}
                    onChange={handleChange}
                    label="Competición Europea"
                    endAdornment={
                      editablePlayer.competicionEuropa && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'competicionEuropa')}
                          sx={{ position: 'absolute', right: 32 }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="EuroLeague">EuroLeague</MenuItem>
                    <MenuItem value="EuroCup">EuroCup</MenuItem>
                    <MenuItem value="FIBA BCL">FIBA BCL</MenuItem>
                    <MenuItem value="FIBA EuropeCup">FIBA EuropeCup</MenuItem>
                    <MenuItem value="Lat-Est">Lat-Est</MenuItem>
                    <MenuItem value="ENBL">ENBL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Liga Principal */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={leagues1}
                  inputValue={editablePlayer.liga1 || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      liga1: newInputValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Liga Principal" fullWidth />
                  )}
                />
              </Grid>

              {/* Liga Secundaria */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={leagues2}
                  inputValue={editablePlayer.liga2 || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      liga2: newInputValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Liga Secundaria" fullWidth />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            // READ ONLY (MOBILE)
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.seleccionNacional && (
                <Typography>
                  <strong>Selección Nacional:</strong> {player.seleccionNacional}
                </Typography>
              )}
              {!!player.equipo && (
                <Typography>
                  <strong>Equipo Actual:</strong> {player.equipo}
                </Typography>
              )}
              {(!!player.competicionEuropa || !!player.liga1 || !!player.liga2) && (
                <Box>
                  {!!player.competicionEuropa && (
                    <Typography>
                      <strong>Competición Europea:</strong> {player.competicionEuropa}
                    </Typography>
                  )}
                  {!!player.liga1 && (
                    <Typography>
                      <strong>Liga Principal:</strong> {player.liga1}
                    </Typography>
                  )}
                  {!!player.liga2 && (
                    <Typography>
                      <strong>Liga Secundaria:</strong> {player.liga2}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // === Render para DESKTOP ===
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        3. Situación Actual
      </Typography>

      {isEditing ? (
        <Grid container spacing={2}>
          {/* Selección Nacional */}
          <Grid item xs={12} md={4}>
            <SelectSelectionNational
              value={editablePlayer.seleccionNacional || ''}
              onChange={(e) =>
                setEditablePlayer((prev) => ({
                  ...prev,
                  seleccionNacional: e.target.value
                }))
              }
            />
          </Grid>

          {/* Equipo Actual (Autocomplete) */}
          <Grid item xs={12} md={4}>
            <Autocomplete
              freeSolo
              options={teams}
              inputValue={editablePlayer.equipo || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  equipo: newInputValue
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Equipo Actual" fullWidth />
              )}
            />
          </Grid>

          {/* Competición Europea */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Competición Europea</InputLabel>
              <Select
                name="competicionEuropa"
                value={editablePlayer.competicionEuropa || ''}
                onChange={handleChange}
                label="Competición Europea"
                endAdornment={
                  editablePlayer.competicionEuropa && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'competicionEuropa')}
                      sx={{ position: 'absolute', right: 32 }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                <MenuItem value="EuroLeague">EuroLeague</MenuItem>
                <MenuItem value="EuroCup">EuroCup</MenuItem>
                <MenuItem value="FIBA BCL">FIBA BCL</MenuItem>
                <MenuItem value="FIBA EuropeCup">FIBA EuropeCup</MenuItem>
                <MenuItem value="Lat-Est">Lat-Est</MenuItem>
                <MenuItem value="ENBL">ENBL</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Liga Principal */}
          <Grid item xs={12} md={4}>
            <Autocomplete
              freeSolo
              options={leagues1}
              inputValue={editablePlayer.liga1 || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  liga1: newInputValue
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Liga Principal" fullWidth />
              )}
            />
          </Grid>

          {/* Liga Secundaria */}
          <Grid item xs={12} md={4}>
            <Autocomplete
              freeSolo
              options={leagues2}
              inputValue={editablePlayer.liga2 || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  liga2: newInputValue
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Liga Secundaria" fullWidth />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        // READ ONLY (DESKTOP)
        <Box sx={{ mt: 1 }}>
          {!!player.seleccionNacional && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>
                Selección Nacional:&nbsp;
              </strong>
              {player.seleccionNacional}
            </Typography>
          )}
          {!!player.equipo && (
            <Typography sx={{ display: 'flex' }}>
              <strong style={{ minWidth: 'auto' }}>
                Equipo Actual:&nbsp;
              </strong>
              {player.equipo}
            </Typography>
          )}
          {(!!player.competicionEuropa || !!player.liga1 || !!player.liga2) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.competicionEuropa && (
                <Typography sx={{ display: 'flex' }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Competición Europea:&nbsp;
                  </strong>
                  {player.competicionEuropa}
                </Typography>
              )}
              {!!player.liga1 && (
                <Typography sx={{ display: 'flex' }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Liga Principal:&nbsp;
                  </strong>
                  {player.liga1}
                </Typography>
              )}
              {!!player.liga2 && (
                <Typography sx={{ display: 'flex' }}>
                  <strong style={{ minWidth: 'auto' }}>
                    Liga Secundaria:&nbsp;
                  </strong>
                  {player.liga2}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default SituacionActual;

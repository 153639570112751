// EditableFichaTecnification.jsx
import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Button, Avatar, Grid,
  Paper, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle,
  IconButton
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

// ***** Imports pour la génération de PDF *****
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import axios from 'axios';

// ***** Import du composant de reconnaissance vocale *****
import SpeechToTextButton from '../components/SpeechToTextButton';

// ***** Import du composant de gestion des médias *****
import MediaTecnification from './MediaTecnification';

const EditableFichaTecnification = ({ playerId, refresh }) => {
  const [fichas, setFichas] = useState([]);
  const [editingField, setEditingField] = useState(null);
  const [editingFichaId, setEditingFichaId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [fichaToDelete, setFichaToDelete] = useState(null);
  const [showOldFichas, setShowOldFichas] = useState(false);

  // État pour détecter si le formulaire (ou l'édition) est en cours => blocage navigation
  const [isDirty, setIsDirty] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  // ---------------------------------------------------------------------------
  // 0) GESTION DU BLOCAGE DE NAVIGATION
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = 'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    const handlePopState = (event) => {
      if (isDirty) {
        const confirmLeave = window.confirm(
          'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
        );
        if (!confirmLeave) {
          window.history.pushState(null, '', window.location.pathname);
        }
      }
    };
    window.addEventListener('popstate', handlePopState);

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    const confirmNavigation = () => {
      if (!isDirty) return true;
      return window.confirm(
        'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
      );
    };

    window.history.pushState = function(...args) {
      if (confirmNavigation()) {
        return originalPushState.apply(this, args);
      }
      return null;
    };

    window.history.replaceState = function(...args) {
      if (confirmNavigation()) {
        return originalReplaceState.apply(this, args);
      }
      return null;
    };

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [isDirty]);
  
  // ---------------------------------------------------------------------------
  // 1) Fonctions utilitaires pour le PDF : convertHtmlToText, addPageTemplate
  // ---------------------------------------------------------------------------
  const convertHtmlToText = (html) => {
    if (!html) return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    function traverse(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.nodeValue;
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tag = node.tagName.toLowerCase();
        let text = '';

        if (tag === 'br') {
          return '\n';
        }
        if (tag === 'p' || tag === 'div') {
          node.childNodes.forEach(child => {
            text += traverse(child);
          });
          text += ' ';
          return text;
        }
        if (tag === 'ol') {
          let index = 1;
          node.childNodes.forEach(child => {
            if (child.nodeType === Node.ELEMENT_NODE && child.tagName.toLowerCase() === 'li') {
              text += index + '. ' + traverse(child) + '\n';
              index++;
            } else {
              text += traverse(child);
            }
          });
          return text;
        }
        if (tag === 'ul') {
          node.childNodes.forEach(child => {
            if (child.nodeType === Node.ELEMENT_NODE && child.tagName.toLowerCase() === 'li') {
              text += '• ' + traverse(child) + '\n';
            } else {
              text += traverse(child);
            }
          });
          return text;
        }
        if (tag === 'li') {
          text += Array.from(node.childNodes).map(child => traverse(child)).join('');
          return text;
        }
        node.childNodes.forEach(child => {
          text += traverse(child);
        });
        return text;
      }
      return '';
    }

    let result = traverse(doc.body);
    result = result.replace(/\n{2,}/g, '\n').trim();
    return result;
  };

  const addPageTemplate = (doc) => {
    const pageNumber = doc.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    
    // Cadre orange
    doc.setDrawColor('#EA7F00');
    doc.setLineWidth(2);
    doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

    // Bande orange en bas
    const bandHeight = 20;
    const bandY = pageHeight - 10 - bandHeight;
    doc.setFillColor(234, 127, 0);
    doc.rect(10, bandY, pageWidth - 20, bandHeight, 'F');

    // Logos et texte (facultatif)
    const logoSize = 16;
    try {
      const logoY = bandY + (bandHeight - logoSize) / 2;
      // Logo VBC
      doc.addImage(
        `${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`,
        'PNG',
        15,
        logoY,
        logoSize,
        logoSize
      );
      // Texte centré
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(9);
      doc.text(
        'VALENCIA BASKET - DEPARTAMENTO DE SCOUTING',
        pageWidth / 2,
        bandY + bandHeight / 2 + 3,
        { align: 'center' }
      );
      // Logo Alquería
      doc.addImage(
        `${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`,
        'PNG',
        pageWidth - 15 - logoSize,
        logoY,
        logoSize,
        logoSize
      );
      // Numéro de page
      doc.setFontSize(8);
      doc.text(pageNumber.toString(), pageWidth / 2, bandY + bandHeight - 3, { align: 'center' });
    } catch (error) {
      console.error('Error adding images to PDF page:', error);
    }

    // Réinitialiser la couleur du texte en noir
    doc.setTextColor(0);
  };

  // ---------------------------------------------------------------------------
  // 2) Fonction principale de génération PDF
  // ---------------------------------------------------------------------------
  const generateFichePDF = async (ficha) => {
    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const marginLeft = 20;
      const marginRight = 20;
      let yPosition = 20;
      const lineHeight = 7;
      const contentWidth = pageWidth - marginLeft - marginRight;

      // Dessiner le template de page (cadre orange, etc.)
      addPageTemplate(doc);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');

      // 2.1) Photo du joueur (via proxy-image)
      try {
        const avatarUrl = `${mediaUrl}/photos/${ficha.player_id}.png`;
        const response = await fetch(
          `${backendUrl}/api/proxy-image?url=${encodeURIComponent(avatarUrl)}`
        );
        if (response.ok) {
          const blob = await response.blob();
          const imgData = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          doc.addImage(imgData, 'PNG', marginLeft, yPosition, 30, 30);
        }
      } catch (err) {
        console.error('Erreur lors du chargement de la photo:', err);
      }

      // 2.2) Nom du joueur
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(234, 127, 0);
      doc.text(ficha.player_nom || 'Jugador', marginLeft + 40, yPosition + 10);
      doc.setTextColor(0);

      yPosition += 40;

      // 2.3) Infos de la fiche
      doc.setFontSize(12);
      doc.text(`Fecha: ${new Date(ficha.date_fiche).toLocaleDateString()}`, marginLeft, yPosition);
      yPosition += lineHeight;
      doc.text(`Entrenador: ${ficha.user_name}`, marginLeft, yPosition);
      yPosition += lineHeight + 5;

      // Petite fonction pour éviter de déborder
      const addNewPageIfNeeded = (neededSpace) => {
        if (yPosition + neededSpace > pageHeight - 30) {
          doc.addPage();
          addPageTemplate(doc);
          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          yPosition = 20;
        }
      };

      // Helper pour imprimer un champ HTML
      const printField = (label, html) => {
        if (!html) return;
        addNewPageIfNeeded(20);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(234, 127, 0);
        doc.text(label, marginLeft, yPosition);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0);
        yPosition += lineHeight;

        const txt = convertHtmlToText(html);
        const lines = doc.splitTextToSize(txt, contentWidth);
        for (const line of lines) {
          addNewPageIfNeeded(10);
          doc.text(line, marginLeft, yPosition);
          yPosition += lineHeight;
        }
        yPosition += 5;
      };

      // 2.4) Impression de chaque champ, en prenant soin d'utiliser
      //      "Mecanica de Tira" pour la variable `tiro`
      printField('Objetivos', ficha.objectifs);
      printField('Implementar', ficha.implementar);
      printField('Rutinas', ficha.routinas);
      printField('Mecanica de Tiro', ficha.tiro);
      printField('N° Sesiones', ficha.numero_sesiones);
      printField('Observaciones', ficha.observations);

      // 2.5) Export PDF
      const dateObj = new Date(ficha.date_fiche);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      const teamString = ficha.team && ficha.team.trim() ? ` - ${ficha.team.trim()}` : '';
      const pdfTitle = `VBC PDP - ${ficha.player_nom || 'Jugador'}${teamString} - ${day}-${month}-${year}`;
      doc.save(`${pdfTitle}.pdf`);

    } catch (err) {
      console.error('Erreur PDF:', err);
    }
  };

  // ---------------------------------------------------------------------------
  // 3) Configuration de l'éditeur Quill
  // ---------------------------------------------------------------------------
  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'], 
      ['clean']
    ],
  };

  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'link'
  ];

  // ---------------------------------------------------------------------------
  // 4) Distinction saison en cours / saisons précédentes
  // ---------------------------------------------------------------------------
  const isCurrentSeason = (date) => {
    const today = new Date();
    const fichaDate = new Date(date);
    // Saison du 1er août (mois=7) au 31 juillet (mois=6)
    const currentYear = today.getMonth() >= 7 ? today.getFullYear() : today.getFullYear() - 1;
    const seasonStart = new Date(currentYear, 7, 1);
    const seasonEnd = new Date(currentYear + 1, 6, 31);
    return (fichaDate >= seasonStart && fichaDate <= seasonEnd);
  };

  // ---------------------------------------------------------------------------
  // 5) Charger les fichas depuis l’API
  // ---------------------------------------------------------------------------
  const fetchFichas = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/tecnification/fiches/${playerId}`, {
        params: { section }
      });
      setFichas(response.data);
    } catch (error) {
      console.error("Error al cargar las fichas:", error);
    }
  };

  useEffect(() => {
    if (playerId) {
      fetchFichas();
    }
  }, [playerId, refresh]);

  // ---------------------------------------------------------------------------
  // 6) Édition inline
  // ---------------------------------------------------------------------------
  const handleDoubleClick = (fichaId, field, value) => {
    setEditingFichaId(fichaId);
    setEditingField(field);
    setEditValue(value || '');
    setIsDirty(true);
  };

  const handleSave = async () => {
    if (!editingFichaId || !editingField) return;
    try {
      await axios.put(`${backendUrl}/api/tecnification/fiches/${editingFichaId}`, {
        [editingField]: editValue,
        section
      });
      await fetchFichas();
      setIsDirty(false);
      setEditingField(null);
      setEditingFichaId(null);
      setEditValue('');
    } catch (error) {
      console.error("Error al actualizar la ficha:", error);
    }
  };

  const handleCancel = () => {
    setIsDirty(false);
    setEditingField(null);
    setEditingFichaId(null);
    setEditValue('');
  };

  // ---------------------------------------------------------------------------
  // 7) Suppression d'une fiche
  // ---------------------------------------------------------------------------
  const handleDeleteClick = (ficha) => {
    setFichaToDelete(ficha);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!fichaToDelete) return;
    try {
      await axios.delete(`${backendUrl}/api/tecnification/fiches/${fichaToDelete.id}`, {
        params: { section }
      });
      fetchFichas();
      setOpenDeleteDialog(false);
      setFichaToDelete(null);
    } catch (error) {
      console.error("Error al eliminar la ficha:", error);
    }
  };

  // ---------------------------------------------------------------------------
  // 8) Champs de la table fiches_tecnification
  // ---------------------------------------------------------------------------
  const fields = [
    'objectifs',
    'implementar',
    'observations',
    'routinas',
    'tiro',            // <-- Mecanica de Tira
    'numero_sesiones'
  ];

  // Labels pour l’affichage
  const fieldNames = {
    objectifs: 'Objetivos',
    implementar: 'Implementar',
    observations: 'Observaciones',
    routinas: 'Rutinas',
    tiro: 'Mecanica de Tiro',   // Label affiché dans l'UI
    numero_sesiones: 'Número de Sesiones'
  };

  // 9) Tri fiches : saison en cours vs saisons précédentes
  const currentSeasonFichas = fichas.filter(f => isCurrentSeason(f.date_fiche));
  const oldFichas = fichas.filter(f => !isCurrentSeason(f.date_fiche));

  // Si aucune fiche
  if (fichas.length === 0) {
    return (
      <Typography color="textSecondary">
        No hay fichas registradas
      </Typography>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      {/* ***** FICHAS DE LA TEMPORADA ACTUAL ***** */}
      {currentSeasonFichas.map((ficha) => (
        <Paper 
          key={ficha.id} 
          elevation={2} 
          sx={{ 
            p: 3, 
            mb: 4,
            backgroundColor: '#f5f5f5'
          }}
        >
          {/* En-tête de la fiche */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2
            }}>
              <Avatar 
                src={`${mediaUrl}/users/${ficha.user_id}.png`}
                alt={ficha.user_name}
              />
              <Box>
                <Typography>
                  {ficha.user_name}
                </Typography>
                <Typography color="text.secondary">
                  {new Date(ficha.date_fiche).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>

            {/* Boutons d'action (PDF + Corbeille) */}
            <Box>
              <IconButton
                color="primary"
                size="small"
                onClick={() => generateFichePDF(ficha)}
                sx={{ mr: 1 }}
              >
                <PictureAsPdfIcon />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => handleDeleteClick(ficha)}
                size="small"
                sx={{
                  '&:hover': {
                    bgcolor: 'error.lighter'
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Grille des champs éditables */}
          <Grid container spacing={3}>
            {fields.map((field) => (
              <Grid item xs={12} md={4} key={`${ficha.id}-${field}`}>
                <Box sx={{ height: '100%' }}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    {fieldNames[field]}
                  </Typography>

                  {editingField === field && editingFichaId === ficha.id ? (
                    /* Mode édition */
                    <Box>
                      {/* Bouton micro + zone d'édition */}
                      <Box sx={{ 
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        overflow: 'hidden',
                        '& .ql-toolbar': {
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                          borderBottom: '1px solid',
                          borderColor: 'divider'
                        },
                        '& .ql-container': {
                          border: 'none',
                          minHeight: '200px'
                        },
                        '& .ql-editor': {
                          minHeight: '200px'
                        }
                      }}>
                        {/* Bouton micro */}
                        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
                          <SpeechToTextButton
                            backendUrl={backendUrl}
                            onTranscription={(transcript) =>
                              setEditValue((prevValue) => prevValue + '\n' + transcript)
                            }
                          />
                          <Typography variant="caption" color="textSecondary">
                            Haz clic en el micrófono para grabar
                          </Typography>
                        </Box>

                        {/* Champ spécifique pour numero_sesiones : un input simple */}
                        {field === 'numero_sesiones' ? (
                          <input
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            style={{
                              width: '100%',
                              border: 'none',
                              outline: 'none',
                              padding: '8px'
                            }}
                          />
                        ) : (
                          <ReactQuill
                            value={editValue}
                            onChange={setEditValue}
                            modules={editorModules}
                            formats={editorFormats}
                          />
                        )}
                      </Box>

                      {/* Boutons d'action (enregistrer, annuler) */}
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        gap: 1,
                        mt: 2 
                      }}>
                        <Button
                          variant="outlined"
                          startIcon={<CancelIcon />}
                          onClick={handleCancel}
                          size="small"
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={handleSave}
                          size="small"
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    /* Mode affichage */
                    <Box 
                      onDoubleClick={() => handleDoubleClick(ficha.id, field, ficha[field])}
                      sx={{ 
                        minHeight: '150px',
                        p: 2,
                        backgroundColor: 'white',
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#fafafa',
                          boxShadow: 1
                        }
                      }}
                    >
                      {field === 'numero_sesiones' ? (
                        <Typography>
                          {ficha[field]}
                        </Typography>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: ficha[field] || '' }} />
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* Nouveau composant : gestion des médias pour cette fiche */}
          <MediaTecnification ficheId={ficha.id} />
        </Paper>
      ))}

      {/* Bouton pour afficher/masquer les fiches anciennes */}
      {oldFichas.length > 0 && (
        <Button
          variant="outlined"
          onClick={() => setShowOldFichas(!showOldFichas)}
        >
          {showOldFichas ? "Ocultar fichas antiguas" : "Ver fichas antiguas"}
        </Button>
      )}

      {/* Fiches de saisons précédentes */}
      {showOldFichas && oldFichas.length > 0 && (
        <>
          <Box sx={{ 
            mt: 4, 
            mb: 2,
            p: 2,
            backgroundColor: 'grey.100',
            borderRadius: 1
          }}>
            <Typography variant="h6" color="text.secondary">
              Fichas de temporadas anteriores
            </Typography>
          </Box>
          
          {oldFichas.map((ficha) => (
            <Paper 
              key={ficha.id} 
              elevation={2} 
              sx={{ 
                p: 3, 
                mb: 4,
                backgroundColor: '#f5f5f5'
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2
                }}>
                  <Avatar 
                    src={`${mediaUrl}/users/${ficha.user_id}.png`}
                    alt={ficha.user_name}
                  />
                  <Box>
                    <Typography>
                      {ficha.user_name}
                    </Typography>
                    <Typography color="text.secondary">
                      {new Date(ficha.date_fiche).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>

                {/* Boutons d'action (PDF + Corbeille) */}
                <Box>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => generateFichePDF(ficha)}
                    sx={{ mr: 1 }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteClick(ficha)}
                    size="small"
                    sx={{
                      '&:hover': {
                        bgcolor: 'error.lighter'
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>

              <Grid container spacing={3}>
                {fields.map((field) => (
                  <Grid item xs={12} md={4} key={`${ficha.id}-${field}`}>
                    <Box sx={{ height: '100%' }}>
                      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        {fieldNames[field]}
                      </Typography>

                      {editingField === field && editingFichaId === ficha.id ? (
                        /* Mode édition */
                        <Box>
                          <Box sx={{ 
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            overflow: 'hidden',
                            '& .ql-toolbar': {
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              borderBottom: '1px solid',
                              borderColor: 'divider'
                            },
                            '& .ql-container': {
                              border: 'none',
                              minHeight: '200px'
                            },
                            '& .ql-editor': {
                              minHeight: '200px'
                            }
                          }}>
                            {/* Bouton micro */}
                            <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
                              <SpeechToTextButton
                                backendUrl={backendUrl}
                                onTranscription={(transcript) =>
                                  setEditValue((prevValue) => prevValue + '\n' + transcript)
                                }
                              />
                              <Typography variant="caption" color="textSecondary">
                                Haz clic en el micrófono para grabar
                              </Typography>
                            </Box>

                            {field === 'numero_sesiones' ? (
                              <input
                                type="text"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                style={{
                                  width: '100%',
                                  border: 'none',
                                  outline: 'none',
                                  padding: '8px'
                                }}
                              />
                            ) : (
                              <ReactQuill
                                value={editValue}
                                onChange={setEditValue}
                                modules={editorModules}
                                formats={editorFormats}
                              />
                            )}
                          </Box>

                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'flex-end', 
                            gap: 1,
                            mt: 2 
                          }}>
                            <Button
                              variant="outlined"
                              startIcon={<CancelIcon />}
                              onClick={handleCancel}
                              size="small"
                            >
                              Cancelar
                            </Button>
                            <Button
                              variant="contained"
                              startIcon={<SaveIcon />}
                              onClick={handleSave}
                              size="small"
                            >
                              Guardar
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        /* Mode affichage */
                        <Box 
                          onDoubleClick={() => handleDoubleClick(ficha.id, field, ficha[field])}
                          sx={{ 
                            minHeight: '150px',
                            p: 2,
                            backgroundColor: 'white',
                            borderRadius: 1,
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: '#fafafa',
                              boxShadow: 1
                            }
                          }}
                        >
                          {field === 'numero_sesiones' ? (
                            <Typography>
                              {ficha[field]}
                            </Typography>
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: ficha[field] || '' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>

              {/* On peut aussi proposer des médias pour les fiches anciennes */}
              <MediaTecnification ficheId={ficha.id} />
            </Paper>
          ))}
        </>
      )}

      {/* Dialog de confirmation de suppression de la fiche */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setFichaToDelete(null);
        }}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea eliminar esta ficha de tecnificación?
            Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOpenDeleteDialog(false);
              setFichaToDelete(null);
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditableFichaTecnification;

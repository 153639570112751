import React, { useState, useEffect } from 'react';
import { 
  Box, Button, Typography, Grid,
  useTheme, useMediaQuery, Avatar
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';

// Import du composant de reconnaissance vocale
import SpeechToTextButton from '../components/SpeechToTextButton';

const FichaTecnificacion = ({ playerId, onFichaAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;
  
  // État pour le mode édition
  const [isEditing, setIsEditing] = useState(false);

  // État pour bloquer la navigation si la fiche est en cours d’édition
  const [isDirty, setIsDirty] = useState(false);

  // IMPORTANT : utiliser les mêmes noms que dans la BDD : 'objectifs', 'observations', etc.
  const [objectifs, setObjectifs] = useState('');
  const [implementar, setImplementar] = useState('');
  const [observations, setObservations] = useState('');
  const [routinas, setRoutinas] = useState('');
  const [tiro, setTiro] = useState(''); // Nouveau champ
  const [numeroSesiones, setNumeroSesiones] = useState('');

  // Récupérer les informations de l'utilisateur du localStorage 
  const userInfo = JSON.parse(localStorage.getItem('user'));
  const currentDate = new Date().toLocaleDateString();

  // ---------------------------------------------------------------------------
  // 1) GESTION DU BLOCAGE DE NAVIGATION
  // ---------------------------------------------------------------------------
  useEffect(() => {
    // Bloquer la fermeture / reload (beforeunload)
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = 'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Bloquer le bouton retour (popstate)
    const handlePopState = (event) => {
      if (isDirty) {
        const confirmLeave = window.confirm(
          'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
        );
        if (!confirmLeave) {
          window.history.pushState(null, '', window.location.pathname);
        }
      }
    };

    window.addEventListener('popstate', handlePopState);

    // Bloquer les navigations internes (pushState / replaceState)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    const confirmNavigation = () => {
      if (!isDirty) return true;
      return window.confirm(
        'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
      );
    };

    window.history.pushState = function(...args) {
      if (confirmNavigation()) {
        return originalPushState.apply(this, args);
      }
      return null;
    };

    window.history.replaceState = function(...args) {
      if (confirmNavigation()) {
        return originalReplaceState.apply(this, args);
      }
      return null;
    };

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [isDirty]);

  // ---------------------------------------------------------------------------
  // 2) Fonctions de gestion du mode édition
  // ---------------------------------------------------------------------------
  const handleNewFicha = () => {
    setIsEditing(true);
    setIsDirty(true); // Bloquer la navigation dès l’ouverture du formulaire
    setObjectifs('');
    setImplementar('');
    setObservations('');
    setRoutinas('');
    setTiro('');
    setNumeroSesiones('');
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsDirty(false); // Lever le blocage si l’utilisateur annule la saisie
    setObjectifs('');
    setImplementar('');
    setObservations('');
    setRoutinas('');
    setTiro('');
    setNumeroSesiones('');
  };

  const handleSaveFicha = async () => {
    // Au moins un champ requis
    if (
      !objectifs && 
      !implementar && 
      !observations &&
      !routinas &&
      !tiro &&
      !numeroSesiones
    ) {
      return;
    }

    try {
      await axios.post(`${backendUrl}/api/tecnification/fiches`, {
        tecnification_player_id: playerId,
        user_id: userInfo.id,
        objectifs,       // dans la BDD: 'objectifs'
        implementar,     // dans la BDD: 'implementar'
        observations,    // dans la BDD: 'observations'
        routinas,        // dans la BDD: 'routinas'
        tiro,            // nouveau champ
        numero_sesiones: numeroSesiones,
        section
      });
      
      // Réinitialiser
      setIsEditing(false);
      setIsDirty(false); // Lever le blocage après un enregistrement réussi
      setObjectifs('');
      setImplementar('');
      setObservations('');
      setRoutinas('');
      setTiro('');
      setNumeroSesiones('');
      
      // Rafraîchir la liste des fiches (propagée au parent)
      if (onFichaAdded) {
        onFichaAdded();
      }
    } catch (error) {
      console.error("Error al crear la ficha:", error);
    }
  };

  // ---------------------------------------------------------------------------
  // Configuration de l’éditeur Quill
  // ---------------------------------------------------------------------------
  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],
      ['clean']
    ],
  };
  
  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link'
  ];

  // ---------------------------------------------------------------------------
  // Rendu JSX
  // ---------------------------------------------------------------------------
  return (
    <Box sx={{ mt: 2 }}>
      <Box 
        sx={{ 
          mb: 2, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}
      >
        <Typography variant="h6">
          {/* Titre éventuel si besoin */}
        </Typography>
        {!isEditing && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleNewFicha}
          >
            Nueva ficha
          </Button>
        )}
      </Box>

      {isEditing && (
        <>
          {/* En-tête de la fiche avec les infos utilisateur */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2, 
            mb: 3,
            p: 2,
            bgcolor: 'grey.100',
            borderRadius: 1
          }}>
            <Avatar
              src={`${mediaUrl}/users/${userInfo.id}.png`}
              alt={`${userInfo.prenom} ${userInfo.nom}`}
              sx={{ width: 48, height: 48 }}
            />
            <Box>
              <Typography variant="subtitle1">
                {`${userInfo.prenom} ${userInfo.nom}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {currentDate}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={2}>
            {/* OBJECTIFS */}
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Objetivos
                </Typography>
                {/* Zone micro + éditeur */}
                <Box 
                  sx={{ 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    overflow: 'hidden',
                    '& .ql-toolbar': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: '1px solid',
                      borderColor: 'divider'
                    },
                    '& .ql-container': {
                      border: 'none',
                      minHeight: isMobile ? '200px' : '200px'
                    },
                    '& .ql-editor': {
                      minHeight: isMobile ? '200px' : '200px'
                    }
                  }}
                >
                  {/* Bouton micro */}
                  <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
                    <SpeechToTextButton
                      backendUrl={backendUrl}
                      onTranscription={(transcript) =>
                        setObjectifs((prevValue) => prevValue + '\n' + transcript)
                      }
                    />
                    <Typography variant="caption" color="textSecondary">
                      Haz clic en el micrófono para grabar
                    </Typography>
                  </Box>
                  <ReactQuill
                    value={objectifs}
                    onChange={setObjectifs}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>
            
            {/* IMPLEMENTAR */}
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Implementar
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    overflow: 'hidden',
                    '& .ql-toolbar': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: '1px solid',
                      borderColor: 'divider'
                    },
                    '& .ql-container': {
                      border: 'none',
                      minHeight: isMobile ? '200px' : '200px'
                    },
                    '& .ql-editor': {
                      minHeight: isMobile ? '200px' : '200px'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
                    <SpeechToTextButton
                      backendUrl={backendUrl}
                      onTranscription={(transcript) =>
                        setImplementar((prevValue) => prevValue + '\n' + transcript)
                      }
                    />
                    <Typography variant="caption" color="textSecondary">
                      Haz clic en el micrófono para grabar
                    </Typography>
                  </Box>
                  <ReactQuill
                    value={implementar}
                    onChange={setImplementar}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>

            {/* OBSERVATIONS */}
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Observaciones
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    overflow: 'hidden',
                    '& .ql-toolbar': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: '1px solid',
                      borderColor: 'divider'
                    },
                    '& .ql-container': {
                      border: 'none',
                      minHeight: isMobile ? '200px' : '200px'
                    },
                    '& .ql-editor': {
                      minHeight: isMobile ? '200px' : '200px'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
                    <SpeechToTextButton
                      backendUrl={backendUrl}
                      onTranscription={(transcript) =>
                        setObservations((prevValue) => prevValue + '\n' + transcript)
                      }
                    />
                    <Typography variant="caption" color="textSecondary">
                      Haz clic en el micrófono para grabar
                    </Typography>
                  </Box>
                  <ReactQuill
                    value={observations}
                    onChange={setObservations}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>

            {/* ROUTINAS */}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Rutinas
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    overflow: 'hidden',
                    '& .ql-toolbar': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: '1px solid',
                      borderColor: 'divider'
                    },
                    '& .ql-container': {
                      border: 'none',
                      minHeight: isMobile ? '150px' : '150px'
                    },
                    '& .ql-editor': {
                      minHeight: isMobile ? '150px' : '150px'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
                    <SpeechToTextButton
                      backendUrl={backendUrl}
                      onTranscription={(transcript) =>
                        setRoutinas((prevValue) => prevValue + '\n' + transcript)
                      }
                    />
                    <Typography variant="caption" color="textSecondary">
                      Haz clic en el micrófono para grabar
                    </Typography>
                  </Box>
                  <ReactQuill
                    value={routinas}
                    onChange={setRoutinas}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Nouveau champ TIRO placé avant Número de Sesiones */}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Mecanica de Tiro
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    overflow: 'hidden',
                    '& .ql-toolbar': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: '1px solid',
                      borderColor: 'divider'
                    },
                    '& .ql-container': {
                      border: 'none',
                      minHeight: isMobile ? '150px' : '150px'
                    },
                    '& .ql-editor': {
                      minHeight: isMobile ? '150px' : '150px'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
                    <SpeechToTextButton
                      backendUrl={backendUrl}
                      onTranscription={(transcript) =>
                        setTiro((prevValue) => prevValue + '\n' + transcript)
                      }
                    />
                    <Typography variant="caption" color="textSecondary">
                      Haz clic en el micrófono para grabar
                    </Typography>
                  </Box>
                  <ReactQuill
                    value={tiro}
                    onChange={setTiro}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>

            {/* NÚMERO DE SESIONES */}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Número de Sesiones
                </Typography>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    p: 1,
                    backgroundColor: 'white'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <SpeechToTextButton
                      backendUrl={backendUrl}
                      onTranscription={(transcript) =>
                        setNumeroSesiones((prevValue) => prevValue + ' ' + transcript)
                      }
                    />
                    <input
                      type="text"
                      value={numeroSesiones}
                      onChange={(e) => setNumeroSesiones(e.target.value)}
                      style={{
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent'
                      }}
                      placeholder="Ej. 10 sesiones..."
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ 
            mt: 2, 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 2
          }}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveFicha}
              disabled={
                !objectifs && 
                !implementar && 
                !observations &&
                !routinas &&
                !tiro &&
                !numeroSesiones
              }
            >
              Guardar ficha
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FichaTecnificacion;

// MediaTecnification.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Fab,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactPlayer from 'react-player';
import axios from 'axios';

const MediaTecnification = ({ ficheId }) => {
  const [medias, setMedias] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState('add'); // 'add' ou 'edit'
  const [currentMedia, setCurrentMedia] = useState({ id: null, titre: '', url: '' });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);

  // Compteur local si vous en aviez besoin, 
  // mais ici on récupère un ID réel depuis la BDD
  // const [autoId, setAutoId] = useState(1);

  // Exemple de variables d'environnement
  const backendUrl = process.env.REACT_APP_BACKEND_URL; 
  const section = process.env.REACT_APP_SECTION;

  // ---------------------------------------------------------------------------
  // 1) Charger les médias depuis l'API
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const fetchMedias = async () => {
      try {
        if (!ficheId) return;
        const response = await axios.get(
          `${backendUrl}/api/tecnification/fiches/${ficheId}/medias`,
          { params: { section } }
        );
        setMedias(response.data); // Tableau de médias
      } catch (error) {
        console.error('Error fetching medias:', error);
      }
    };

    fetchMedias();
  }, [ficheId, backendUrl, section]);

  // ---------------------------------------------------------------------------
  // 2) Gérer l'ajout (ouvrir le dialogue en mode "add")
  // ---------------------------------------------------------------------------
  const handleAddClick = () => {
    setDialogMode('add');
    setCurrentMedia({ id: null, titre: '', url: '' });
    setDialogOpen(true);
  };

  // ---------------------------------------------------------------------------
  // 3) Gérer l'édition (ouvrir le dialogue en mode "edit")
  // ---------------------------------------------------------------------------
  const handleEditClick = (media) => {
    setDialogMode('edit');
    setCurrentMedia(media);
    setDialogOpen(true);
  };

  // ---------------------------------------------------------------------------
  // 4) Enregistrer (ajout ou édition)
  // ---------------------------------------------------------------------------
  const handleDialogSave = async () => {
    try {
      if (dialogMode === 'add') {
        // POST => /fiches/:ficheId/medias
        const response = await axios.post(
          `${backendUrl}/api/tecnification/fiches/${ficheId}/medias`,
          {
            titre: currentMedia.titre,
            url: currentMedia.url,
            section,
          }
        );
        // On récupère l'objet nouvellement créé (avec son ID)
        const newMedia = response.data;
        // Mise à jour du state local
        setMedias([...medias, newMedia]);
      } else {
        // PUT => /medias/:id
        await axios.put(
          `${backendUrl}/api/tecnification/medias/${currentMedia.id}`,
          {
            titre: currentMedia.titre,
            url: currentMedia.url,
            section,
          }
        );
        // Mettre à jour localement
        setMedias((prev) =>
          prev.map((m) =>
            m.id === currentMedia.id
              ? { ...m, titre: currentMedia.titre, url: currentMedia.url }
              : m
          )
        );
      }
      setDialogOpen(false);
    } catch (error) {
      console.error('Error saving media:', error);
      alert('Error guardando el media');
    }
  };

  // ---------------------------------------------------------------------------
  // 5) Annuler l'ajout / édition
  // ---------------------------------------------------------------------------
  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  // ---------------------------------------------------------------------------
  // 6) Ouvrir le dialogue de confirmation de suppression
  // ---------------------------------------------------------------------------
  const handleDeleteClick = (media) => {
    setMediaToDelete(media);
    setDeleteDialogOpen(true);
  };

  // ---------------------------------------------------------------------------
  // 7) Confirmer la suppression
  // ---------------------------------------------------------------------------
  const handleDeleteConfirm = async () => {
    try {
      if (!mediaToDelete) return;

      // DELETE => /medias/:id
      await axios.delete(
        `${backendUrl}/api/tecnification/medias/${mediaToDelete.id}`,
        { params: { section } }
      );

      // Mettre à jour localement
      setMedias((prev) => prev.filter((m) => m.id !== mediaToDelete.id));

    } catch (error) {
      console.error('Error deleting media:', error);
      alert('Error eliminando el media');
    } finally {
      setDeleteDialogOpen(false);
      setMediaToDelete(null);
    }
  };

  // ---------------------------------------------------------------------------
  // 8) Annuler la suppression
  // ---------------------------------------------------------------------------
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setMediaToDelete(null);
  };

  // ---------------------------------------------------------------------------
  // Render
  // ---------------------------------------------------------------------------
  return (
    <Box sx={{ position: 'relative', mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Media(s) para la ficha 
      </Typography>

      {/* Liste de médias */}
      {medias.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          Aún no hay ningún media registrado para esta ficha.
        </Typography>
      ) : (
        medias.map((media) => (
          <Card key={media.id} sx={{ mb: 1 }}>
            <CardContent>
              {/* En-tête : Titre + Actions (Edit / Delete) */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'space-between' 
                }}
              >
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {media.titre || ''}
                  </Typography>
                  
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => handleEditClick(media)}
                    sx={{ mr: 1 }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDeleteClick(media)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>

              {/* Zone vidéo (ReactPlayer) */}
              <Box 
                sx={{ 
                  mt: 2, 
                  p: 2, 
                  bgcolor: 'white', 
                  border: 1, 
                  borderColor: 'divider' 
                }}
              >
                <ReactPlayer
                  url={media.url}
                  controls
                  width="100%"
                />
              </Box>
            </CardContent>
          </Card>
        ))
      )}

      {/* Bouton flottant pour ajouter un média */}
      <Tooltip title="Agregar un nuevo media">
        <Fab
          color="primary"
          size="small"
          onClick={handleAddClick}
          sx={{ 
            position: 'absolute',
            bottom: 0,
            right: 0,
            transform: 'translate(50%, 50%)'
          }}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      {/* Dialog pour l'ajout / édition */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogCancel}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {dialogMode === 'add' ? 'Agregar Media' : 'Editar Media'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Título (opcional)"
            fullWidth
            margin="normal"
            value={currentMedia.titre}
            onChange={(e) =>
              setCurrentMedia((prev) => ({ ...prev, titre: e.target.value }))
            }
          />
          <TextField
            label="URL"
            fullWidth
            margin="normal"
            value={currentMedia.url}
            onChange={(e) =>
              setCurrentMedia((prev) => ({ ...prev, url: e.target.value }))
            }
            placeholder="Ej: https://www.youtube.com/watch?v=abc123..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Cancelar</Button>
          <Button onClick={handleDialogSave} variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de confirmation de suppression */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro que desea eliminar este media?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancelar</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MediaTecnification;

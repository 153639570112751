import React, { useEffect, useState } from 'react';
import {
  Button, Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, 
  Avatar, Divider, Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HeightIcon from '@mui/icons-material/Height';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import EditableStatField from './EditableStatField';
import EditableTextField from './EditableTextField';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

// ********** IMPORT DU NOUVEAU COMPOSANT **********
import MediaTechnica from './MediaTechnica'; 

// Enregistrement des plugins pour les graphiques
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title
);

const getSeasonYear = (date) => {
  const d = new Date(date);
  const month = d.getMonth(); 
  const year = d.getFullYear();
  return month >= 7 ? year : year - 1;
};

const getSeasonLabel = (year) => `Temporada ${year}-${year + 1}`;

const getAvatarUrl = (userId) => {
  return `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`;
};

// -----------------------------------------------------------------------------
// Convertir du HTML en texte brut
const convertHtmlToText = (html) => {
  if (!html) return '';
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  function traverse(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue;
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
      const tag = node.tagName.toLowerCase();
      let text = '';

      if (tag === 'br') {
        return '\n';
      }
      if (tag === 'p' || tag === 'div') {
        node.childNodes.forEach((child) => {
          text += traverse(child);
        });
        text += ' ';
        return text;
      }
      if (tag === 'ol') {
        let index = 1;
        node.childNodes.forEach((child) => {
          if (child.nodeType === Node.ELEMENT_NODE && child.tagName.toLowerCase() === 'li') {
            text += index + '. ' + traverse(child) + '\n';
            index++;
          } else {
            text += traverse(child);
          }
        });
        return text;
      }
      if (tag === 'ul') {
        node.childNodes.forEach((child) => {
          if (child.nodeType === Node.ELEMENT_NODE && child.tagName.toLowerCase() === 'li') {
            text += '• ' + traverse(child) + '\n';
          } else {
            text += traverse(child);
          }
        });
        return text;
      }
      if (tag === 'li') {
        text += Array.from(node.childNodes).map((child) => traverse(child)).join('');
        return text;
      }

      node.childNodes.forEach((child) => {
        text += traverse(child);
      });
      return text;
    }
    return '';
  }

  let result = traverse(doc.body);
  result = result.replace(/\n{2,}/g, '\n').trim();
  return result;
};

// -----------------------------------------------------------------------------
// Template page PDF : cadre orange, bande orange en bas
const addPageTemplate = (doc) => {
  const pageNumber = doc.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;

  // Cadre
  doc.setDrawColor('#EA7F00');
  doc.setLineWidth(2);
  doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

  // Bande orange
  const bandHeight = 20;
  const bandY = pageHeight - 10 - bandHeight;
  doc.setFillColor(234, 127, 0);
  doc.rect(10, bandY, pageWidth - 20, bandHeight, 'F');

  // Logos, texte et numéro de page
  const logoSize = 16;
  try {
    const logoY = bandY + (bandHeight - logoSize) / 2;
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`,
      'PNG',
      15,
      logoY,
      logoSize,
      logoSize
    );
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(9);
    doc.text(
      'VALENCIA BASKET - DEPARTAMENTO DE SCOUTING',
      pageWidth / 2,
      bandY + bandHeight / 2 + 3,
      { align: 'center' }
    );
    doc.addImage(
      `${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`,
      'PNG',
      pageWidth - 15 - logoSize,
      logoY,
      logoSize,
      logoSize
    );
    doc.setFontSize(8);
    doc.text(pageNumber.toString(), pageWidth / 2, bandY + bandHeight - 3, { align: 'center' });
  } catch (error) {
    console.error('Error adding images to PDF page:', error);
  }

  doc.setTextColor(0);
  doc.setFontSize(12);
};
// -----------------------------------------------------------------------------

const TechnicaTab = ({ playerId, dateNaissance, nom, onDataLoaded }) => {
  const [techData, setTechData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const [openDialog, setOpenDialog] = useState(false);
  const [ficheToDelete, setFicheToDelete] = useState(null);

  const handleDeleteClick = (fiche) => {
    setFicheToDelete(fiche);
    setOpenDialog(true);
  };
  
  const handleClose = () => {
    setOpenDialog(false);
    setFicheToDelete(null);
  };
  
  const handleConfirmDelete = async () => {
    if (ficheToDelete) {
      try {
        await axios.delete(`${backendUrl}/api/technique/${ficheToDelete.id}`, {
          data: { section }
        });
        fetchTechData(); 
        handleClose();
      } catch (error) {
        console.error("Error al eliminar la ficha técnica:", error);
      }
    }
  };

  const fetchTechData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/technique`, { 
        params: { 
          playerId,
          section
        } 
      });
      const data = response.data;
      setTechData(data);

      // Données pour graphique
      if (data.length >= 2 && dateNaissance) {
        const birthDate = new Date(dateNaissance);
        const processedData = data
          .map(item => {
            const measurementDate = new Date(item.fecha);
            const ageAtMeasurement = 
              (measurementDate - birthDate) / (1000 * 60 * 60 * 24 * 365.25);
            return {
              age: parseFloat(ageAtMeasurement.toFixed(1)),
              altura_sin_zapatos: item.altura_sin_zapatos,
              peso_kg: item.peso_kg,
              date: measurementDate.toLocaleDateString()
            };
          })
          .sort((a, b) => a.age - b.age);
        setChartData(processedData);
      }

      // Groupement par saison
      const grouped = data.reduce((acc, item) => {
        const seasonYear = getSeasonYear(item.fecha);
        if (!acc[seasonYear]) {
          acc[seasonYear] = [];
        }
        acc[seasonYear].push(item);
        return acc;
      }, {});

      // Tri desc par date
      Object.keys(grouped).forEach(season => {
        grouped[season].sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      });

      setGroupedData(grouped);

      if (onDataLoaded) {
        onDataLoaded(data.length > 0);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données techniques:", error);
    }
  };

  useEffect(() => {
    fetchTechData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId, section, onDataLoaded, dateNaissance]);

  // ---------------------------------------------------------------------------
  // Générer le PDF
  const generatePDF = async (item) => {
    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const marginLeft = 20;
      const marginRight = 20;
      let yPosition = 20;
      const lineHeight = 7;
      const contentWidth = pageWidth - marginLeft - marginRight;

      addPageTemplate(doc);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');

      const addNewPageIfNeeded = (neededSpace = 20) => {
        if (yPosition + neededSpace > pageHeight - 20) {
          doc.addPage();
          addPageTemplate(doc);
          yPosition = 20;
        }
      };

      // Photo
      try {
        const avatarUrl = `${process.env.REACT_APP_MEDIA_URL}/photos/${playerId}.png`;
        const response = await fetch(
          `${backendUrl}/api/proxy-image?url=${encodeURIComponent(avatarUrl)}`
        );
        const blob = await response.blob();
        const imgData = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
        doc.addImage(imgData, 'PNG', marginLeft, yPosition, 30, 30);
      } catch (err) {
        console.error('Erreur lors du chargement de la photo:', err);
      }

      // Titre
      doc.setFontSize(24);
      doc.setTextColor(234, 127, 0);
      doc.text('Ficha Técnica', pageWidth / 2, yPosition + 15, { align: 'center' });
      doc.setFontSize(18);
      doc.setTextColor(0);
      doc.text(nom, pageWidth / 2, yPosition + 30, { align: 'center' });
      doc.setFontSize(12);
      yPosition += 40;

      doc.text(`Fecha: ${new Date(item.fecha).toLocaleDateString()}`, marginLeft, yPosition);
      doc.text(`Entrenador: ${item.usuario_nombre}`, marginLeft, yPosition + lineHeight);
      yPosition += 20;

      // Sous-titre + tableau autoTable
      doc.setFontSize(16);
      doc.setTextColor(234, 127, 0);
      doc.text('Medidas Físicas', marginLeft, yPosition);
      doc.setFontSize(12);
      doc.setTextColor(0);
      yPosition += 10;

      doc.autoTable({
        startY: yPosition,
        head: [['Medida', 'Valor']],
        body: [
          ['Peso', item.peso_kg ? `${item.peso_kg} kg` : '-'],
          ['Body Fat', item.body_fat_porcentaje ? `${item.body_fat_porcentaje}%` : '-'],
          ['Altura s/ Zapatos', item.altura_sin_zapatos ? `${item.altura_sin_zapatos} cm` : '-'],
          ['Altura c/ Zapatos', item.altura_con_zapatos ? `${item.altura_con_zapatos} cm` : '-'],
          ['Envergadura Vertical', item.entrega_vertical ? `${item.entrega_vertical} cm` : '-'],
          ['Envergadura Horizontal', item.entrega_horizontal ? `${item.entrega_horizontal} cm` : '-'],
        ],
        theme: 'striped',
        headStyles: {
          fillColor: [234, 127, 0],
          textColor: [255, 255, 255],
          fontSize: 10,
          fontStyle: 'bold',
        },
        styles: {
          fontSize: 9,
          cellPadding: 3,
          overflow: 'linebreak',
        },
        columnStyles: {
          0: { cellWidth: contentWidth * 0.7 },
          1: { cellWidth: contentWidth * 0.3, halign: 'center' },
        },
        margin: { left: marginLeft, right: marginRight },
        didDrawPage: function () {
          addPageTemplate(doc);
        },
      });
      yPosition = doc.lastAutoTable.finalY + 10;

      const printSectionText = (title, fields) => {
        addNewPageIfNeeded(30);
        doc.setFontSize(14);
        doc.setTextColor(234, 127, 0);
        doc.setFont('helvetica', 'bold');
        doc.text(title, marginLeft, yPosition);
        yPosition += 10;
        doc.setFontSize(12);
        doc.setTextColor(0);
        doc.setFont('helvetica', 'normal');

        fields.forEach((field) => {
          addNewPageIfNeeded(20);
          const label = field.label;
          let value = field.value ? field.value.trim() : '';
          value = convertHtmlToText(value);
          const displayValue = value && value.length > 0 ? value : '-';

          doc.setFont('helvetica', 'bold');
          doc.text(`${label}:`, marginLeft, yPosition);
          yPosition += 6;

          doc.setFont('helvetica', 'normal');
          const textLines = doc.splitTextToSize(displayValue, contentWidth);
          textLines.forEach(line => {
            addNewPageIfNeeded(10);
            doc.text(line, marginLeft, yPosition);
            yPosition += 6;
          });
          yPosition += 4;
        });
      };

      // Impression des différentes sections (attaques, défenses, etc.)
      printSectionText('Medidas Físicas (Comentarios)', [
        { label: 'Comentarios y Observaciones', value: item.comentarios_observaciones_medidas },
        { label: 'Escala Perfil Atlético', value: item.escala_perfil_atletico },
        { label: 'Informe Médico', value: item.informe_medico },
      ]);

      printSectionText('Ataque - Aspectos Individuales', [
        { label: 'Puntos Fuertes', value: item.ataque_asp_ind_puntos_fuertes },
        { label: 'Objetivos de Mejora', value: item.ataque_asp_ind_objetivos_mejora },
        { label: 'Observaciones Individuales', value: item.ataque_asp_ind_observaciones_individuales },
      ]);

      printSectionText('Ataque - Aspectos Colectivos', [
        { label: 'Puntos Fuertes', value: item.ataque_asp_col_puntos_fuertes },
        { label: 'Objetivos de Mejora', value: item.ataque_asp_col_objetivos_mejora },
        { label: 'Observaciones Colectivas', value: item.ataque_asp_col_observaciones_colectivas },
      ]);

      printSectionText('Defensa - Aspectos Individuales', [
        { label: 'Puntos Fuertes', value: item.defensa_asp_ind_puntos_fuertes },
        { label: 'Objetivos de Mejora', value: item.defensa_asp_ind_objetivos_mejora },
        { label: 'Observaciones Individuales', value: item.defensa_asp_ind_observaciones_individuales },
      ]);

      printSectionText('Defensa - Aspectos Colectivos', [
        { label: 'Puntos Fuertes', value: item.defensa_asp_col_puntos_fuertes },
        { label: 'Objetivos de Mejora', value: item.defensa_asp_col_objetivos_mejora },
        { label: 'Observaciones Colectivas', value: item.defensa_asp_col_observaciones_colectivas },
      ]);

      printSectionText('Información Audiovisual', [
        { label: 'Observaciones', value: item.info_audiovisual_observaciones },
      ]);

      printSectionText('Aspectos Físicos', [
        { label: 'Puntos Fuertes', value: item.asp_fisicos_puntos_fuertes },
        { label: 'Aspectos que Potenciar', value: item.asp_fisicos_aspectos_potenciar },
        { label: 'Desarrollo motriz', value: item.asp_fisicos_desarrollo_motriz },
        { label: 'Observaciones', value: item.asp_fisicos_observaciones },
      ]);

      printSectionText('Aspectos Psicológicos', [
        { label: 'Observaciones', value: item.asp_psicologicos_observaciones },
      ]);

      printSectionText('Ámbito Académico', [
        { label: 'Observaciones', value: item.ambito_academico_observaciones },
      ]);

      printSectionText('INTEL', [
        { label: 'Observaciones', value: item.intel_observaciones },
      ]);

      doc.save(
        `ficha-tecnica-${nom}-${new Date(item.fecha).toLocaleDateString().replace(/\//g, '-')}.pdf`
      );

    } catch (error) {
      console.error('Erreur PDF:', error);
    }
  };
  // ---------------------------------------------------------------------------

  const labelStyle = { fontSize: '0.8rem', mb: 1, color: '#ff9800' };

  // ---------------------------------------------------------------------------
  // Graphique de l'évolution de la taille
  const HeightChart = () => {
    const filteredData = chartData.filter(d => d.altura_sin_zapatos);
    if (filteredData.length < 2) return null;

    const labels = filteredData.map(d => d.age);
    const dataValues = filteredData.map(d => d.altura_sin_zapatos);

    const data = {
      labels,
      datasets: [
        {
          label: 'Altura s/ Zapatos (cm)',
          data: dataValues,
          borderColor: 'orange',
          backgroundColor: 'orange',
          tension: 0.3,
          spanGaps: true
        }
      ]
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: 'Evolución Altura con la Edad',
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: 'Altura (cm)'
          }
        },
        x: {
          title: {
            display: true,
            text: 'Edad (años)'
          }
        }
      }
    };

    return (
      <Box sx={{ mb: 4, height: '400px' }}>
        <Line data={data} options={options} />
      </Box>
    );
  };

  // ---------------------------------------------------------------------------
  // Graphique de l'évolution du poids
  const WeightChart = () => {
    const filteredData = chartData.filter(d => d.peso_kg);
    if (filteredData.length < 2) return null;

    const labels = filteredData.map(d => d.age);
    const dataValues = filteredData.map(d => d.peso_kg);

    const data = {
      labels,
      datasets: [
        {
          label: 'Peso (kg)',
          data: dataValues,
          borderColor: 'blue',
          backgroundColor: 'blue',
          tension: 0.3,
          spanGaps: true
        }
      ]
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: 'Evolución Peso con la Edad',
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: 'Peso (kg)'
          }
        },
        x: {
          title: {
            display: true,
            text: 'Edad (años)'
          }
        }
      }
    };

    return (
      <Box sx={{ mb: 4, height: '400px' }}>
        <Line data={data} options={options} />
      </Box>
    );
  };

  // ---------------------------------------------------------------------------
  // RENDER MEDIDAS FÍSICAS
  const renderMedidasFisicas = (seasonData, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Medidas Físicas
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {seasonData.map((item, index) => (
          <Box 
            key={item.id}
            sx={{ 
              mb: 2,
              backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
              p: 2,
              borderRadius: 1
            }}
          >
            <Chip 
              label={new Date(item.fecha).toLocaleDateString()}
              sx={{ mb: 2 }}
              avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
              size="small"
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={labelStyle}>Peso</Typography>
                <EditableStatField
                  item={item}
                  fieldName="peso_kg"
                  icon={FitnessCenterIcon}
                  unit="kg"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={labelStyle}>Body Fat</Typography>
                <EditableStatField
                  item={item}
                  fieldName="body_fat_porcentaje"
                  icon={FitnessCenterIcon}
                  unit="%"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={labelStyle}>Altura c/ Zapatos</Typography>
                <EditableStatField
                  item={item}
                  fieldName="altura_con_zapatos"
                  icon={HeightIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={labelStyle}>Altura s/ Zapatos</Typography>
                <EditableStatField
                  item={item}
                  fieldName="altura_sin_zapatos"
                  icon={HeightIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={labelStyle}>Envergadura Vertical</Typography>
                <EditableStatField
                  item={item}
                  fieldName="entrega_vertical"
                  icon={AccessibilityNewIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={labelStyle}>Envergadura Horizontal</Typography>
                <EditableStatField
                  item={item}
                  fieldName="entrega_horizontal"
                  icon={AccessibilityNewIcon}
                  unit="cm"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  size="small"
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" sx={labelStyle}>Comentarios y Observaciones</Typography>
              <EditableTextField
                item={item}
                fieldName="comentarios_observaciones_medidas"
                playerId={playerId}
                onUpdate={fetchTechData}
                variant="outlined"
                fullWidth
                multiline
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" sx={labelStyle}>Escala Perfil Atlético</Typography>
              <EditableTextField
                item={item}
                fieldName="escala_perfil_atletico"
                playerId={playerId}
                onUpdate={fetchTechData}
                variant="outlined"
                fullWidth
                multiline
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" sx={labelStyle}>Informe Médico</Typography>
              <EditableTextField
                item={item}
                fieldName="informe_medico"
                playerId={playerId}
                onUpdate={fetchTechData}
                variant="outlined"
                fullWidth
                multiline
              />
            </Box>
            {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  // ---------------------------------------------------------------------------
  // RENDER ATAQUE
  const renderAtaque = (seasonData, isLatestSeason) => (
    <Accordion
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Ataque
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        <Accordion
          defaultExpanded={true}
          sx={{
            mb: 4,
            border: '2px solid #ff9800',
            borderRadius: 2,
            overflow: 'hidden',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              borderBottom: '2px solid #ff9800',
              backgroundColor: '#fff',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Aspectos Individuales
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2 }}>
            {seasonData.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  mb: 2,
                  backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
                  p: 2,
                  borderRadius: 1
                }}
              >
                <Chip
                  label={new Date(item.fecha).toLocaleDateString()}
                  sx={{ mb: 2 }}
                  avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
                  size="small"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Puntos Fuertes</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="ataque_asp_ind_puntos_fuertes"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Objetivos de Mejora</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="ataque_asp_ind_objetivos_mejora"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={labelStyle}>Observaciones Individuales</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="ataque_asp_ind_observaciones_individuales"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded={true}
          sx={{
            mb: 4,
            border: '2px solid #ff9800',
            borderRadius: 2,
            overflow: 'hidden',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              borderBottom: '2px solid #ff9800',
              backgroundColor: '#fff',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Aspectos Colectivos
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2 }}>
            {seasonData.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  mb: 2,
                  backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
                  p: 2,
                  borderRadius: 1
                }}
              >
                <Chip
                  label={new Date(item.fecha).toLocaleDateString()}
                  sx={{ mb: 2 }}
                  avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
                  size="small"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Puntos Fuertes</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="ataque_asp_col_puntos_fuertes"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Objetivos de Mejora</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="ataque_asp_col_objetivos_mejora"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={labelStyle}>Observaciones Colectivas</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="ataque_asp_col_observaciones_colectivas"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );

  // ---------------------------------------------------------------------------
  // RENDER DEFENSA
  const renderDefensa = (seasonData, isLatestSeason) => (
    <Accordion
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Defensa
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        <Accordion
          defaultExpanded={true}
          sx={{
            mb: 4,
            border: '2px solid #ff9800',
            borderRadius: 2,
            overflow: 'hidden',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              borderBottom: '2px solid #ff9800',
              backgroundColor: '#fff',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Aspectos Individuales
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2 }}>
            {seasonData.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  mb: 2,
                  backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
                  p: 2,
                  borderRadius: 1
                }}
              >
                <Chip
                  label={new Date(item.fecha).toLocaleDateString()}
                  sx={{ mb: 2 }}
                  avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
                  size="small"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Puntos Fuertes</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="defensa_asp_ind_puntos_fuertes"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Objetivos de Mejora</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="defensa_asp_ind_objetivos_mejora"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={labelStyle}>Observaciones Individuales</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="defensa_asp_ind_observaciones_individuales"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded={true}
          sx={{
            mb: 4,
            border: '2px solid #ff9800',
            borderRadius: 2,
            overflow: 'hidden',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              borderBottom: '2px solid #ff9800',
              backgroundColor: '#fff',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Aspectos Colectivos
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 2 }}>
            {seasonData.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  mb: 2,
                  backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
                  p: 2,
                  borderRadius: 1
                }}
              >
                <Chip
                  label={new Date(item.fecha).toLocaleDateString()}
                  sx={{ mb: 2 }}
                  avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
                  size="small"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Puntos Fuertes</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="defensa_asp_col_puntos_fuertes"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={labelStyle}>Objetivos de Mejora</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="defensa_asp_col_objetivos_mejora"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={labelStyle}>Observaciones Colectivas</Typography>
                    <EditableTextField
                      item={item}
                      fieldName="defensa_asp_col_observaciones_colectivas"
                      playerId={playerId}
                      onUpdate={fetchTechData}
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );

  // ---------------------------------------------------------------------------
  // RENDER INFORMACIÓN AUDIOVISUAL
  // => ICI ON INTÈGRE LE NOUVEAU COMPOSANT "MediaTechnica"
  const renderInfoAudiovisual = (seasonData, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Información Audiovisual
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {seasonData.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              mb: 2,
              backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
              p: 2,
              borderRadius: 1
            }}
          >
            <Chip 
              label={new Date(item.fecha).toLocaleDateString()}
              sx={{ mb: 2 }}
              avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
              size="small"
            />
            <Typography variant="body2" sx={labelStyle}>Observaciones</Typography>
            <EditableTextField
              item={item}
              fieldName="info_audiovisual_observaciones"
              playerId={playerId}
              onUpdate={fetchTechData}
              variant="outlined"
              fullWidth
              multiline
            />

            {/* On ajoute notre composant MediaTechnica ici */}
            <Box sx={{ mt: 2 }}>
              <MediaTechnica itemId={item.id} />
            </Box>

            {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  // ---------------------------------------------------------------------------
  // RENDER ASPECTOS FISICOS
  const renderAspectosFisicos = (seasonData, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Aspectos Físicos
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {seasonData.map((item, index) => (
          <Box 
            key={item.id}
            sx={{ 
              mb: 2,
              backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
              p: 2,
              borderRadius: 1
            }}
          >
            <Chip 
              label={new Date(item.fecha).toLocaleDateString()}
              sx={{ mb: 2 }}
              avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
              size="small"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" sx={labelStyle}>Puntos Fuertes</Typography>
                <EditableTextField
                  item={item}
                  fieldName="asp_fisicos_puntos_fuertes"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" sx={labelStyle}>Aspectos que Potenciar</Typography>
                <EditableTextField
                  item={item}
                  fieldName="asp_fisicos_aspectos_potenciar"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" sx={labelStyle}>Desarrollo motriz</Typography>
                <EditableTextField
                  item={item}
                  fieldName="asp_fisicos_desarrollo_motriz"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" sx={labelStyle}>Observaciones</Typography>
                <EditableTextField
                  item={item}
                  fieldName="asp_fisicos_observaciones"
                  playerId={playerId}
                  onUpdate={fetchTechData}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>
            </Grid>
            {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  // ---------------------------------------------------------------------------
  // RENDER ASPECTOS PSICOLÓGICOS
  const renderAspectosPsicologicos = (seasonData, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Aspectos Psicológicos
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {seasonData.map((item, index) => (
          <Box 
            key={item.id}
            sx={{ 
              mb: 2,
              backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
              p: 2,
              borderRadius: 1
            }}
          >
            <Chip 
              label={new Date(item.fecha).toLocaleDateString()}
              sx={{ mb: 2 }}
              avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
              size="small"
            />
            <Typography variant="body2" sx={labelStyle}>Observaciones</Typography>
            <EditableTextField
              item={item}
              fieldName="asp_psicologicos_observaciones"
              playerId={playerId}
              onUpdate={fetchTechData}
              variant="outlined"
              fullWidth
              multiline
            />
            {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  // ---------------------------------------------------------------------------
  // RENDER ÁMBITO ACADÉMICO
  const renderAmbitoAcademico = (seasonData, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Ámbito Académico
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {seasonData.map((item, index) => (
          <Box 
            key={item.id}
            sx={{ 
              mb: 2,
              backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
              p: 2,
              borderRadius: 1
            }}
          >
            <Chip 
              label={new Date(item.fecha).toLocaleDateString()}
              sx={{ mb: 2 }}
              avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
              size="small"
            />
            <Typography variant="body2" sx={labelStyle}>Observaciones</Typography>
            <EditableTextField
              item={item}
              fieldName="ambito_academico_observaciones"
              playerId={playerId}
              onUpdate={fetchTechData}
              variant="outlined"
              fullWidth
              multiline
            />
            {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  // ---------------------------------------------------------------------------
  // RENDER INTEL
  const renderIntel = (seasonData, isLatestSeason) => (
    <Accordion 
      defaultExpanded={isLatestSeason}
      sx={{ 
        mb: 4,
        border: '2px solid #ff9800',
        borderRadius: 2,
        overflow: 'hidden',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        sx={{
          borderBottom: '2px solid #ff9800',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          INTEL
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {seasonData.map((item, index) => (
          <Box 
            key={item.id}
            sx={{ 
              mb: 2,
              backgroundColor: index % 2 === 0 ? '#fff' : '#fff8e1',
              p: 2,
              borderRadius: 1
            }}
          >
            <Chip 
              label={new Date(item.fecha).toLocaleDateString()}
              sx={{ mb: 2 }}
              avatar={<Avatar src={getAvatarUrl(item.usuario_id)} />}
              size="small"
            />
            <Typography variant="body2" sx={labelStyle}>Observaciones</Typography>
            <EditableTextField
              item={item}
              fieldName="intel_observaciones"
              playerId={playerId}
              onUpdate={fetchTechData}
              variant="outlined"
              fullWidth
              multiline
            />
            {index < seasonData.length - 1 && <Divider sx={{ mt: 2 }} />}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  if (!Object.keys(groupedData).length) {
    return <Typography variant="h6">Aucune donnée disponible</Typography>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      {/* Graphiques (Taille, Poids) */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <HeightChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <WeightChart />
        </Grid>
      </Grid>

      {Object.entries(groupedData)
        .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
        .map(([year, seasonData], index) => {
          const isLatestSeason = index === 0;
          return (
            <Accordion key={year} defaultExpanded={isLatestSeason}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  minHeight: '64px',
                  '& .MuiAccordionSummary-content': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    border: '2px solid #ff9800',
                    borderRadius: '25px',
                    padding: '8px 16px',
                    color: '#ff9800',
                    fontWeight: 'bold',
                  }}
                >
                  <Typography 
                    variant="h6"
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    {getSeasonLabel(Number(year))}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {renderMedidasFisicas(seasonData, isLatestSeason)}
                {renderAtaque(seasonData, isLatestSeason)}
                {renderDefensa(seasonData, isLatestSeason)}
                {renderInfoAudiovisual(seasonData, isLatestSeason)}  {/* <--- MEDIA */}
                {renderAspectosFisicos(seasonData, isLatestSeason)}
                {renderAspectosPsicologicos(seasonData, isLatestSeason)}
                {renderAmbitoAcademico(seasonData, isLatestSeason)}
                {renderIntel(seasonData, isLatestSeason)}

                {/* Zone d'export PDF et de suppression */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>Exportar PDF</Typography>
                  <Grid container spacing={2}>
                    {seasonData.map((item) => (
                      <Grid item key={item.id}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Button
                            variant="contained"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={() => generatePDF(item)}
                          >
                            {new Date(item.fecha).toLocaleDateString()}
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleDeleteClick(item)}
                          >
                            Eliminar
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {/* Dialog de confirmation pour supprimer une fiche */}
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"¿Confirmar eliminación?"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      ¿Está seguro de que desea eliminar esta ficha técnica? Esta acción no se puede deshacer.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" autoFocus>
                      Eliminar
                    </Button>
                  </DialogActions>
                </Dialog>
              </AccordionDetails>
            </Accordion>
          );
      })}
    </Box>
  );
};

export default TechnicaTab;

import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Paper, Grid, Checkbox,
  IconButton, Button, TablePagination, TextField, FormControl, InputLabel, Select, MenuItem, ListItemText, Slider, Chip,
  Switch
} from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Visibility, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { BigBoardContext } from '../BigBoardContext';

const BigBoard = () => {
  const [players, setPlayers] = useState([]);
  const { bigBoardPlayers, setBigBoardPlayers } = useContext(BigBoardContext);

  const [page, setPage] = useState(0);
  const rowsPerPage = 50;

  // -- FUSION des filtres nom/équipe en un seul --
  const [searchFilter, setSearchFilter] = useState('');

  // États pour les positions
  const [positionFilter, setPositionFilter] = useState([]);
  // États pour la taille et l'âge
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  // États pour les ligues
  const [uniqueLeagues, setUniqueLeagues] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);

  // -- NOUVEAU : liste des groupes et filtre associé --
  const [groups, setGroups] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);

  // Interrupteur Pro / Cantera
  const [playerType, setPlayerType] = useState('Pro'); // valeur par défaut

  // Récupération du rôle de l'utilisateur depuis localStorage
  const userData = JSON.parse(localStorage.getItem('user'));
  const userRole = userData?.role?.toLowerCase() || '';

  // Tags
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Au lieu d'un simple champ texte, on gère :
  // 1) l'option sélectionnée (parmi les MenuItem)
  // 2) la valeur réellement utilisée comme "bigBoardName"
  const [selectedRankingNameOption, setSelectedRankingNameOption] = useState('');
  const [bigBoardName, setBigBoardName] = useState('');

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const positionMappings = {
    Base: ['Base', 'Point Guard'],
    Escolta: ['Escolta', 'Shooting Guard', 'Guard'],
    Alero: ['Alero', 'Small Forward', 'Swingman'],
    'Ala-Pívot': ['Ala-Pívot', 'Power Forward', 'Power Forward / Center'],
    Pívot: ['Pívot', 'Center'],
  };

  // -- 1) GESTION DU RÔLE ET DU PLAYER TYPE (Pro / Cantera) --
  useEffect(() => {
    if (userRole === 'super admin') {
      // Laisse le switch tel quel
      return;
    } else if (userRole === 'admin pro') {
      setPlayerType('Pro');
    } else {
      // 'admin cantera', 'entrenador', 'pdp'...
      setPlayerType('Cantera');
    }
  }, [userRole]);

  // Charger les filtres depuis l'URL au chargement initial
  useEffect(() => {
    if (isInitialLoad) {
      const params = new URLSearchParams(location.search);
      setSearchFilter(params.get('searchFilter') || '');
      setPositionFilter(params.get('positionFilter') ? params.get('positionFilter').split(',') : []);
      setLeagueFilter(params.get('leagueFilter') ? params.get('leagueFilter').split(',') : []);
      setGroupFilter(params.get('groupFilter') ? params.get('groupFilter').split(',').map(Number) : []);
      setHeightFilter(params.get('heightFilter') ? params.get('heightFilter').split(',').map(Number) : [130, 230]);
      setAgeFilter(params.get('ageFilter') ? params.get('ageFilter').split(',').map(Number) : [12, 45]);
      setPage(params.get('page') ? parseInt(params.get('page'), 10) : 0);
      setIsInitialLoad(false);
    }
  }, [location.search, isInitialLoad]);

  // Mettre à jour l'URL lorsque les filtres changent
  useEffect(() => {
    if (!isInitialLoad) {
      const params = new URLSearchParams();
      if (searchFilter) params.set('searchFilter', searchFilter);
      if (positionFilter.length) params.set('positionFilter', positionFilter.join(','));
      if (leagueFilter.length) params.set('leagueFilter', leagueFilter.join(','));
      if (groupFilter.length) params.set('groupFilter', groupFilter.join(','));
      if (heightFilter.length) params.set('heightFilter', heightFilter.join(','));
      if (ageFilter.length) params.set('ageFilter', ageFilter.join(','));
      if (page) params.set('page', page);

      const newUrl = `${location.pathname}?${params.toString()}`;
      if (location.search !== `?${params.toString()}`) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [
    searchFilter,
    positionFilter,
    leagueFilter,
    groupFilter,
    heightFilter,
    ageFilter,
    page,
    isInitialLoad,
    location.pathname,
    location.search,
    navigate
  ]);

  // Récupérer les joueurs depuis le backend
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players?section=${section}`);
        const allPlayers = response.data.players;

        // Extraire les ligues uniques
        const leagues = Array.from(
          new Set(
            allPlayers
              .map((player) => player.league_1)
              .filter(
                (league) =>
                  league !== null &&
                  league !== undefined &&
                  typeof league === 'string' &&
                  league.trim() !== '' &&
                  league.trim() !== 'null'
              )
          )
        ).sort();
        setUniqueLeagues(leagues);

        // Exclure les joueurs déjà sur le Big Board
        const bigBoardPlayerIds = bigBoardPlayers.map((player) => player.id);
        const availablePlayers = allPlayers.filter((player) => !bigBoardPlayerIds.includes(player.id));

        setPlayers(availablePlayers);
      } catch (error) {
        console.error('Erreur lors de la récupération des joueurs:', error);
      }
    };
    fetchPlayers();
  }, [backendUrl, section, bigBoardPlayers]);

  // Récupérer la liste des groupes
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/groups?section=${section}`);
        // `response.data` doit être un tableau d'objets { id, libelle, code_couleur }
        setGroups(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des groupes:', error);
      }
    };
    fetchGroups();
  }, [backendUrl, section]);

  // Récupérer les tags depuis le backend
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/tags`, { params: { section } });
        setTags(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des tags:', error);
      }
    };
    fetchTags();
  }, [backendUrl, section]);

  // Fonction pour calculer l'âge du joueur
  const calculateAge = (anneeNaissance) => {
    const currentYear = new Date().getFullYear();
    return currentYear - anneeNaissance;
  };

  // -- 2) FILTRAGE : prendre en compte le type (Pro / Cantera) et le reste --
  const filteredPlayers = players.filter((player) => {
    // 1) Filtrage par type_joueur
    if (!player.type || player.type.toLowerCase() !== playerType.toLowerCase()) {
      return false;
    }

    // 2) Filtrage par recherche (nom ou équipe)
    const searchTerm = searchFilter.toLowerCase();
    const matchesSearch =
      player.nom.toLowerCase().includes(searchTerm) ||
      (player.team && player.team.toLowerCase().includes(searchTerm));

    if (!matchesSearch) return false;

    // 3) Filtrage par position
    const positionMatches =
      positionFilter.length === 0 ||
      positionFilter.some((pos) =>
        positionMappings[pos].some(
          (mappedPos) => player.poste?.toLowerCase() === mappedPos.toLowerCase()
        )
      );
    if (!positionMatches) return false;

    // 4) Filtrage par ligue
    if (leagueFilter.length > 0 && !leagueFilter.includes(player.league_1)) {
      return false;
    }

    // 5) Filtrage par groupes
    // Exemple : groupFilter = [1, 8] => on regarde si player.groupes contient l'un de ces IDs
    if (groupFilter.length > 0) {
      const playerGroupIds = (player.groupes || []).map((g) => g.id);
      const hasAtLeastOneSelectedGroup = groupFilter.some((groupId) => playerGroupIds.includes(groupId));
      if (!hasAtLeastOneSelectedGroup) return false;
    }

    // 6) Filtrage par taille
    if (player.taille < heightFilter[0] || player.taille > heightFilter[1]) {
      return false;
    }

    // 7) Filtrage par âge
    const playerAge = calculateAge(player.annee_naissance);
    if (playerAge < ageFilter[0] || playerAge > ageFilter[1]) {
      return false;
    }

    return true;
  });

  // Fonction pour ne récupérer que les joueurs de la page courante
  const getCurrentPagePlayers = () => {
    return filteredPlayers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  // -- 3) FONCTIONS ADD / REMOVE PLAYER BIG BOARD --
  const movePlayerToBigBoard = (player) => {
    if (!bigBoardPlayers.some((p) => p.id === player.id)) {
      setBigBoardPlayers([...bigBoardPlayers, player]);
      setPlayers(players.filter((p) => p.id !== player.id));
    }
  };

  const removePlayerFromBigBoard = (player) => {
    setBigBoardPlayers(bigBoardPlayers.filter((p) => p.id !== player.id));
    setPlayers([...players, player]);
  };

  // Réarrange les joueurs dans le Big Board
  const movePlayerInBigBoard = (dragIndex, hoverIndex) => {
    const updatedPlayers = [...bigBoardPlayers];
    const [movedPlayer] = updatedPlayers.splice(dragIndex, 1);
    updatedPlayers.splice(hoverIndex, 0, movedPlayer);
    setBigBoardPlayers(updatedPlayers);
  };

  // -- 4) GESTION DE L'AFFICHAGE OU NON DU LIEN FICHE JOUEUR --
  // Pour les rôles "entrenador" ou "pdp", on ne rend pas le nom cliquable
  const canViewPlayer = !(userRole === 'entrenador' || userRole === 'pdp');

  const handleViewPlayer = (player) => {
    if (canViewPlayer) {
      navigate(`/players/${player.id}`);
    }
  };

  // -- 5) ENREGISTREMENT DU BIG BOARD --
  const handleSaveBigBoard = async () => {
    if (!bigBoardName) {
      alert('Por favor, introduce un nombre para el Ranking.');
      return;
    }
    if (bigBoardPlayers.length === 0) {
      alert('Por favor, añade jugador@s al Ranking.');
      return;
    }
    if (selectedTags.length === 0) {
      alert('Por favor, selecciona al menos un tag para el Ranking.');
      return;
    }

    try {
      // Récupérer l'ID de l'utilisateur depuis localStorage
      const userData = JSON.parse(localStorage.getItem('user'));
      const userId = userData?.id;
      if (!userId) {
        alert('Error: ID de usuario no disponible.');
        return;
      }

      await axios.post(`${backendUrl}/api/bigboard`, {
        nom: bigBoardName,
        tags: selectedTags,
        players: bigBoardPlayers.map((player) => player.id),
        user_id: userId,
        section,
        type: playerType, // Pro ou Cantera
      });

      alert('Ranking guardado con éxito.');

      // Réinitialiser le formulaire
      setBigBoardName('');
      setSelectedTags([]);
      setSelectedRankingNameOption('');
      setBigBoardPlayers([]);
    } catch (error) {
      console.error('Error al guardar el Ranking.', error);
      alert('Error al guardar el Ranking.');
    }
  };

  // -- GESTION DU SELECT POUR "Nombre del Ranking" --
  const handleRankingNameChange = (event) => {
    const value = event.target.value;
    setSelectedRankingNameOption(value);
    if (value !== 'Otro') {
      setBigBoardName(value);
    } else {
      setBigBoardName('');
    }
  };

  // -- 6) COMPOSANTS DE TABLEAU ET DND --

  // Ligne de joueur dans le tableau de gauche (joueurs "disponibles")
  const PlayerRow = ({ player }) => {
    const [{ isDragging }, dragRef] = useDrag({
      type: 'player',
      item: player,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const isInBigBoard = bigBoardPlayers.some((p) => p.id === player.id);

    const handleCheckboxChange = (event) => {
      if (event.target.checked) {
        movePlayerToBigBoard(player);
      } else {
        removePlayerFromBigBoard(player);
      }
    };

    return (
      <TableRow
        ref={dragRef}
        key={player.id}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        sx={{
          cursor: 'move',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isInBigBoard}
            onChange={handleCheckboxChange}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
        <TableCell>
          <Avatar
            src={`${mediaUrl}/photos/${player.id}.png`}
            alt={player.nom}
            sx={{ width: 40, height: 40 }}
          />
        </TableCell>
        <TableCell>
          {canViewPlayer ? (
            <Box
              component="a"
              href={`/players/${player.id}`}
              onClick={(e) => {
                e.preventDefault();
                handleViewPlayer(player);
              }}
              sx={{
                color: 'black',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
            >
              {player.nom}
            </Box>
          ) : (
            <span>{player.nom}</span>
          )}
        </TableCell>
        <TableCell>{player.annee_naissance}</TableCell>
        <TableCell>{player.taille}</TableCell>
        <TableCell>{player.poste}</TableCell>
        <TableCell>{player.team || '-'}</TableCell>
        <TableCell>{player.league_1 || '-'}</TableCell>
      </TableRow>
    );
  };

  // Joueur déjà dans le Big Board (colonne de droite)
  const DraggableBigBoardPlayer = ({ player, index }) => {
    const ref = useRef(null);
    const [, drag] = useDrag({ type: 'bigboard-player', item: { index } });
    const [, drop] = useDrop({
      accept: 'bigboard-player',
      hover: (item) => {
        if (item.index !== index) {
          movePlayerInBigBoard(item.index, index);
          item.index = index;
        }
      },
    });
    drag(drop(ref));

    return (
      <Box ref={ref} display="flex" alignItems="center" mb={2} style={{ cursor: 'move' }}>
        <Typography variant="h6" style={{ marginRight: '10px' }}>
          {index + 1}
        </Typography>
        <Avatar src={`${mediaUrl}/photos/${player.id}.png`} alt={player.nom} />
        <Typography style={{ marginLeft: '10px' }}>
          {canViewPlayer ? (
            <Box
              component="a"
              href={`/players/${player.id}`}
              onClick={(e) => {
                e.preventDefault();
                handleViewPlayer(player);
              }}
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
            >
              {player.nom}
            </Box>
          ) : (
            <span>{player.nom}</span>
          )}
        </Typography>
        <IconButton onClick={() => removePlayerFromBigBoard(player)} style={{ marginLeft: 'auto' }}>
          <DeleteIcon color="error" />
        </IconButton>
      </Box>
    );
  };

  // Zone de dépôt Big Board
  const BigBoardDropZone = () => {
    const [{ isOver }, dropRef] = useDrop({
      accept: 'player',
      drop: (player) => movePlayerToBigBoard(player),
      collect: (monitor) => ({ isOver: monitor.isOver() }),
    });

    return (
      <Paper
        ref={dropRef}
        style={{
          minHeight: '400px',
          backgroundColor: isOver ? '#e0f7fa' : '#f5f5f5',
          padding: '20px'
        }}
      >
        <Typography variant="h6">Ranking</Typography>
        {bigBoardPlayers.length === 0 && <p>Arrastra jugador@s aquí.</p>}
        {bigBoardPlayers.map((player, index) => (
          <DraggableBigBoardPlayer key={player.id} player={player} index={index} />
        ))}
      </Paper>
    );
  };

  // -- 7) RENDU FINAL --
  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ p: 2 }}>
        {/* Interrupteur Pro / Cantera visible seulement pour le superadmin */}
        {userRole === 'super admin' && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography>Cantera</Typography>
            <Switch
              checked={playerType === 'Pro'}
              onChange={() => setPlayerType(playerType === 'Pro' ? 'Cantera' : 'Pro')}
            />
            <Typography>Pro</Typography>
          </Box>
        )}

        <Box sx={{ mt: 4, px: 4 }}>
          {/* FUSION : Un seul champ pour nom+équipe */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Filtrar por Nombre/Equipo"
                variant="outlined"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
                fullWidth
              />
            </Grid>

            {/* Nouveau MultiSelect pour Grupos */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="groups-filter-label">Filtrar por Grupos</InputLabel>
                <Select
                  labelId="groups-filter-label"
                  multiple
                  value={groupFilter}
                  onChange={(e) => setGroupFilter(e.target.value)}
                  renderValue={(selected) => {
                    // selected est un tableau d'IDs de groupes
                    const labels = groups
                      .filter((g) => selected.includes(g.id))
                      .map((g) => g.libelle);
                    return labels.join(', ');
                  }}
                >
                  {groups.map((g) => (
                    <MenuItem key={g.id} value={g.id}>
                      <Checkbox checked={groupFilter.indexOf(g.id) > -1} />
                      <ListItemText primary={g.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Filtres par posición et liga */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="position-filter-label">Filtrar por Posición</InputLabel>
                <Select
                  labelId="position-filter-label"
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.indexOf(position) > -1} />
                      <ListItemText
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="league-filter-label">Filtrar por Liga</InputLabel>
                <Select
                  labelId="league-filter-label"
                  multiple
                  value={leagueFilter}
                  onChange={(e) => setLeagueFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {uniqueLeagues.map((league) => (
                    <MenuItem key={league} value={league}>
                      <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                      <ListItemText primary={league} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Sliders pour la taille et l'âge */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              gap: 5,
              mb: 3
            }}
          >
            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>
                Filtrar por altura (130 a 230 cm)
              </Typography>
              <Slider
                value={heightFilter}
                onChange={(e, newValue) => setHeightFilter(newValue)}
                valueLabelDisplay="auto"
                min={130}
                max={230}
                sx={{ color: '#ff9800' }}
              />
            </Box>

            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>
                Filtrar por edad (12 a 45 años)
              </Typography>
              <Slider
                value={ageFilter}
                onChange={(e, newValue) => setAgeFilter(newValue)}
                valueLabelDisplay="auto"
                min={12}
                max={45}
                sx={{ color: '#ff9800' }}
              />
            </Box>
          </Box>

          {/* Configuration du Big Board */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="big-board-name-label">Nombre del Ranking</InputLabel>
                <Select
                  labelId="big-board-name-label"
                  value={selectedRankingNameOption}
                  onChange={handleRankingNameChange}
                  label="Nombre del Ranking"
                >
                  <MenuItem value="">--Seleccione--</MenuItem>
                  <MenuItem value="Bases">Bases</MenuItem>
                  <MenuItem value="Escoltas">Escoltas</MenuItem>
                  <MenuItem value="Aleros">Aleros</MenuItem>
                  <MenuItem value="Ala-Pívots">Ala-Pívots</MenuItem>
                  <MenuItem value="Pívots">Pívots</MenuItem>
                  <MenuItem value="Otro">Otro</MenuItem>
                </Select>
              </FormControl>

              {selectedRankingNameOption === 'Otro' && (
                <TextField
                  label="Escribe otro nombre"
                  variant="outlined"
                  value={bigBoardName}
                  onChange={(e) => setBigBoardName(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              )}
            </Grid>

            {/* Tags */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="tags-select-label">Seleccionar Tags</InputLabel>
                <Select
                  labelId="tags-select-label"
                  multiple
                  value={selectedTags}
                  onChange={(e) => setSelectedTags(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((tagId) => {
                        const tag = tags.find((t) => t.id === tagId);
                        return (
                          <Chip
                            key={tagId}
                            label={tag.libelle}
                            sx={{ backgroundColor: tag.code_couleur, color: 'white' }}
                          />
                        );
                      })}
                    </Box>
                  )}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <Checkbox checked={selectedTags.indexOf(tag.id) > -1} />
                      <Chip
                        label={tag.libelle}
                        sx={{ backgroundColor: tag.code_couleur, color: 'white', mr: 1 }}
                      />
                      <ListItemText primary={tag.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            sx={{ backgroundColor: '#ff9800', color: 'white', mb: 4 }}
            onClick={handleSaveBigBoard}
          >
            Guardar Ranking
          </Button>

          <Grid container spacing={2}>
            {/* Tableau de joueurs disponibles */}
            <Grid item xs={12} md={8}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox disabled />
                      </TableCell>
                      <TableCell>Avatar</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Año de nacimiento</TableCell>
                      <TableCell>Altura (cm)</TableCell>
                      <TableCell>Posición</TableCell>
                      <TableCell>Equipo</TableCell>
                      <TableCell>Liga</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getCurrentPagePlayers().map((player) => (
                      <PlayerRow key={player.id} player={player} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={filteredPlayers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              />
            </Grid>

            {/* Big Board (droite) */}
            <Grid item xs={12} md={4}>
              <BigBoardDropZone />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default BigBoard;

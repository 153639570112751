// PDPTab.jsx
import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper,
  CircularProgress, Alert,
  Card, CardContent,
  Grid,
  IconButton,
  useTheme, useMediaQuery
} from '@mui/material';
import axios from 'axios';
import DOMPurify from 'dompurify';

// ***** Imports pour la génération de PDF *****
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

// ***** Import de ReactPlayer *****
import ReactPlayer from 'react-player';

const PDPTab = ({ playerId, onDataLoaded }) => {
  const [pdpData, setPdpData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // URL du backend et du dossier "media"
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // ---------------------------------------------------------------------------
  // 1) convertHtmlToText : convertit le HTML en texte brut (pour le PDF)
  // ---------------------------------------------------------------------------
  const convertHtmlToText = (html) => {
    if (!html) return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    function traverse(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.nodeValue;
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tag = node.tagName.toLowerCase();
        let text = '';

        if (tag === 'br') {
          return '\n';
        }
        if (tag === 'p' || tag === 'div') {
          node.childNodes.forEach(child => {
            text += traverse(child);
          });
          text += ' ';
          return text;
        }
        if (tag === 'ol') {
          let index = 1;
          node.childNodes.forEach(child => {
            if (
              child.nodeType === Node.ELEMENT_NODE && 
              child.tagName.toLowerCase() === 'li'
            ) {
              text += index + '. ' + traverse(child) + '\n';
              index++;
            } else {
              text += traverse(child);
            }
          });
          return text;
        }
        if (tag === 'ul') {
          node.childNodes.forEach(child => {
            if (
              child.nodeType === Node.ELEMENT_NODE &&
              child.tagName.toLowerCase() === 'li'
            ) {
              text += '• ' + traverse(child) + '\n';
            } else {
              text += traverse(child);
            }
          });
          return text;
        }
        if (tag === 'li') {
          text += Array.from(node.childNodes).map(child => traverse(child)).join('');
          return text;
        }
        node.childNodes.forEach(child => {
          text += traverse(child);
        });
        return text;
      }
      return '';
    }

    let result = traverse(doc.body);
    // Nettoie les multiples sauts de lignes
    result = result.replace(/\n{2,}/g, '\n').trim();
    return result;
  };

  // ---------------------------------------------------------------------------
  // 2) addPageTemplate : ajoute un cadre + bas de page par page (PDF)
  // ---------------------------------------------------------------------------
  const addPageTemplate = (doc) => {
    const pageNumber = doc.getNumberOfPages();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    
    // Dessiner le cadre orange
    doc.setDrawColor('#EA7F00');
    doc.setLineWidth(2);
    doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

    // Bande orange en bas
    const bandHeight = 20;
    const bandY = pageHeight - 10 - bandHeight;
    doc.setFillColor(234, 127, 0);
    doc.rect(10, bandY, pageWidth - 20, bandHeight, 'F');

    // Logos et texte (facultatif)
    const logoSize = 16;
    try {
      const logoY = bandY + (bandHeight - logoSize) / 2;
      // Logo VBC
      doc.addImage(
        `${process.env.PUBLIC_URL}/assets/LogoValenciaBasket.png`,
        'PNG',
        15,
        logoY,
        logoSize,
        logoSize
      );
      // Texte centré
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(9);
      doc.text(
        'VALENCIA BASKET - DEPARTAMENTO DE SCOUTING',
        pageWidth / 2,
        bandY + bandHeight / 2 + 3,
        { align: 'center' }
      );
      // Logo Alquería
      doc.addImage(
        `${process.env.PUBLIC_URL}/assets/alqueria-del-basket.png`,
        'PNG',
        pageWidth - 15 - logoSize,
        logoY,
        logoSize,
        logoSize
      );
      // Numéro de page
      doc.setFontSize(8);
      doc.text(pageNumber.toString(), pageWidth / 2, bandY + bandHeight - 3, { align: 'center' });
    } catch (error) {
      console.error('Error adding images to PDF page:', error);
    }

    // Réinitialiser la couleur du texte en noir
    doc.setTextColor(0);
  };

  // ---------------------------------------------------------------------------
  // 3) generateFichePDF : génère le PDF pour une fiche "PDP"
  // ---------------------------------------------------------------------------
  const generateFichePDF = async (entry) => {
    try {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const marginLeft = 20;
      const marginRight = 20;
      let yPosition = 20;
      const lineHeight = 7;
      const contentWidth = pageWidth - marginLeft - marginRight;

      // 3.1) Dessiner le template (cadre, bas de page, etc.)
      addPageTemplate(doc);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');

      // 3.2) Tenter de charger la photo du joueur
      try {
        const avatarUrl = `${mediaUrl}/photos/${playerId}.png`; 
        const response = await fetch(
          `${backendUrl}/api/proxy-image?url=${encodeURIComponent(avatarUrl)}`
        );
        if (response.ok) {
          const blob = await response.blob();
          const imgData = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          // Dessiner la photo (30x30)
          doc.addImage(imgData, 'PNG', marginLeft, yPosition, 30, 30);
        }
      } catch (err) {
        console.error('Erreur lors du chargement de la photo:', err);
      }

      // 3.3) Nom du joueur
      doc.setFontSize(14);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(234, 127, 0);
      doc.text(entry.jugador || 'Jugador', marginLeft + 40, yPosition + 10);
      doc.setTextColor(0);

      yPosition += 40;

      // 3.4) Infos sur la fiche (date, évaluateur)
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(`Fecha: ${new Date(entry.fecha).toLocaleDateString()}`, marginLeft, yPosition);
      yPosition += lineHeight;
      doc.text(`Entrenador: ${entry.evaluador}`, marginLeft, yPosition);
      yPosition += lineHeight + 5;

      // Fonction helper pour éviter de déborder sur la page
      const addNewPageIfNeeded = (neededSpace) => {
        if (yPosition + neededSpace > pageHeight - 30) {
          doc.addPage();
          addPageTemplate(doc);
          doc.setFontSize(12);
          doc.setFont('helvetica', 'normal');
          yPosition = 20;
        }
      };

      // Helper pour imprimer un champ HTML (converti en texte)
      const printField = (label, html) => {
        if (!html) return;
        addNewPageIfNeeded(20);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(234, 127, 0);
        doc.text(label, marginLeft, yPosition);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0);
        yPosition += lineHeight;

        const txt = convertHtmlToText(html);
        const lines = doc.splitTextToSize(txt, contentWidth);
        for (const line of lines) {
          addNewPageIfNeeded(10);
          doc.text(line, marginLeft, yPosition);
          yPosition += lineHeight;
        }
        yPosition += 5;
      };

      // 3.5) Impression des champs
      printField('Objetivos', entry.objetivos);
      printField('Implementar', entry.implementar);
      printField('Rutinas', entry.routinas);
      printField('Mecanica de Tiro', entry.tiro);
      printField('Número de Sesiones', entry.numero_sesiones);
      printField('Observaciones', entry.observaciones);

      // 3.6) Si on a un tableau de médias, on les liste
      // (titre + URL)
      if (entry.medias && entry.medias.length > 0) {
        addNewPageIfNeeded(20);
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(234, 127, 0);
        doc.text('Medias', marginLeft, yPosition);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0);
        yPosition += lineHeight;

        entry.medias.forEach((media, index) => {
          addNewPageIfNeeded(10);
          const line = `${index + 1}. ${media.titre || ''} - ${media.url}`;
          doc.text(line, marginLeft, yPosition);
          yPosition += lineHeight;
        });
        yPosition += 5;
      }

      // 3.7) Exporter le PDF
      const dateObj = new Date(entry.fecha);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      const teamString = entry.team && entry.team.trim() ? ` - ${entry.team.trim()}` : '';
      const pdfTitle = `VBC PDP - ${entry.jugador || 'Jugador'}${teamString} - ${day}-${month}-${year}`;
      doc.save(`${pdfTitle}.pdf`);

    } catch (err) {
      console.error('Erreur PDF:', err);
    }
  };

  // ---------------------------------------------------------------------------
  // 4) Charger les données PDP
  // ---------------------------------------------------------------------------
  useEffect(() => {
    const fetchPDPData = async () => {
      try {
        // On récupère les fiches (chacune avec medias)
        const response = await axios.get(
          `${backendUrl}/api/tecnification/player/${playerId}/fiches`,
          { params: { section } }
        );
        setPdpData(response.data);

        // Informe le parent si onDataLoaded est défini
        onDataLoaded(response.data.length > 0);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching PDP data:', error);
        setError('Error al cargar los datos del programa PDP');
        onDataLoaded(false);
        setLoading(false);
      }
    };

    fetchPDPData();
  }, [playerId, backendUrl, section, onDataLoaded]);

  // ---------------------------------------------------------------------------
  // 5) Sécurisation HTML (DOMPurify) pour l'affichage dans React
  // ---------------------------------------------------------------------------
  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  // ---------------------------------------------------------------------------
  // 6) VUE MOBILE
  // ---------------------------------------------------------------------------
  const MobileView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {pdpData.map((entry) => (
        <Card key={entry.id} sx={{ width: '100%' }}>
          <CardContent>
            {/* En-tête : Date, Evaluador, bouton PDF */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                pb: 1,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{ color: '#FF8C00' }}>
                  {new Date(entry.fecha).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {entry.evaluador}
                </Typography>
              </Box>
              <IconButton 
                onClick={() => generateFichePDF(entry)} 
                size="small" 
                color="primary"
              >
                <PictureAsPdfIcon />
              </IconButton>
            </Box>

            {/* Objetivos */}
            {entry.objetivos && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Objetivos
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.objetivos)} />
              </Box>
            )}

            {/* Implementar */}
            {entry.implementar && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Implementar
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.implementar)} />
              </Box>
            )}

            {/* Observaciones */}
            {entry.observaciones && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Observaciones
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.observaciones)} />
              </Box>
            )}

            {/* Rutinas */}
            {entry.routinas && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Rutinas
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.routinas)} />
              </Box>
            )}

            {/* Mecanica de Tira */}
            {entry.tiro && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Mecanica de Tiro
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.tiro)} />
              </Box>
            )}

            {/* Número de Sesiones */}
            {entry.numero_sesiones && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Número de Sesiones
                </Typography>
                <Typography>{entry.numero_sesiones}</Typography>
              </Box>
            )}

            {/* Medias (s'il y en a) : titre + player (pas d'URL affichée) */}
            {entry.medias && entry.medias.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Media(s)
                </Typography>
                {entry.medias.map((media, idx) => (
                  <Box key={media.id || idx} sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                      {media.titre || ''}
                    </Typography>
                    {/* ReactPlayer (URL cachée) */}
                    <ReactPlayer
                      url={media.url}
                      controls
                      width="100%"
                    />
                  </Box>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  // ---------------------------------------------------------------------------
  // 7) VUE DESKTOP
  // ---------------------------------------------------------------------------
  const DesktopView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {pdpData.map((entry) => (
        <Paper key={entry.id} sx={{ overflow: 'hidden' }}>
          {/* En-tête avec date, évaluateur et bouton PDF */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#FF8C00',
              p: 1.5,
              color: 'white',
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 'medium' }}>
                {new Date(entry.fecha).toLocaleDateString()}
              </Typography>
              <Typography>{entry.evaluador}</Typography>
            </Box>
            <IconButton
              onClick={() => generateFichePDF(entry)}
              size="small"
              sx={{ color: '#fff' }}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Box>

          {/* Contenu : on utilise une grille pour organiser les champs */}
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {/* Objetivos */}
              {entry.objetivos && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Objetivos
                  </Typography>
                  <div dangerouslySetInnerHTML={createMarkup(entry.objetivos)} />
                </Grid>
              )}

              {/* Implementar */}
              {entry.implementar && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Implementar
                  </Typography>
                  <div dangerouslySetInnerHTML={createMarkup(entry.implementar)} />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* Observaciones */}
              {entry.observaciones && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Observaciones
                  </Typography>
                  <div dangerouslySetInnerHTML={createMarkup(entry.observaciones)} />
                </Grid>
              )}

              {/* Rutinas */}
              {entry.routinas && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Rutinas
                  </Typography>
                  <div dangerouslySetInnerHTML={createMarkup(entry.routinas)} />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* Mecanica de Tira */}
              {entry.tiro && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Mecanica de Tiro
                  </Typography>
                  <div dangerouslySetInnerHTML={createMarkup(entry.tiro)} />
                </Grid>
              )}

              {/* Número de Sesiones */}
              {entry.numero_sesiones && (
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Número de Sesiones
                  </Typography>
                  <Typography>{entry.numero_sesiones}</Typography>
                </Grid>
              )}
            </Grid>

            {/* Medias (s'il y en a) : titre + player */}
            {entry.medias && entry.medias.length > 0 && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Media(s)
                  </Typography>
                  {entry.medias.map((media, idx) => (
                    <Box key={media.id || idx} sx={{ mb: 2 }}>
                      <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                        {media.titre || 'Sin Título'}
                      </Typography>
                      {/* Player vidéo */}
                      <ReactPlayer
                        url={media.url}
                        controls
                        width="100%"
                      />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            )}
          </Box>
        </Paper>
      ))}
    </Box>
  );

  // ---------------------------------------------------------------------------
  // 8) ÉTATS : chargement / erreur / rendu principal
  // ---------------------------------------------------------------------------
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress sx={{ color: '#FF8C00' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!pdpData || pdpData.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay datos PDP disponibles para este jugador
      </Alert>
    );
  }

  // ---------------------------------------------------------------------------
  // 9) RENDU FINAL : titre + vue responsive (Mobile ou Desktop)
  // ---------------------------------------------------------------------------
  return (
    <Box sx={{ p: 2 }}>
      {/* Titre principal */}
      <Typography variant="h5" sx={{ mb: 3, color: '#FF8C00', fontWeight: 'bold' }}>
        Player Development Program VBC
      </Typography>

      {isMobile ? <MobileView /> : <DesktopView />}
    </Box>
  );
};

export default PDPTab;

// PlayerList.js
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Paper,
  IconButton, Box, Typography, TablePagination, Chip, Dialog, DialogTitle, DialogContent,
  TextField, useMediaQuery, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Slider,
  DialogActions, Button, Card, CardContent, CardHeader, CardActions,
  SwipeableDrawer, Divider, Grid, Fab, CircularProgress,
  Tooltip
} from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

import {
  Visibility,
  Delete as DeleteIcon,
  FilterList,
  Close,
  Star as StarIcon,
  Visibility as VisibilityIcon,
  Assignment as AssignmentIcon,
  Lightbulb as LightbulbIcon,
  Description as DescriptionIcon,  // <-- Pour le badge "Ficha Técnica"
} from '@mui/icons-material';

import { FormControlLabel } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import Player from '../models/playerModel';

// Fonction utilitaire pour déterminer la couleur du texte en fonction du fond
const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

// Calcul de l'âge (décimal) à partir de la date de naissance
const calculateAge = (dateNaissance) => {
  const birthDate = new Date(dateNaissance);
  const today = new Date();

  let years = today.getFullYear() - birthDate.getFullYear();
  const diffTime = Math.abs(today - birthDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const decimal = (diffDays % 365.25) / 365.25;

  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    years--;
  }

  return years + decimal;
};

const formatYearAndAge = (dateNaissance, anneeNaissance) => {
  const age = calculateAge(dateNaissance);
  return `${anneeNaissance} (${age.toFixed(1)})`;
};

// Petite fonction utilitaire qui renvoie le style d’un “badge” circulaire
const badgeStyle = (bgColor) => ({
  position: 'relative',
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: bgColor,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontSize: '14px',
  marginLeft: '-6px', // Pour un léger overlapping
  border: '2px solid #fff', // Bord blanc pour se détacher du fond
});

// Composant carte (vue mobile)
const PlayerCard = ({
  player,
  mediaUrl,
  timestamp,
  onView,
  onDelete,
  groupColors,
  userRole,
  isSelected,
  onSelectToggle,
}) => {
  const theme = useTheme();

  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        avatar={
          <Avatar
            src={`${mediaUrl}/photos/${player.id}.png?${timestamp}`}
            alt={player.nom}
            sx={{ width: 60, height: 60 }}
          />
        }
        title={
          (userRole === 'admin pro' || userRole === 'admin cantera' || userRole === 'super admin') ? (
            <Typography 
              variant="h6" 
              component="a"
              // Ici, on laisse juste le lien "href" au cas où, mais le clic par défaut
              // est déjà géré par le onView ci-dessous.
              href={`/players/${player.id}`}
              sx={{ 
                textDecoration: 'none', 
                color: 'inherit',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {player.nom}
            </Typography>
          ) : (
            <Typography variant="h6" component="div">
              {player.nom}
            </Typography>
          )
        }
        subheader={
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
            <Typography variant="body2">{player.poste}</Typography>
            {player?.nationalite && player.nationalite !== 'null' && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {player.nationalite}
              </Typography>
            )}
            <Typography variant="body2">{player.team || 'Sin equipo'}</Typography>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {formatYearAndAge(player.dateNaissance, player.anneeNaissance)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              {player.taille}cm
            </Typography>
          </Grid>
        </Grid>
        {player.league_1 && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {player.league_1}
          </Typography>
        )}
        {player.competicion_europa && player.competicion_europa !== 'null' && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {player.competicion_europa}
          </Typography>
        )}
        {player.groupes && player.groupes[0]?.id !== null && (
          <Box sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {player.groupes.map((group) => (
                <Chip
                  key={group.id}
                  label={group.libelle}
                  size="small"
                  sx={{
                    backgroundColor: group.code_couleur || 'grey',
                    color: getContrastColor(group.code_couleur),
                  }}
                />
              ))}
            </Box>
          </Box>
        )}

        {(userRole === 'admin pro' ||
          userRole === 'admin cantera' ||
          userRole === 'super admin') && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            {player.in_big_board === 1 && (
              <Tooltip title="Ranking">
                <Box sx={badgeStyle(theme.palette.primary.main)}>
                  <StarIcon fontSize="inherit" />
                </Box>
              </Tooltip>
            )}
            {player.in_follow_list === 1 && (
              <Tooltip title="Seguimiento">
                <Box sx={badgeStyle(theme.palette.secondary.main)}>
                  <VisibilityIcon fontSize="inherit" />
                </Box>
              </Tooltip>
            )}
            {player.in_informe === 1 && (
              <Tooltip title="Informe">
                <Box sx={badgeStyle(theme.palette.success.main)}>
                  <AssignmentIcon fontSize="inherit" />
                </Box>
              </Tooltip>
            )}
            {player.in_intel === 1 && (
              <Tooltip title="Intel">
                <Box sx={badgeStyle(theme.palette.warning.main)}>
                  <LightbulbIcon fontSize="inherit" />
                </Box>
              </Tooltip>
            )}
            {/* Nouveau badge Ficha Técnica  */}
            {player.in_fiche_technique === 1 && (
              <Tooltip title="Ficha Técnica">
                <Box sx={badgeStyle(theme.palette.info.main)}>
                  <DescriptionIcon fontSize="inherit" />
                </Box>
              </Tooltip>
            )}
            {player.in_video === 1 && (
              <Tooltip title="Video">
                <Box sx={badgeStyle("#9c27b0")}>
                  <VideoLibraryIcon fontSize="inherit" />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}

        {userRole === 'super admin' && (
          <Box sx={{ mt: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={() => onSelectToggle(player.id)}
                />
              }
              label="Seleccionar"
            />
          </Box>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          size="small"
          startIcon={<Visibility />}
          onClick={() => onView(player)}
          sx={{ color: theme.palette.primary.main }}
        >
          Ver
        </Button>
        <Button
          size="small"
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(player, e);
          }}
          sx={{ color: theme.palette.error.main }}
        >
          Eliminar
        </Button>
      </CardActions>
    </Card>
  );
};

// Tiroir (drawer) de filtres (vue mobile)
const FilterDrawer = ({
  open,
  onClose,
  positionFilter,
  setPositionFilter,
  groupFilter,
  setGroupFilter,
  leagueFilter,
  setLeagueFilter,
  competitionFilter,
  setCompetitionFilter,
  heightFilter,
  setHeightFilter,
  ageFilter,
  setAgeFilter,
  groups,
  groupColors,
  uniqueLeagues,
  positionMappings,
  playerTypeFilter,
  setPlayerTypeFilter,

  // Ajout des 2 nouveaux filtres:
  licenciaFilter,
  setLicenciaFilter,
  agenciaFilter,
  setAgenciaFilter,
  agencies,  // Liste d’agences récupérées côté parent
}) => {
  const [openLeagueSelect, setOpenLeagueSelect] = useState(false);
  const [openCompetitionSelect, setOpenCompetitionSelect] = useState(false);
  const [openLicenciaSelect, setOpenLicenciaSelect] = useState(false);
  const [openAgenciaSelect, setOpenAgenciaSelect] = useState(false);

  // Liste fixe des compétitions européennes
  const euroCompetitions = [
    'EuroLeague',
    'EuroCup',
    'FIBA BCL',
    'FIBA EuropeCup',
    'Lat-Est',
    'ENBL',
  ];

  // Liste fixe des licences
  const LICENCIAS = ['EXT', 'EUR', 'JFL', 'COT'];

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
          pb: 2,
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />

        {/* SLIDER tri-état pour le type de jugador (mobile) */}
        <Typography gutterBottom>Tipo de Jugador</Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={playerTypeFilter === 1}
                onChange={() => {
                  if (playerTypeFilter === 1) {
                    setPlayerTypeFilter(0); 
                  } else {
                    setPlayerTypeFilter(1); 
                  }
                }}
              />
            }
            label="Cantera"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={playerTypeFilter === 2}
                onChange={() => {
                  if (playerTypeFilter === 2) {
                    setPlayerTypeFilter(0);
                  } else {
                    setPlayerTypeFilter(2);
                  }
                }}
              />
            }
            label="Pro"
          />
        </Box>

        {/* Posición */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Posición</InputLabel>
          <Select
            multiple
            value={positionFilter}
            onChange={(e) => setPositionFilter(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {Object.entries(positionMappings).map(([position, equivalents]) => (
              <MenuItem key={position} value={position}>
                <Checkbox checked={positionFilter.indexOf(position) > -1} />
                <ListItemText
                  primary={position}
                  secondary={equivalents.slice(1).join(', ')}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Grupos */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Grupos</InputLabel>
          <Select
            multiple
            value={groupFilter}
            onChange={(e) => setGroupFilter(e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((group) => (
                  <Chip
                    key={group}
                    label={group}
                    sx={{
                      backgroundColor: groupColors[group] || 'grey',
                      color: getContrastColor(groupColors[group]),
                    }}
                  />
                ))}
              </Box>
            )}
          >
            {groups.map((group) => (
              <MenuItem key={group.libelle} value={group.libelle}>
                <Checkbox checked={groupFilter.indexOf(group.libelle) > -1} />
                <ListItemText primary={group.libelle} />
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    backgroundColor: group.code_couleur || 'grey',
                    borderRadius: '50%',
                    ml: 1,
                  }}
                />
              </MenuItem>
            ))}
            {/* --- Ajout de l'option Sin Grupos --- */}
            <MenuItem value="Sin Grupos">
              <Checkbox checked={groupFilter.indexOf("Sin Grupos") > -1} />
              <ListItemText primary="Sin Grupos" />
            </MenuItem>
          </Select>
        </FormControl>

        {/* Liga */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Liga</InputLabel>
          <Select
            multiple
            open={openLeagueSelect}
            onOpen={() => setOpenLeagueSelect(true)}
            onClose={() => setOpenLeagueSelect(false)}
            value={leagueFilter}
            onChange={(e) => setLeagueFilter(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {uniqueLeagues.map((league) => (
              <MenuItem key={league} value={league}>
                <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                <ListItemText primary={league} />
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                setOpenLeagueSelect(false);
              }}
              sx={{
                justifyContent: 'center',
                color: 'primary.main',
                py: 1,
              }}
            >
              Cerrar
            </MenuItem>
          </Select>
        </FormControl>

        {/* Competición Europea */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Competición Europea</InputLabel>
          <Select
            multiple
            open={openCompetitionSelect}
            onOpen={() => setOpenCompetitionSelect(true)}
            onClose={() => setOpenCompetitionSelect(false)}
            value={competitionFilter}
            onChange={(e) => setCompetitionFilter(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {euroCompetitions.map((comp) => (
              <MenuItem key={comp} value={comp}>
                <Checkbox checked={competitionFilter.indexOf(comp) > -1} />
                <ListItemText primary={comp} />
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                setOpenCompetitionSelect(false);
              }}
              sx={{
                justifyContent: 'center',
                color: 'primary.main',
                py: 1,
              }}
            >
              Cerrar
            </MenuItem>
          </Select>
        </FormControl>

        {/* Nouveau Select Licencia */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Licencia</InputLabel>
          <Select
            multiple
            open={openLicenciaSelect}
            onOpen={() => setOpenLicenciaSelect(true)}
            onClose={() => setOpenLicenciaSelect(false)}
            value={licenciaFilter}
            onChange={(e) => setLicenciaFilter(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {['EXT', 'EUR', 'JFL', 'COT'].map((lic) => (
              <MenuItem key={lic} value={lic}>
                <Checkbox checked={licenciaFilter.indexOf(lic) > -1} />
                <ListItemText primary={lic} />
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                setOpenLicenciaSelect(false);
              }}
              sx={{
                justifyContent: 'center',
                color: 'primary.main',
                py: 1,
              }}
            >
              Cerrar
            </MenuItem>
          </Select>
        </FormControl>

        {/* Nouveau Select Agencia */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Agencia</InputLabel>
          <Select
            multiple
            open={openAgenciaSelect}
            onOpen={() => setOpenAgenciaSelect(true)}
            onClose={() => setOpenAgenciaSelect(false)}
            value={agenciaFilter}
            onChange={(e) => setAgenciaFilter(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {agencies.map((agency) => (
              <MenuItem key={agency} value={agency}>
                <Checkbox checked={agenciaFilter.indexOf(agency) > -1} />
                <ListItemText primary={agency} />
              </MenuItem>
            ))}
            <Divider />
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                setOpenAgenciaSelect(false);
              }}
              sx={{
                justifyContent: 'center',
                color: 'primary.main',
                py: 1,
              }}
            >
              Cerrar
            </MenuItem>
          </Select>
        </FormControl>

        {/* Altura */}
        <Typography gutterBottom>Altura (100-230 cm)</Typography>
        <Slider
          value={heightFilter}
          onChange={(e, newValue) => setHeightFilter(newValue)}
          valueLabelDisplay="auto"
          min={100}
          max={230}
          sx={{ mb: 3 }}
        />

        {/* Edad */}
        <Typography gutterBottom>Edad (08-42 años)</Typography>
        <Slider
          value={ageFilter}
          onChange={(e, newValue) => setAgeFilter(newValue)}
          valueLabelDisplay="auto"
          min={8}
          max={42}
        />
      </Box>
    </SwipeableDrawer>
  );
};

// Mapping des positions pour la cohérence
const positionMappings = {
  Base: ['Base', 'Point Guard'],
  Escolta: ['Escolta', 'Shooting Guard', 'Guard'],
  Alero: ['Alero', 'Small Forward', 'Swingman'],
  'Ala-Pívot': ['Ala-Pívot', 'Power Forward', 'Power Forward / Center'],
  Pívot: ['Pívot', 'Center'],
};

const PlayerList = () => {
  const [players, setPlayers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupColors, setGroupColors] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Pour filtrer par poste, groupes, Liga, etc.
  const [positionFilter, setPositionFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [competitionFilter, setCompetitionFilter] = useState([]); 
  const [heightFilter, setHeightFilter] = useState([100, 230]);
  const [ageFilter, setAgeFilter] = useState([8, 42]);

  // Filtre par nom / année
  const [nameFilter, setNameFilter] = useState('');
  const [yearFilter, setYearFilter] = useState('');

  // Slider tri-état : 0 = Tous, 1 = Cantera, 2 = Pro
  const [playerTypeFilter, setPlayerTypeFilter] = useState(0);

  // Ajout des 2 nouveaux filtres
  const [licenciaFilter, setLicenciaFilter] = useState([]);
  const [agenciaFilter, setAgenciaFilter] = useState([]);
  const [agencies, setAgencies] = useState([]); // liste d’agences

  // Gestion du "premier chargement"
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  // Ligues uniques pour peupler la liste "Liga"
  const [uniqueLeagues, setUniqueLeagues] = useState([]);

  // Pour la suppression (simple)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState(null);

  // Pour la suppression multiple (super admin)
  const [selectedPlayerIDs, setSelectedPlayerIDs] = useState([]);
  const [multiDeleteDialogOpen, setMultiDeleteDialogOpen] = useState(false);

  // Tiroir de filtres (mobile)
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  // Loader
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();

  // Variables d'environnement
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;

  // Pour invalider le cache des photos
  const timestamp = Date.now();

  // Récupération du userRole
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUserRole(user?.role?.toLowerCase());
  }, []);

  // Récupère les paramètres de l'URL au chargement initial
  useEffect(() => {
    if (isInitialLoad) {
      const params = new URLSearchParams(location.search);

      setNameFilter(params.get('nameFilter') || '');
      setYearFilter(params.get('yearFilter') || '');
      setPositionFilter(
        params.get('positionFilter') ? params.get('positionFilter').split(',') : []
      );
      setGroupFilter(
        params.get('groupFilter') ? params.get('groupFilter').split(',') : []
      );
      setLeagueFilter(
        params.get('leagueFilter') ? params.get('leagueFilter').split(',') : []
      );
      setCompetitionFilter(
        params.get('competitionFilter') ? params.get('competitionFilter').split(',') : []
      );
      setHeightFilter(
        params.get('heightFilter')
          ? params.get('heightFilter').split(',').map(Number)
          : [100, 230]
      );
      setAgeFilter(
        params.get('ageFilter')
          ? params.get('ageFilter').split(',').map(Number)
          : [8, 42]
      );
      setPage(params.get('page') ? parseInt(params.get('page'), 10) : 0);
      setPlayerTypeFilter(
        params.get('playerTypeFilter')
          ? parseInt(params.get('playerTypeFilter'), 10)
          : 0
      );

      // Récupération des filtres Licencia et Agencia
      setLicenciaFilter(
        params.get('licenciaFilter') ? params.get('licenciaFilter').split(',') : []
      );
      setAgenciaFilter(
        params.get('agenciaFilter') ? params.get('agenciaFilter').split(',') : []
      );

      setIsInitialLoad(false);
    }
  }, [location.search, isInitialLoad]);

  // Met à jour l'URL quand les filtres changent (après le chargement initial)
  useEffect(() => {
    if (!isInitialLoad) {
      const params = new URLSearchParams();

      if (nameFilter) params.set('nameFilter', nameFilter);
      if (yearFilter) params.set('yearFilter', yearFilter);
      if (positionFilter.length) params.set('positionFilter', positionFilter.join(','));
      if (groupFilter.length) params.set('groupFilter', groupFilter.join(','));
      if (leagueFilter.length) params.set('leagueFilter', leagueFilter.join(','));
      if (competitionFilter.length) {
        params.set('competitionFilter', competitionFilter.join(','));
      }
      if (heightFilter.length) params.set('heightFilter', heightFilter.join(','));
      if (ageFilter.length) params.set('ageFilter', ageFilter.join(','));
      if (page) params.set('page', page);
      if (playerTypeFilter !== 0) {
        params.set('playerTypeFilter', playerTypeFilter);
      }

      // Ajout des filtres Licencia et Agencia dans l'URL
      if (licenciaFilter.length) {
        params.set('licenciaFilter', licenciaFilter.join(','));
      }
      if (agenciaFilter.length) {
        params.set('agenciaFilter', agenciaFilter.join(','));
      }

      const newUrl = `${location.pathname}?${params.toString()}`;
      if (location.search !== `?${params.toString()}`) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [
    nameFilter,
    yearFilter,
    positionFilter,
    groupFilter,
    leagueFilter,
    competitionFilter,
    heightFilter,
    ageFilter,
    page,
    playerTypeFilter,
    licenciaFilter,
    agenciaFilter,
    isInitialLoad,
    location.pathname,
    location.search,
    navigate,
  ]);

  // Reset page to 0 on filter changes
  useEffect(() => {
    if (!isInitialLoad) {
      setPage(0);
    }
  }, [
    isInitialLoad,
    nameFilter,
    yearFilter,
    positionFilter,
    groupFilter,
    leagueFilter,
    competitionFilter,
    heightFilter,
    ageFilter,
    playerTypeFilter,
    licenciaFilter,
    agenciaFilter,
  ]);

  // Récupération des données depuis l'API
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // On démarre le loader

        const user = JSON.parse(localStorage.getItem('user'));

        // On utilise un param "type" pour admin pro / cantera si nécessaire
        const typeParam =
          user?.role?.toLowerCase() === 'admin pro'
            ? 'pro'
            : user?.role?.toLowerCase() === 'admin cantera'
            ? 'cantera'
            : null;

        const playersEndpoint =
          user?.role?.toLowerCase() === 'entrenador'
            ? `${backendUrl}/api/players/coach/${user.id}?section=${section}`
            : `${backendUrl}/api/players?section=${section}${
                typeParam ? `&type=${typeParam}` : ''
              }`;

        const [playersResponse, groupsResponse, agenciesResponse] = await Promise.all([
          fetch(playersEndpoint),
          axios.get(`${backendUrl}/api/groups`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/agencies`, { params: { section } }),
        ]);

        const playersData = await playersResponse.json();
        // Convertit les données en objets Player (cf. playerModel)
        setPlayers(playersData.players.map((item) => Player.fromJSON(item)));

        // Extraction des ligues uniques pour le filtre "Liga"
        const leagues = Array.from(
          new Set(
            playersData.players
              .map((p) => p.league_1)
              .filter((league) => league?.trim() && league.trim() !== 'null')
          )
        ).sort();
        setUniqueLeagues(leagues);

        // Récupération des groupes
        setGroups(groupsResponse.data);
        const colors = {};
        groupsResponse.data.forEach((group) => {
          colors[group.libelle] = group.code_couleur;
        });
        setGroupColors(colors);

        // Récupération des agences
        if (agenciesResponse?.data.agencies?.length) {
          setAgencies(agenciesResponse.data.agencies);
        } else {
          setAgencies([]);
        }
      } catch (error) {
        console.error('Erreur:', error);
      } finally {
        setLoading(false); // On arrête le loader
      }
    };

    if (isInitialLoad) {
      fetchData();
    }
  }, [section, backendUrl, isInitialLoad]);

  /**
   * Ouvre la fiche du joueur.
   * - En mode mobile, on utilise `navigate` pour charger la page dans le même onglet.
   * - En mode desktop, on ouvre la page du joueur dans un nouvel onglet.
   */
  const handleViewPlayer = (player) => {
    if (isMobile) {
      navigate(`/players/${player.id}`);
    } else {
      // Ouvre dans un nouvel onglet
      window.open(`/players/${player.id}`, '_blank');
    }
  };

  // Suppression simple
  const handleDeleteClick = (player, event) => {
    event.stopPropagation();
    setPlayerToDelete(player);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${backendUrl}/api/players/${playerToDelete.id}?section=${section}`);
      setPlayers((prev) => prev.filter((p) => p.id !== playerToDelete.id));
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error al eliminar el jugador:', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setPlayerToDelete(null);
  };

  // Gestion de la sélection multiple (pour super admin)
  const handleSelectPlayer = (playerId) => {
    setSelectedPlayerIDs((prevSelected) => {
      if (prevSelected.includes(playerId)) {
        // Si déjà sélectionné, on enlève
        return prevSelected.filter((id) => id !== playerId);
      } else {
        // Sinon on ajoute
        return [...prevSelected, playerId];
      }
    });
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      // Tout sélectionner (uniquement les joueurs filtrés)
      const allIDs = filteredPlayers.map((p) => p.id);
      setSelectedPlayerIDs(allIDs);
    } else {
      // Tout déselectionner
      setSelectedPlayerIDs([]);
    }
  };

  const handleOpenMultiDeleteDialog = () => {
    setMultiDeleteDialogOpen(true);
  };

  const handleCloseMultiDeleteDialog = () => {
    setMultiDeleteDialogOpen(false);
  };

  const handleConfirmMultipleDelete = async () => {
    try {
      for (const id of selectedPlayerIDs) {
        await axios.delete(`${backendUrl}/api/players/${id}?section=${section}`);
      }
      setPlayers((prev) => prev.filter((p) => !selectedPlayerIDs.includes(p.id)));
      setSelectedPlayerIDs([]);
    } catch (error) {
      console.error('Error al eliminar varios jugadores:', error);
    } finally {
      handleCloseMultiDeleteDialog();
    }
  };

  // Filtrage des joueurs
  const filteredPlayers = players.filter((player) => {
    const playerAge = calculateAge(player.dateNaissance);
    const playerGroups = player.groupes.map((group) => group.libelle);
    const searchTerm = nameFilter.toLowerCase();
    const yearSearchTerm = yearFilter.toLowerCase();
    const hasSinGrupos = groupFilter.includes("Sin Grupos");
    const playerHasNoGroups =
      !player.groupes || player.groupes.length === 0 || player.groupes[0].id === null;

    const groupCondition =
      groupFilter.length === 0
        ? true
        : (
            // si "Sin Grupos" est dedans et que le joueur n’a pas de groupes, on valide
            (hasSinGrupos && playerHasNoGroups)
            // ou si l’un des groupes du joueur fait partie du groupFilter (hors "Sin Grupos")
            || playerGroups.some((g) => groupFilter.includes(g))
          );

    // Vérification de la position
    const positionMatches =
      positionFilter.length === 0 ||
      positionFilter.some((pos) =>
        positionMappings[pos]?.some(
          (mappedPos) => player.poste.toLowerCase() === mappedPos.toLowerCase()
        )
      );

    // Vérification du type (tri-état)
    const matchesPlayerType = (() => {
      if (playerTypeFilter === 0) return true; // Tous
      if (playerTypeFilter === 1) return player.type?.toLowerCase() === 'cantera'; 
      if (playerTypeFilter === 2) return player.type?.toLowerCase() === 'pro'; 
      return true;
    })();

    // Vérification de l'année
    const yearMatches =
      !yearFilter || player.anneeNaissance.toString().includes(yearSearchTerm);

    // Vérification de la compétition européenne
    const competitionMatches =
      competitionFilter.length === 0 || competitionFilter.includes(player.competicion_europa);

    // Vérification de la licencia (pasaporte)
    const licenciaMatches =
      licenciaFilter.length === 0 || licenciaFilter.includes(player.pasaporte);

    // Vérification de l’agencia
    const agenciaMatches =
      agenciaFilter.length === 0 || agenciaFilter.includes(player.agencia);

    return (
      // Nom ou équipe
      (player.nom.toLowerCase().includes(searchTerm) ||
        (player.team && player.team.toLowerCase().includes(searchTerm))) &&
      positionMatches &&
      matchesPlayerType &&
      yearMatches &&
      // Liga
      (leagueFilter.length === 0 || leagueFilter.includes(player.league_1)) &&
      // Compétition européenne
      competitionMatches &&
      // Taille
      player.taille >= heightFilter[0] &&
      player.taille <= heightFilter[1] &&
      // Âge
      playerAge >= ageFilter[0] &&
      playerAge <= ageFilter[1] &&
      // Licencia
      licenciaMatches &&
      // Agencia
      agenciaMatches &&
      groupCondition
    );
  });

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  // Nombre de joueurs filtrés
  const totalFiltered = filteredPlayers.length;

  // Gestion de la checkbox "sélectionner tous" en desktop
  const allChecked =
    filteredPlayers.length > 0 &&
    filteredPlayers.every((p) => selectedPlayerIDs.includes(p.id));
  // Indéterminée si au moins un coché mais pas tous
  const isIndeterminate =
    selectedPlayerIDs.length > 0 && selectedPlayerIDs.length < filteredPlayers.length;

  return (
    <Box sx={{ mt: 4, px: 2, pb: isMobile ? 7 : 4 }}>
      {/* -- Nouveau bloc: Tipo de Jugador (Desktop) AU-DESSUS de Nombre/Equipo & Año -- */}
      {!isMobile && (
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom>Tipo de Jugador</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={playerTypeFilter === 1}
                  onChange={() => {
                    setPlayerTypeFilter(playerTypeFilter === 1 ? 0 : 1);
                  }}
                />
              }
              label="Cantera"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={playerTypeFilter === 2}
                  onChange={() => {
                    setPlayerTypeFilter(playerTypeFilter === 2 ? 0 : 2);
                  }}
                />
              }
              label="Pro"
            />
          </Box>
        </Box>
      )}

      {/* Barre de recherche (Nom / Équipe) + filtre "Année" */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 3,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="Filtrar por Nombre o Equipo"
            variant="outlined"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
            sx={{ flex: 1 }}
          />
          <TextField
            label="Filtrar por Año de Nacimiento"
            variant="outlined"
            value={yearFilter}
            onChange={(e) => setYearFilter(e.target.value)}
            sx={{ flex: 1 }}
          />
        </Box>

        {isMobile ? (
          // Affiche le bouton pour ouvrir le tiroir (mobile)
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.drawer + 1,
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterList />
          </Fab>
        ) : (
          // En desktop, on affiche directement les filtres
          <>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Filtrar por Posición</InputLabel>
                <Select
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.indexOf(position) > -1} />
                      <ListItemText
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Filtrar por Grupos</InputLabel>
                <Select
                  multiple
                  value={groupFilter}
                  onChange={(e) => setGroupFilter(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ 
                      display: 'flex', 
                      flexWrap: 'wrap', 
                      gap: 0.5,
                      maxWidth: '100%',
                      overflow: 'hidden'
                    }}>
                      {selected.length <= 2 ? (
                        selected.map((group) => (
                          <Chip
                            key={group}
                            label={group}
                            sx={{
                              backgroundColor: groupColors[group] || 'grey',
                              color: getContrastColor(groupColors[group]),
                              maxWidth: '120px',
                              '& .MuiChip-label': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }
                            }}
                          />
                        ))
                      ) : (
                        <Typography>{`${selected.length} grupos seleccionados`}</Typography>
                      )}
                    </Box>
                  )}
                >
                  {groups.map((group) => (
                    <MenuItem key={group.libelle} value={group.libelle}>
                      <Checkbox checked={groupFilter.indexOf(group.libelle) > -1} />
                      <ListItemText primary={group.libelle} />
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: group.code_couleur || 'grey',
                          borderRadius: '50%',
                          ml: 1,
                        }}
                      />
                    </MenuItem>
                  ))}
                  {/* --- Ajout de l'option Sin Grupos --- */}
                  <MenuItem value="Sin Grupos">
                    <Checkbox checked={groupFilter.indexOf("Sin Grupos") > -1} />
                    <ListItemText primary="Sin Grupos" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Liga</InputLabel>
                <Select
                  multiple
                  value={leagueFilter}
                  onChange={(e) => setLeagueFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {uniqueLeagues.map((league) => (
                    <MenuItem key={league} value={league}>
                      <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                      <ListItemText primary={league} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Competición Europea</InputLabel>
                <Select
                  multiple
                  value={competitionFilter}
                  onChange={(e) => setCompetitionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {[
                    'EuroLeague',
                    'EuroCup',
                    'FIBA BCL',
                    'FIBA EuropeCup',
                    'Lat-Est',
                    'ENBL',
                  ].map((comp) => (
                    <MenuItem key={comp} value={comp}>
                      <Checkbox checked={competitionFilter.indexOf(comp) > -1} />
                      <ListItemText primary={comp} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Ajout du filtre Licencia (pasaporte) */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Licencia</InputLabel>
                <Select
                  multiple
                  value={licenciaFilter}
                  onChange={(e) => setLicenciaFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {['EXT', 'EUR', 'JFL', 'COT'].map((lic) => (
                    <MenuItem key={lic} value={lic}>
                      <Checkbox checked={licenciaFilter.indexOf(lic) > -1} />
                      <ListItemText primary={lic} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Ajout du filtre Agencia */}
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Agencia</InputLabel>
                <Select
                  multiple
                  value={agenciaFilter}
                  onChange={(e) => setAgenciaFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {agencies.map((agency) => (
                    <MenuItem key={agency} value={agency}>
                      <Checkbox checked={agenciaFilter.indexOf(agency) > -1} />
                      <ListItemText primary={agency} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Sliders pour la taille et l'âge */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ flex: 1 }}>
                <Typography gutterBottom>Altura (100-230 cm)</Typography>
                <Slider
                  value={heightFilter}
                  onChange={(e, newValue) => setHeightFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={100}
                  max={230}
                />
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <Typography gutterBottom>Edad (08-42 años)</Typography>
                <Slider
                  value={ageFilter}
                  onChange={(e, newValue) => setAgeFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={8}
                  max={42}
                />
              </FormControl>
            </Box>
          </>
        )}
      </Box>

      <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
        Total: {totalFiltered} Jugador@s
      </Typography>

      {/* Bouton pour supprimer plusieurs joueurs (réservé au super admin) */}
      {userRole === 'super admin' && selectedPlayerIDs.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleOpenMultiDeleteDialog}
          >
            Eliminar seleccionados ({selectedPlayerIDs.length})
          </Button>
        </Box>
      )}

      {/* Vue mobile : affichage en “cards” */}
      {isMobile ? (
        <Box>
          {filteredPlayers
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((player) => (
              <PlayerCard
                key={player.id}
                player={player}
                mediaUrl={mediaUrl}
                timestamp={timestamp}
                onView={handleViewPlayer}
                onDelete={handleDeleteClick}
                groupColors={groupColors}
                userRole={userRole}
                isSelected={selectedPlayerIDs.includes(player.id)}
                onSelectToggle={handleSelectPlayer}
              />
            ))}
        </Box>
      ) : (
        // Vue desktop : affichage en tableau
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/* Colonne checkbox pour super admin */}
                {userRole === 'super admin' && (
                  <TableCell sx={{ backgroundColor: '#ffe0b2' }}>
                    <Checkbox
                      checked={allChecked}
                      indeterminate={isIndeterminate}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  </TableCell>
                )}
                <TableCell sx={{ backgroundColor: '#ffe0b2' }} />
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Nombre
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Posición
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Año
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Altura
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Nacionalidad
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Equipo
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Liga
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Competición Europea
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Grupos
                </TableCell>
                <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                  Acciones
                </TableCell>
                {(userRole === 'admin pro' ||
                  userRole === 'admin cantera' ||
                  userRole === 'super admin') && (
                  <TableCell sx={{ backgroundColor: '#ffe0b2', fontWeight: 'bold' }}>
                    {/* Colonne pour les badges */}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPlayers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((player) => {
                  const isSelected = selectedPlayerIDs.includes(player.id);
                  return (
                    <TableRow key={player.id} hover>
                      {/* Checkbox pour super admin */}
                      {userRole === 'super admin' && (
                        <TableCell>
                          <Checkbox
                            checked={isSelected}
                            onChange={() => handleSelectPlayer(player.id)}
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <Avatar
                          src={`${mediaUrl}/photos/${player.id}.png?${timestamp}`}
                          alt={player.nom}
                          sx={{ width: 40, height: 40 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          component="span"
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              textDecoration: 'underline',
                            },
                          }}
                          // => Cliquez : ouvre dans un nouvel onglet si desktop, sinon même onglet
                          onClick={() => handleViewPlayer(player)}
                        >
                          {player.nom}
                        </Typography>
                      </TableCell>
                      <TableCell>{player.poste}</TableCell>
                      <TableCell>
                        {formatYearAndAge(player.dateNaissance, player.anneeNaissance)}
                      </TableCell>
                      <TableCell>{player.taille}cm</TableCell>
                      <TableCell>
                        {player?.nationalite && player.nationalite !== 'null'
                          ? player.nationalite
                          : ''}
                      </TableCell>
                      <TableCell>{player.team}</TableCell>
                      <TableCell>{player.league_1}</TableCell>
                      <TableCell>
                        {player.competicion_europa && player.competicion_europa !== 'null'
                          ? player.competicion_europa
                          : ''}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {player.groupes &&
                            player.groupes[0]?.id !== null &&
                            player.groupes.map((group) => (
                              <Chip
                                key={group.id}
                                label={group.libelle}
                                size="small"
                                sx={{
                                  backgroundColor: group.code_couleur || 'grey',
                                  color: getContrastColor(group.code_couleur),
                                }}
                              />
                            ))}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleViewPlayer(player)}
                          sx={{ color: '#ff9800' }}
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          onClick={(e) => handleDeleteClick(player, e)}
                          sx={{ color: '#f44336' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      {(userRole === 'admin pro' ||
                        userRole === 'admin cantera' ||
                        userRole === 'super admin') && (
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {player.in_big_board === 1 && (
                              <Tooltip title="Ranking">
                                <Box sx={badgeStyle(theme.palette.primary.main)}>
                                  <StarIcon fontSize="inherit" />
                                </Box>
                              </Tooltip>
                            )}
                            {player.in_follow_list === 1 && (
                              <Tooltip title="Seguimiento">
                                <Box sx={badgeStyle(theme.palette.secondary.main)}>
                                  <VisibilityIcon fontSize="inherit" />
                                </Box>
                              </Tooltip>
                            )}
                            {player.in_informe === 1 && (
                              <Tooltip title="Informe">
                                <Box sx={badgeStyle(theme.palette.success.main)}>
                                  <AssignmentIcon fontSize="inherit" />
                                </Box>
                              </Tooltip>
                            )}
                            {player.in_intel === 1 && (
                              <Tooltip title="Intel">
                                <Box sx={badgeStyle(theme.palette.warning.main)}>
                                  <LightbulbIcon fontSize="inherit" />
                                </Box>
                              </Tooltip>
                            )}
                            {player.in_fiche_technique === 1 && (
                              <Tooltip title="Ficha Técnica">
                                <Box sx={badgeStyle(theme.palette.info.main)}>
                                  <DescriptionIcon fontSize="inherit" />
                                </Box>
                              </Tooltip>
                            )}
                            {player.in_video === 1 && (
                              <Tooltip title="Video">
                                <Box sx={badgeStyle("#9c27b0")}>
                                  <VideoLibraryIcon fontSize="inherit" />
                                </Box>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination */}
      <TablePagination
        component="div"
        count={filteredPlayers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        labelRowsPerPage=""
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />

      {/* Tiroir de filtres (mobile) */}
      <FilterDrawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        positionFilter={positionFilter}
        setPositionFilter={setPositionFilter}
        groupFilter={groupFilter}
        setGroupFilter={setGroupFilter}
        leagueFilter={leagueFilter}
        setLeagueFilter={setLeagueFilter}
        competitionFilter={competitionFilter}
        setCompetitionFilter={setCompetitionFilter}
        heightFilter={heightFilter}
        setHeightFilter={setHeightFilter}
        ageFilter={ageFilter}
        setAgeFilter={setAgeFilter}
        groups={groups}
        groupColors={groupColors}
        uniqueLeagues={uniqueLeagues}
        positionMappings={positionMappings}
        playerTypeFilter={playerTypeFilter}
        setPlayerTypeFilter={setPlayerTypeFilter}

        /* Passer les nouveaux filtres et listes */
        licenciaFilter={licenciaFilter}
        setLicenciaFilter={setLicenciaFilter}
        agenciaFilter={agenciaFilter}
        setAgenciaFilter={setAgenciaFilter}
        agencies={agencies}
      />

      {/* Dialog de confirmation de suppression (simple) */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro que desea eliminar al jugador {playerToDelete?.nom}? Esta acción no se
            puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de confirmation de suppression (multiple) */}
      <Dialog
        open={multiDeleteDialogOpen}
        onClose={handleCloseMultiDeleteDialog}
        aria-labelledby="multi-delete-dialog-title"
      >
        <DialogTitle id="multi-delete-dialog-title">Confirmar Eliminación Múltiple</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro que desea eliminar los {selectedPlayerIDs.length} jugadores
            seleccionados? Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMultiDeleteDialog}>Cancelar</Button>
          <Button onClick={handleConfirmMultipleDelete} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PlayerList;

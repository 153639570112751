import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player';
import { Tweet } from 'react-twitter-widgets';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/**
 * Retourne l'URL de l'avatar pour une source donnée
 * @param {string} source Nom de la source (ex: "BeBasket")
 */
const getSourceAvatarUrl = (source) => {
  if (!source) return '/assets/default.png';
  // Adaptez le chemin si nécessaire (ex: /assets/nomDeLaSource.png)
  return `/assets/${source}.png`;
};

/**
 * Convertit le HTML en React, supprime les <img>,
 * intègre les vidéos YouTube et tweets, etc.
 */
function parseHtmlContent(htmlString) {
  if (!htmlString) return null;

  const getTweetIdFromUrl = (url) => {
    const match = url.match(/status\/(\d+)/);
    return match ? match[1] : null;
  };

  return parse(htmlString, {
    replace: (domNode) => {
      // Supprimer <img>
      if (domNode.name === 'img') {
        return null;
      }

      // iframe YouTube
      if (domNode.name === 'iframe' && domNode.attribs) {
        const src = domNode.attribs.src || '';
        if (src.includes('youtube.com') || src.includes('youtu.be')) {
          return (
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '800px',
                  paddingTop: '56.25%', // ratio 16:9
                }}
              >
                <ReactPlayer
                  url={src}
                  controls
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </Box>
            </Box>
          );
        }
        return null;
      }

      // Liens vers YouTube et Twitter
      if (domNode.name === 'a' && domNode.attribs?.href) {
        const href = domNode.attribs.href;
        // Lien YouTube
        if (href.includes('youtube.com') || href.includes('youtu.be')) {
          return (
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  maxWidth: '800px',
                  paddingTop: '56.25%',
                }}
              >
                <ReactPlayer
                  url={href}
                  controls
                  width="100%"
                  height="100%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </Box>
            </Box>
          );
        }
        // Lien Twitter (Tweet)
        if (href.includes('twitter.com') && href.includes('/status/')) {
          const tweetId = getTweetIdFromUrl(href);
          if (tweetId) {
            return (
              <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <Tweet tweetId={tweetId} options={{ width: '100%' }} />
              </Box>
            );
          }
        }
      }

      return undefined; // Laisse le reste (texte, liens normaux, etc.)
    },
  });
}

const NewsTab = ({ playerId }) => {
  const theme = useTheme();
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Ouverture/fermeture des blocs de contenu
  const [openRows, setOpenRows] = useState({});

  // Dialogo de edición
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [editedSource, setEditedSource] = useState('');
  const [editedUrl, setEditedUrl] = useState('');

  // Dialogo de eliminación
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);

  // Variables d'environnement
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  useEffect(() => {
    const fetchNews = async () => {
      if (!playerId) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        setError(null);

        const response = await axios.get(`${backendUrl}/api/news`, {
          params: { section, playerId },
        });

        setNewsList(response.data || []);
      } catch (err) {
        setError('Error al obtener las noticias.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchNews();
  }, [playerId, section, backendUrl]);

  /**
   * Ouvrir / fermer le contenu
   */
  const handleToggleRow = (newsId) => {
    setOpenRows((prev) => ({
      ...prev,
      [newsId]: !prev[newsId],
    }));
  };

  /**
   * Clic sur "Modificar"
   */
  const handleEditClick = (newsItem) => {
    setCurrentNews(newsItem);
    setEditedTitle(newsItem.title || '');
    setEditedContent(newsItem.content || '');
    setEditedSource(newsItem.source || '');
    setEditedUrl(newsItem.url || '');
    setOpenEditDialog(true);
  };

  /**
   * Sauvegarde de la news modifiée
   */
  const handleEditSave = async () => {
    if (!currentNews) return;

    try {
      // Nettoyage (optionnel) pour éviter des <p> vides
      const transformedContent = editedContent
        .replace(/<p><br><\/p>/g, '<br>')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '<br>')
        .replace(/<br><br>/g, '<br>');

      const updateData = {
        title: editedTitle,
        content: transformedContent,
        source: editedSource,
        url: editedUrl,
      };

      await axios.put(`${backendUrl}/api/news/${currentNews.id}`, updateData, {
        params: { section },
      });

      setNewsList((prev) =>
        prev.map((item) =>
          item.id === currentNews.id
            ? { ...item, ...updateData, content: transformedContent }
            : item
        )
      );

      setOpenEditDialog(false);
      alert('¡Noticia actualizada con éxito!');
    } catch (error) {
      console.error('Error al actualizar la noticia:', error);
      alert('Error al actualizar la noticia.');
    }
  };

  /**
   * Clic sur "Eliminar"
   */
  const handleDeleteClick = (newsItem) => {
    setNewsToDelete(newsItem);
    setOpenDeleteDialog(true);
  };

  /**
   * Confirmation de la suppression
   */
  const confirmDelete = async () => {
    if (!newsToDelete) return;

    try {
      await axios.delete(`${backendUrl}/api/news/${newsToDelete.id}`, {
        params: { section },
      });
      setNewsList((prev) => prev.filter((n) => n.id !== newsToDelete.id));
      setOpenDeleteDialog(false);
      alert('¡Noticia eliminada con éxito!');
    } catch (error) {
      console.error('Error al eliminar la noticia:', error);
      alert('Error al eliminar la noticia.');
    }
  };

  /**
   * Annuler la suppression
   */
  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setNewsToDelete(null);
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="body1" color="error" sx={{ p: 2 }}>
        {error}
      </Typography>
    );
  }

  if (newsList.length === 0) {
    return (
      <Typography variant="body1" sx={{ p: 2 }}>
        Ninguna noticia para este jugador.
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
     
      <List>
        {newsList.map((newsItem) => (
          <React.Fragment key={newsItem.id}>
            <ListItem
              sx={{
                mb: 1,
                borderBottom: '1px solid #ccc',
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* Ligne supérieure : titre, date + source, actions */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {/* Texte principal : titre + date/source */}
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        backgroundColor: '#e0f7fa',
                        display: 'inline-block',
                        p: '2px 4px',
                        borderRadius: '4px',
                      }}
                    >
                      {newsItem.title}
                    </Typography>
                  }
                  secondary={
                    <Box component="span" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                      <Typography
                        component="span"
                        variant="caption"
                        color="textSecondary"
                        sx={{ mr: 1 }}
                      >
                        {new Date(newsItem.updated_at).toLocaleDateString()}
                        {' – '}
                        {newsItem.source || 'N/A'}
                      </Typography>
                      {/* Avatar de la source */}
                      <ListItemAvatar sx={{ minWidth: 'auto', ml: 1 }}>
                        <Tooltip title={newsItem.source || ''}>
                          <Avatar
                            src={getSourceAvatarUrl(newsItem.source)}
                            alt={newsItem.source}
                            sx={{ width: 24, height: 24 }}
                          />
                        </Tooltip>
                      </ListItemAvatar>
                    </Box>
                  }
                />

                {/* Actions : voir contenu, ouvrir article, modifier, supprimer */}
                <Box>
                  {/* Voir contenu (collapse) */}
                  <Tooltip title="Ver contenido">
                    <IconButton onClick={() => handleToggleRow(newsItem.id)}>
                      {openRows[newsItem.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Tooltip>

                  {/* Lien vers l'article si URL existe */}
                  {newsItem.url && (
                    <Tooltip title="Ver artículo">
                      <IconButton
                        onClick={() => window.open(newsItem.url, '_blank')}
                        color="primary"
                        sx={{ ml: 1 }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {/* Modifier */}
                  <Tooltip title="Modificar">
                    <IconButton onClick={() => handleEditClick(newsItem)} color="primary" sx={{ ml: 1 }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>

                  {/* Supprimer */}
                  <Tooltip title="Eliminar">
                    <IconButton onClick={() => handleDeleteClick(newsItem)} sx={{ ml: 1, color: 'red' }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {/* Contenu (collapse) */}
              <Collapse in={openRows[newsItem.id]} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    p: 1,
                    mt: 1,
                    backgroundColor: '#FFF8E1',
                    borderRadius: '4px',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {parseHtmlContent(newsItem.content)}
                </Box>
              </Collapse>
            </ListItem>
          </React.Fragment>
        ))}
      </List>

      {/* Dialogo de edición */}
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Modificar la noticia</DialogTitle>
        <DialogContent>
          {currentNews && (
            <Box sx={{ mt: 2 }}>
              <TextField
                label="Título"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
              />

              <TextField
                label="Fuente"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={editedSource}
                onChange={(e) => setEditedSource(e.target.value)}
              />

              <TextField
                label="URL"
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                value={editedUrl}
                onChange={(e) => setEditedUrl(e.target.value)}
              />

              <Typography variant="subtitle1" gutterBottom>
                Contenido:
              </Typography>
              <ReactQuill
                theme="snow"
                value={editedContent}
                onChange={setEditedContent}
                placeholder="Modificar el contenido..."
                style={{ minHeight: '200px' }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de eliminación */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Eliminar la noticia</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que deseas eliminar esta noticia?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NewsTab;

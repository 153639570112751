import React, { useState, useRef } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import axios from 'axios';

/**
 * Componente reutilizable para la conversión de voz a texto.
 *
 * @param {function} onTranscription - Función callback llamada con la transcripción devuelta por la API.
 * @param {string} backendUrl - URL del servidor backend (puede obtenerse de process.env.REACT_APP_BACKEND_URL).
 */
const SpeechToTextButton = ({ onTranscription, backendUrl }) => {
  const [recording, setRecording] = useState(false);
  const [error, setError] = useState(null);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    try {
      setError(null);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        audioChunksRef.current = [];
        await sendAudioForTranscription(audioBlob);
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error('Error al capturar el audio:', error);
      setError('No se pudo capturar el audio. Verifica los permisos del micrófono.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const sendAudioForTranscription = async (audioBlob) => {
    const formDataAudio = new FormData();
    formDataAudio.append('audio', audioBlob, 'grabacion.wav');
    try {
      const response = await axios.post(
        `${backendUrl}/api/importar/speech-to-text`,
        formDataAudio,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      const transcription = response.data.transcription;
      // Llamamos a la función onTranscription para enviar los datos al componente padre
      if (onTranscription) {
        onTranscription(transcription);
      }
    } catch (err) {
      console.error("Error al enviar el audio al servidor:", err);
      setError("Error al transcribir el audio.");
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <IconButton
        color={recording ? 'error' : 'primary'}
        onClick={recording ? stopRecording : startRecording}
      >
        <MicIcon fontSize="large" />
      </IconButton>
      <Typography variant="body2">
        {recording ? 'Grabando...' : 'Haz clic en el micrófono para grabar'}
      </Typography>
      {error && (
        <Typography variant="caption" color="error" sx={{ ml: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default SpeechToTextButton;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import ReactPlayer from 'react-player';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Fonction utilitaire pour générer l'URL de l'avatar (basée sur l'ID de l'utilisateur)
const getAvatarUrl = (userId) => {
  return `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`;
};

const VideoTab = ({ playerId, onDataLoaded }) => {
  // États pour stocker la liste des vidéos
  const [videoData, setVideoData] = useState([]);

  // États pour la gestion de l'édition
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  // Champs éditables pour la fiche vidéo
  const [editedFecha, setEditedFecha] = useState('');
  const [editedTitre, setEditedTitre] = useState('');
  const [editedUrl, setEditedUrl] = useState('');
  const [editedDetalles, setEditedDetalles] = useState('');

  // États pour la suppression
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // Charger la liste des vidéos via une requête GET
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/videos`, {
          params: { playerId, section }
        });
        setVideoData(response.data);

        if (onDataLoaded) {
          onDataLoaded(response.data.length > 0);
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    };

    fetchVideoData();
  }, [playerId, backendUrl, section, onDataLoaded]);

  // Ouvrir la fenêtre d'édition en pré-remplissant les champs
  const handleEditClick = (video) => {
    setCurrentVideo(video);
    const dateStr = video.fecha ? new Date(video.fecha).toISOString().split('T')[0] : '';
    setEditedFecha(dateStr);
    setEditedTitre(video.titre || '');
    setEditedUrl(video.url || '');
    setEditedDetalles(video.detalles || '');
    setOpenEditDialog(true);
  };

  // Sauvegarder les modifications de la vidéo
  const handleEditSave = async () => {
    if (!currentVideo) return;

    // Transformation optionnelle du HTML pour 'detalles'
    const transformedDetalles = editedDetalles
      .replace(/<p><br><\/p>/g, '<br>')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '<br>')
      .replace(/<br><br>/g, '<br>');

    const updateData = {
      fecha: editedFecha,
      titre: editedTitre,
      url: editedUrl,
      detalles: transformedDetalles
    };

    try {
      await axios.put(
        `${backendUrl}/api/videos/${currentVideo.id}`,
        updateData,
        { params: { section } }
      );

      // Mise à jour locale de la liste
      setVideoData(prevData =>
        prevData.map(item =>
          item.id === currentVideo.id
            ? { ...item, fecha: editedFecha, titre: editedTitre, url: editedUrl, detalles: transformedDetalles }
            : item
        )
      );
      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error updating video:', error);
      alert('Error updating video.');
    }
  };

  // Fermer le dialogue d'édition
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCurrentVideo(null);
  };

  // Ouvrir le dialogue de suppression
  const handleDeleteClick = (video) => {
    setVideoToDelete(video);
    setOpenDeleteDialog(true);
  };

  // Confirmer la suppression
  const confirmDelete = async () => {
    if (!videoToDelete) return;

    try {
      await axios.delete(`${backendUrl}/api/videos/${videoToDelete.id}`, {
        params: { section }
      });

      setVideoData(prevData =>
        prevData.filter(item => item.id !== videoToDelete.id)
      );
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error deleting video:', error);
      alert('Error deleting video.');
    }
  };

  // Annuler la suppression
  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setVideoToDelete(null);
  };

  // Si aucune vidéo n'est disponible
  if (!videoData.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h6">No hay videos disponibles</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {videoData.map((row) => (
        <Card key={row.id} sx={{ mb: 2, '&:last-child': { mb: 0 } }}>
          <CardContent sx={{ p: 2 }}>
            {/* En-tête de la carte */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Avatar
                  src={getAvatarUrl(row.id_usuarios)}
                  alt={row.titre}
                  sx={{ mr: 2 }}
                />
                <Box>
                  {/* Titre en grand */}
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    {row.titre}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(row.fecha).toLocaleDateString()}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <IconButton
                  onClick={(e) => { e.stopPropagation(); handleEditClick(row); }}
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={(e) => { e.stopPropagation(); handleDeleteClick(row); }}
                  size="small"
                  sx={{ color: 'red' }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>

            {/* Zone de visualisation : toujours ouverte, fond blanc, sans afficher l'URL */}
            <Box sx={{ mt: 2, p: 2, bgcolor: 'white', border: 1, borderColor: 'divider' }}>
              <ReactPlayer url={row.url} controls width="100%" />
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" component="div" dangerouslySetInnerHTML={{ __html: row.detalles }} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}

      {/* Dialogue d'édition de la fiche vidéo */}
      <Dialog
        open={openEditDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') return; // Empêche la fermeture sur clic extérieur
          handleCloseEditDialog();
        }}        
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Modificar el Video</DialogTitle>
        <DialogContent>
          {currentVideo && (
            <Box sx={{ mt: 2 }}>
              {/* Date */}
              <Typography variant="subtitle2" gutterBottom>
                <strong>Fecha:</strong>
              </Typography>
              <TextField
                type="date"
                fullWidth
                value={editedFecha}
                onChange={(e) => setEditedFecha(e.target.value)}
                sx={{ mb: 2 }}
              />

              {/* Titre */}
              <Typography variant="subtitle2" gutterBottom>
                <strong>Título:</strong>
              </Typography>
              <TextField
                fullWidth
                value={editedTitre}
                onChange={(e) => setEditedTitre(e.target.value)}
                placeholder="Modificar el título..."
                sx={{ mb: 2 }}
              />

              {/* URL (affiché uniquement en mode édition) */}
              <Typography variant="subtitle2" gutterBottom>
                <strong>URL:</strong>
              </Typography>
              <TextField
                fullWidth
                value={editedUrl}
                onChange={(e) => setEditedUrl(e.target.value)}
                placeholder="Modificar la URL..."
                sx={{ mb: 2 }}
              />

              {/* Détails */}
              <Typography variant="subtitle2" gutterBottom>
                <strong>Detalles:</strong>
              </Typography>
              <ReactQuill
                theme="snow"
                value={editedDetalles}
                onChange={setEditedDetalles}
                placeholder="Modificar los detalles..."
                style={{ minHeight: '150px' }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogue de confirmation pour la suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Eliminar Video</DialogTitle>
        <DialogContent>
          <Typography>¿Está seguro de que desea eliminar este video?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VideoTab;

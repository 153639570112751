import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  Avatar,
  Button,
  Typography,
  TextField,
  Autocomplete,
  Grid,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ListboxComponent from '../components/ListboxComponent ';
import { useSearchParams } from 'react-router-dom';

// ---> IMPORTATION DU COMPOSANT SPEECH-TO-TEXT
import SpeechToTextButton from '../components/SpeechToTextButton';

const PlayerCardCreator = () => {
  // États communs
  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [cardType, setCardType] = useState('');
  const [fecha, setFecha] = useState('');
  const [evento, setEvento] = useState('');
  const [fuente, setFuente] = useState('');
  const [equipo, setEquipo] = useState('');
  const [detalles, setDetalles] = useState('');
  const [userId, setUserId] = useState(null);
  const [eventos, setEventos] = useState([]);

  // États pour Visualización (visu)
  const [visualizacion, setVisualizacion] = useState('');

  // Nouveaux états pour la fiche vidéo
  const [titre, setTitre] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;

  // Nouvel état pour stocker les équipes
  const [teams, setTeams] = useState([]);

  // Pattern pour la validation des nombres décimaux
  const decimalPattern = /^[0-9]{0,3}(\.[0-9]{0,2})?$/;

  // ===============================
  // ÉTATS POUR LA FICHA TÉCNICA
  // ===============================
  // Champs numériques
  const [peso_kg, setPeso_kg] = useState('');
  const [body_fat_porcentaje, setBody_fat_porcentaje] = useState('');
  const [altura_con_zapatos, setAltura_con_zapatos] = useState('');
  const [altura_sin_zapatos, setAltura_sin_zapatos] = useState('');
  const [entrega_vertical, setEntrega_vertical] = useState('');
  const [entrega_horizontal, setEntrega_horizontal] = useState('');

  // Champs textuels
  const [comentarios_observaciones_medidas, setComentarios_observaciones_medidas] = useState('');
  const [escala_perfil_atletico, setEscala_perfil_atletico] = useState('');
  const [informe_medico, setInforme_medico] = useState('');

  const [ataque_asp_ind_puntos_fuertes, setAtaque_asp_ind_puntos_fuertes] = useState('');
  const [ataque_asp_ind_objetivos_mejora, setAtaque_asp_ind_objetivos_mejora] = useState('');
  const [ataque_asp_ind_observaciones_individuales, setAtaque_asp_ind_observaciones_individuales] =
    useState('');

  const [ataque_asp_col_puntos_fuertes, setAtaque_asp_col_puntos_fuertes] = useState('');
  const [ataque_asp_col_objetivos_mejora, setAtaque_asp_col_objetivos_mejora] = useState('');
  const [ataque_asp_col_observaciones_colectivas, setAtaque_asp_col_observaciones_colectivas] =
    useState('');

  const [defensa_asp_ind_puntos_fuertes, setDefensa_asp_ind_puntos_fuertes] = useState('');
  const [defensa_asp_ind_objetivos_mejora, setDefensa_asp_ind_objetivos_mejora] = useState('');
  const [defensa_asp_ind_observaciones_individuales, setDefensa_asp_ind_observaciones_individuales] =
    useState('');

  const [defensa_asp_col_puntos_fuertes, setDefensa_asp_col_puntos_fuertes] = useState('');
  const [defensa_asp_col_objetivos_mejora, setDefensa_asp_col_objetivos_mejora] = useState('');
  const [
    defensa_asp_col_observaciones_colectivas,
    setDefensa_asp_col_observaciones_colectivas
  ] = useState('');

  const [info_audiovisual_observaciones, setInfo_audiovisual_observaciones] = useState('');

  const [asp_fisicos_puntos_fuertes, setAsp_fisicos_puntos_fuertes] = useState('');
  const [asp_fisicos_aspectos_potenciar, setAsp_fisicos_aspectos_potenciar] = useState('');
  const [asp_fisicos_desarrollo_motriz, setAsp_fisicos_desarrollo_motriz] = useState('');
  const [asp_fisicos_observaciones, setAsp_fisicos_observaciones] = useState('');

  const [asp_psicologicos_observaciones, setAsp_psicologicos_observaciones] = useState('');

  const [ambito_academico_observaciones, setAmbito_academico_observaciones] = useState('');

  const [intel_observaciones, setIntel_observaciones] = useState('');

  // ===============================
  const [searchParams] = useSearchParams();

  // --- État pour détecter si le formulaire a été modifié (dirty) ---
  const [isDirty, setIsDirty] = useState(false);

  // --- État pour bloquer le double submit ---
  const [isSubmitting, setIsSubmitting] = useState(false);

  const markDirty = () => {
    setIsDirty(true);
  };

  /**
   * GESTION DU BLOCKAGE DE NAVIGATION :
   * - beforeunload (fermeture / reload)
   * - popstate (bouton retour)
   * - pushState / replaceState (navigation interne via React Router)
   */
  useEffect(() => {
    // -------- 1) BLOQUER LA FERMETURE / RELOAD (beforeunload) --------
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue =
          'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // -------- 2) BLOQUER LE BOUTON RETOUR (popstate) --------
    const handlePopState = (event) => {
      if (isDirty) {
        const confirmLeave = window.confirm(
          'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
        );
        if (!confirmLeave) {
          window.history.pushState(null, '', window.location.pathname);
        }
      }
    };

    window.addEventListener('popstate', handlePopState);

    // -------- 3) BLOQUER LES NAVIGATIONS INTERNES (pushState / replaceState) --------
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    const confirmNavigation = () => {
      if (!isDirty) return true;
      return window.confirm(
        'Hay cambios no guardados. ¿Está seguro(a) de que desea cambiar de página?'
      );
    };

    window.history.pushState = function (...args) {
      if (confirmNavigation()) {
        return originalPushState.apply(this, args);
      }
      return null;
    };

    window.history.replaceState = function (...args) {
      if (confirmNavigation()) {
        return originalReplaceState.apply(this, args);
      }
      return null;
    };

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [isDirty]);

  // Récupération éventuelle du playerId et du cardType via l'URL
  useEffect(() => {
    const playerId = searchParams.get('playerId');
    const preselectedType = searchParams.get('cardType');

    if (playerId) {
      const foundPlayer = players.find((p) => p.id === parseInt(playerId));
      if (foundPlayer) {
        setSelectedPlayer(foundPlayer);
      }
    }

    if (preselectedType) {
      setCardType(preselectedType);
    }
  }, [searchParams, players]);

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/informe/eventos`, {
          params: { section }
        });
        setEventos(response.data);
      } catch (error) {
        console.error('Error fetching eventos:', error);
        setEventos([]);
      }
    };

    fetchEventos();
  }, [backendUrl, section]);

  const reloadEventos = async () => {
    try {
      const timestamp = new Date().getTime();
      const response = await axios.get(`${backendUrl}/api/informe/eventos`, {
        params: {
          section,
          t: timestamp
        }
      });
      setEventos(response.data);
    } catch (error) {
      console.error('Error reloading eventos:', error);
    }
  };

  // Chargement initial des joueurs
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/select`, {
          params: { section }
        });
        setPlayers(response.data.players);
      } catch (error) {
        console.error('Error al obtener los jugador@s:', error);
      }
    };

    fetchPlayers();
    resetFecha();

    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.id) {
      setUserId(storedUser.id);
    }
  }, [backendUrl, section]);

  // Récupération des équipes
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/teams`, {
          params: { section }
        });
        setTeams(response.data.teams || []);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };
    fetchTeams();
  }, [backendUrl, section]);

  const resetFecha = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setFecha(formattedDate);
  };

  const resetForm = () => {
    setSelectedPlayer(null);
    setCardType('');
    resetFecha();
    setEvento('');
    setFuente('');
    setDetalles('');
    setEquipo('');
    setVisualizacion('');
    setTitre('');
    setVideoUrl('');

    // Réinitialiser les champs techniques (fiche técnica)
    setPeso_kg('');
    setBody_fat_porcentaje('');
    setAltura_con_zapatos('');
    setAltura_sin_zapatos('');
    setEntrega_vertical('');
    setEntrega_horizontal('');

    setComentarios_observaciones_medidas('');
    setEscala_perfil_atletico('');
    setInforme_medico('');

    setAtaque_asp_ind_puntos_fuertes('');
    setAtaque_asp_ind_objetivos_mejora('');
    setAtaque_asp_ind_observaciones_individuales('');

    setAtaque_asp_col_puntos_fuertes('');
    setAtaque_asp_col_objetivos_mejora('');
    setAtaque_asp_col_observaciones_colectivas('');

    setDefensa_asp_ind_puntos_fuertes('');
    setDefensa_asp_ind_objetivos_mejora('');
    setDefensa_asp_ind_observaciones_individuales('');

    setDefensa_asp_col_puntos_fuertes('');
    setDefensa_asp_col_objetivos_mejora('');
    setDefensa_asp_col_observaciones_colectivas('');

    setInfo_audiovisual_observaciones('');

    setAsp_fisicos_puntos_fuertes('');
    setAsp_fisicos_aspectos_potenciar('');
    setAsp_fisicos_desarrollo_motriz('');
    setAsp_fisicos_observaciones('');

    setAsp_psicologicos_observaciones('');

    setAmbito_academico_observaciones('');

    setIntel_observaciones('');

    setIsDirty(false);
  };

  const handleCardTypeChange = (e) => {
    setCardType(e.target.value);
    markDirty();
  };

  const transformDetalles = (text) => {
    // Convertir les balises <p> en retours à la ligne pour l'envoi backend
    return text.replace(/<p>/g, '').replace(/<\/p>/g, '<br />').trim();
  };

  const formatDateForBackend = (input) => {
    if (!input || input.includes('T')) return input;
    const [year, month, day] = input.split('-');
    if (year && month && day) {
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    return input;
  };

  const handleCreateCard = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    if (!selectedPlayer || !cardType) {
      alert('Por favor, seleccione un jugador y un tipo de ficha.');
      setIsSubmitting(false);
      return;
    }

    const formattedDetalles = transformDetalles(detalles);

    try {
      const promises = [];

      // Selon le type de fiche
      if (cardType === 'informe') {
        if (!evento) {
          alert('Por favor, ingrese un evento para la ficha de informe.');
          setIsSubmitting(false);
          return;
        }
        if (!visualizacion) {
          alert('Por favor, seleccione un tipo de scouting (video o live).');
          setIsSubmitting(false);
          return;
        }
        const informePromise = axios.post(`${backendUrl}/api/informe`, {
          playerId: selectedPlayer.id,
          fecha,
          id_usuarios: userId,
          evento,
          equipo,
          detalles: formattedDetalles,
          section,
          visu: visualizacion
        });
        promises.push(informePromise);
      } else if (cardType === 'intel') {
        if (!fuente) {
          alert('Por favor, ingrese una fuente para la ficha de intel.');
          setIsSubmitting(false);
          return;
        }
        const intelPromise = axios.post(`${backendUrl}/api/intel`, {
          playerId: selectedPlayer.id,
          fecha,
          id_usuarios: userId,
          fuente,
          detalles: formattedDetalles,
          section
        });
        promises.push(intelPromise);
      } else if (cardType === 'techniques') {
        const num = (val) => (val && val.trim() !== '' ? val.trim() : '1');
        const txt = (val) =>
          val && val.trim() !== '' ? transformDetalles(val) : ' ';

        const techniquesPromise = axios.post(`${backendUrl}/api/technique`, {
          playerId: selectedPlayer.id,
          fecha,
          id_usuarios: userId,

          peso_kg: num(peso_kg),
          body_fat_porcentaje: num(body_fat_porcentaje),
          altura_con_zapatos: num(altura_con_zapatos),
          altura_sin_zapatos: num(altura_sin_zapatos),
          entrega_vertical: num(entrega_vertical),
          entrega_horizontal: num(entrega_horizontal),

          comentarios_observaciones_medidas: txt(comentarios_observaciones_medidas),
          escala_perfil_atletico: txt(escala_perfil_atletico),
          informe_medico: txt(informe_medico),

          ataque_asp_ind_puntos_fuertes: txt(ataque_asp_ind_puntos_fuertes),
          ataque_asp_ind_objetivos_mejora: txt(ataque_asp_ind_objetivos_mejora),
          ataque_asp_ind_observaciones_individuales: txt(
            ataque_asp_ind_observaciones_individuales
          ),

          ataque_asp_col_puntos_fuertes: txt(ataque_asp_col_puntos_fuertes),
          ataque_asp_col_objetivos_mejora: txt(ataque_asp_col_objetivos_mejora),
          ataque_asp_col_observaciones_colectivas: txt(
            ataque_asp_col_observaciones_colectivas
          ),

          defensa_asp_ind_puntos_fuertes: txt(defensa_asp_ind_puntos_fuertes),
          defensa_asp_ind_objetivos_mejora: txt(defensa_asp_ind_objetivos_mejora),
          defensa_asp_ind_observaciones_individuales: txt(
            defensa_asp_ind_observaciones_individuales
          ),

          defensa_asp_col_puntos_fuertes: txt(defensa_asp_col_puntos_fuertes),
          defensa_asp_col_objetivos_mejora: txt(defensa_asp_col_objetivos_mejora),
          defensa_asp_col_observaciones_colectivas: txt(
            defensa_asp_col_observaciones_colectivas
          ),

          info_audiovisual_observaciones: txt(info_audiovisual_observaciones),

          asp_fisicos_puntos_fuertes: txt(asp_fisicos_puntos_fuertes),
          asp_fisicos_aspectos_potenciar: txt(asp_fisicos_aspectos_potenciar),
          asp_fisicos_desarrollo_motriz: txt(asp_fisicos_desarrollo_motriz),
          asp_fisicos_observaciones: txt(asp_fisicos_observaciones),

          asp_psicologicos_observaciones: txt(asp_psicologicos_observaciones),

          ambito_academico_observaciones: txt(ambito_academico_observaciones),

          intel_observaciones: txt(intel_observaciones),

          section
        });
        promises.push(techniquesPromise);
      } else if (cardType === 'video') {
        if (!titre || !videoUrl) {
          alert('Por favor, ingrese el título y la URL del video.');
          setIsSubmitting(false);
          return;
        }
        const videoPromise = axios.post(`${backendUrl}/api/videos`, {
          playerId: selectedPlayer.id,
          fecha,
          id_usuarios: userId,
          titre,
          url: videoUrl,
          detalles: formattedDetalles,
          section
        });
        promises.push(videoPromise);
      }

      await Promise.all(promises);

      if (cardType === 'informe' && !eventos.includes(evento)) {
        await reloadEventos();
      }

      alert('Ficha guardada exitosamente!');
      resetForm();
    } catch (error) {
      console.error('Error al guardar la ficha:', error);
      alert('Error al guardar la ficha.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ mt: 4, px: 4 }}>
      <Typography variant="h4" gutterBottom>
        Crear una Ficha
      </Typography>

      {/* Sélecteur de joueur avec Autocomplete */}
      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <Autocomplete
          options={players}
          getOptionLabel={(player) => player.nom}
          value={selectedPlayer}
          onChange={(event, newValue) => {
            setSelectedPlayer(newValue);
            markDirty();
          }}
          renderInput={(params) => (
            <TextField {...params} label="Seleccionar un jugador" variant="outlined" />
          )}
          renderOption={(props, player) => (
            <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={`${mediaUrl}/photos/${player.id}.png`}
                alt={player.nom}
                sx={{ width: 30, height: 30, marginRight: 1 }}
              />
              {player.nom}
            </Box>
          )}
          ListboxComponent={ListboxComponent}
        />
      </FormControl>

      {/* Avatar du joueur sélectionné */}
      {selectedPlayer && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 3
          }}
        >
          <Avatar
            src={`${mediaUrl}/photos/${selectedPlayer.id}.png`}
            alt={selectedPlayer.nom}
            sx={{
              width: 150,
              height: 150,
              border: '2px solid #e0e0e0',
              boxShadow: '0px 3px 6px rgba(0,0,0,0.1)'
            }}
          />
        </Box>
      )}

      {/* Sélecteur de type de fiche */}
      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <TextField
          select
          label="Tipo de ficha"
          value={cardType}
          onChange={handleCardTypeChange}
          SelectProps={{
            native: true
          }}
        >
          <option value="" />
          <option value="techniques">Técnica</option>
          <option value="informe">Informe</option>
          <option value="intel">INTEL</option>
          <option value="video">Media</option>
        </TextField>
      </FormControl>

      {/* Formulaire pour "informe" et "intel" */}
      {(cardType === 'informe' || cardType === 'intel') && (
        <Box sx={{ mt: 3 }}>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Fecha"
              variant="outlined"
              type="date"
              value={fecha}
              onChange={(e) => {
                setFecha(e.target.value);
                markDirty();
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>

          {cardType === 'informe' && (
            <>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <Autocomplete
                  freeSolo
                  options={eventos}
                  value={evento || null}
                  onChange={(event, newValue) => {
                    setEvento(newValue || '');
                    markDirty();
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (event && event.type === 'change') {
                      setEvento(newInputValue);
                      markDirty();
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Evento"
                      variant="outlined"
                      placeholder="Seleccione o escriba el evento"
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <TextField
                  select
                  label="Scouting"
                  variant="outlined"
                  value={visualizacion}
                  onChange={(e) => {
                    setVisualizacion(e.target.value);
                    markDirty();
                  }}
                  SelectProps={{
                    native: true
                  }}
                >
                  <option value=""></option>
                  <option value="video">Video</option>
                  <option value="live">Live</option>
                </TextField>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <Autocomplete
                  freeSolo
                  options={teams}
                  getOptionLabel={(option) => option}
                  value={equipo || null}
                  onChange={(event, newValue) => {
                    setEquipo(newValue || '');
                    markDirty();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Equipo"
                      variant="outlined"
                      placeholder="Seleccione o escriba el equipo"
                      onChange={(e) => {
                        setEquipo(e.target.value);
                        markDirty();
                      }}
                    />
                  )}
                />
              </FormControl>
            </>
          )}

          {cardType === 'intel' && (
            <FormControl fullWidth sx={{ mb: 3 }}>
              <TextField
                label="Fuente"
                variant="outlined"
                value={fuente}
                onChange={(e) => {
                  setFuente(e.target.value);
                  markDirty();
                }}
                placeholder="Introduzca la fuente"
              />
            </FormControl>
          )}

          {/* Detalles + SpeechToTextButton */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Typography variant="body1" gutterBottom>
              Detalles
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDetalles((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={detalles}
              onChange={(value) => {
                setDetalles(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCard}
            sx={{ mt: 3 }}
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                Guardando...
              </>
            ) : (
              `Guardar ${cardType === 'informe' ? 'Informe' : 'Intel'}`
            )}
          </Button>
        </Box>
      )}

      {/* Formulaire pour "video" */}
      {cardType === 'video' && (
        <Box sx={{ mt: 3 }}>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Fecha"
              variant="outlined"
              type="date"
              value={fecha}
              onChange={(e) => {
                setFecha(e.target.value);
                markDirty();
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Título"
              variant="outlined"
              value={titre}
              onChange={(e) => {
                setTitre(e.target.value);
                markDirty();
              }}
              placeholder="Ingrese el título del video"
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="URL del Video"
              variant="outlined"
              value={videoUrl}
              onChange={(e) => {
                setVideoUrl(e.target.value);
                markDirty();
              }}
              placeholder="Ingrese la URL del video (ej. YouTube, Vimeo)"
            />
          </FormControl>

          {/* Detalles + SpeechToTextButton */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Typography variant="body1" gutterBottom>
              Detalles
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDetalles((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={detalles}
              onChange={(value) => {
                setDetalles(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCard}
            sx={{ mt: 3 }}
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                Guardando...
              </>
            ) : (
              'Guardar Media'
            )}
          </Button>
        </Box>
      )}

      {/* Formulaire pour "techniques" */}
      {cardType === 'techniques' && (
        <Box sx={{ mt: 3 }}>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Fecha"
              variant="outlined"
              type="date"
              value={fecha}
              onChange={(e) => {
                setFecha(e.target.value);
                markDirty();
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </FormControl>

          <Typography variant="h6" gutterBottom>
            Medidas Físicas
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Peso (kg)"
                variant="outlined"
                value={peso_kg}
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) {
                    setPeso_kg(e.target.value);
                    markDirty();
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Body Fat (%)"
                variant="outlined"
                value={body_fat_porcentaje}
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) {
                    setBody_fat_porcentaje(e.target.value);
                    markDirty();
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Altura con Zapatos (cm)"
                variant="outlined"
                value={altura_con_zapatos}
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) {
                    setAltura_con_zapatos(e.target.value);
                    markDirty();
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Altura sin Zapatos (cm)"
                variant="outlined"
                value={altura_sin_zapatos}
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) {
                    setAltura_sin_zapatos(e.target.value);
                    markDirty();
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Envergadura Vertical (cm)"
                variant="outlined"
                value={entrega_vertical}
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) {
                    setEntrega_vertical(e.target.value);
                    markDirty();
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Envergadura Horizontal (cm)"
                variant="outlined"
                value={entrega_horizontal}
                onChange={(e) => {
                  if (decimalPattern.test(e.target.value)) {
                    setEntrega_horizontal(e.target.value);
                    markDirty();
                  }
                }}
              />
            </Grid>
          </Grid>

          {/* Comentarios y Observaciones + SpeechToTextButton */}
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Typography variant="body1" gutterBottom>
              Comentarios y Observaciones
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setComentarios_observaciones_medidas((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={comentarios_observaciones_medidas}
              onChange={(value) => {
                setComentarios_observaciones_medidas(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Escala Perfil Atlético + SpeechToTextButton */}
          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom>
              Escala Perfil Atlético
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setEscala_perfil_atletico((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={escala_perfil_atletico}
              onChange={(value) => {
                setEscala_perfil_atletico(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Informe Médico + SpeechToTextButton */}
          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <Typography variant="body1" gutterBottom>
              Informe Médico
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setInforme_medico((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={informe_medico}
              onChange={(value) => {
                setInforme_medico(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Ataque - Aspectos Individuales */}
          <Typography variant="h6" gutterBottom>
            Ataque - Aspectos Individuales
          </Typography>

          <FormControl fullWidth sx={{ mt: 1 }}>
            <Typography>Puntos Fuertes</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAtaque_asp_ind_puntos_fuertes((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={ataque_asp_ind_puntos_fuertes}
              onChange={(value) => {
                setAtaque_asp_ind_puntos_fuertes(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography>Objetivos de Mejora</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAtaque_asp_ind_objetivos_mejora((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={ataque_asp_ind_objetivos_mejora}
              onChange={(value) => {
                setAtaque_asp_ind_objetivos_mejora(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <Typography>Observaciones Individuales</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAtaque_asp_ind_observaciones_individuales((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={ataque_asp_ind_observaciones_individuales}
              onChange={(value) => {
                setAtaque_asp_ind_observaciones_individuales(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Ataque - Aspectos Colectivos */}
          <Typography variant="h6" gutterBottom>
            Ataque - Aspectos Colectivos
          </Typography>

          <FormControl fullWidth sx={{ mt: 1 }}>
            <Typography>Puntos Fuertes</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAtaque_asp_col_puntos_fuertes((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={ataque_asp_col_puntos_fuertes}
              onChange={(value) => {
                setAtaque_asp_col_puntos_fuertes(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography>Objetivos de Mejora</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAtaque_asp_col_objetivos_mejora((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={ataque_asp_col_objetivos_mejora}
              onChange={(value) => {
                setAtaque_asp_col_objetivos_mejora(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <Typography>Observaciones Colectivas</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAtaque_asp_col_observaciones_colectivas((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={ataque_asp_col_observaciones_colectivas}
              onChange={(value) => {
                setAtaque_asp_col_observaciones_colectivas(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Defensa - Aspectos Individuales */}
          <Typography variant="h6" gutterBottom>
            Defensa - Aspectos Individuales
          </Typography>

          <FormControl fullWidth sx={{ mt: 1 }}>
            <Typography>Puntos Fuertes</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDefensa_asp_ind_puntos_fuertes((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={defensa_asp_ind_puntos_fuertes}
              onChange={(value) => {
                setDefensa_asp_ind_puntos_fuertes(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography>Objetivos de Mejora</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDefensa_asp_ind_objetivos_mejora((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={defensa_asp_ind_objetivos_mejora}
              onChange={(value) => {
                setDefensa_asp_ind_objetivos_mejora(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <Typography>Observaciones Individuales</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDefensa_asp_ind_observaciones_individuales(
                    (prev) => prev + '\n' + transcript
                  );
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={defensa_asp_ind_observaciones_individuales}
              onChange={(value) => {
                setDefensa_asp_ind_observaciones_individuales(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Defensa - Aspectos Colectivos */}
          <Typography variant="h6" gutterBottom>
            Defensa - Aspectos Colectivos
          </Typography>

          <FormControl fullWidth sx={{ mt: 1 }}>
            <Typography>Puntos Fuertes</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDefensa_asp_col_puntos_fuertes((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={defensa_asp_col_puntos_fuertes}
              onChange={(value) => {
                setDefensa_asp_col_puntos_fuertes(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography>Objetivos de Mejora</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDefensa_asp_col_objetivos_mejora((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={defensa_asp_col_objetivos_mejora}
              onChange={(value) => {
                setDefensa_asp_col_objetivos_mejora(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <Typography>Observaciones Colectivas</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setDefensa_asp_col_observaciones_colectivas(
                    (prev) => prev + '\n' + transcript
                  );
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={defensa_asp_col_observaciones_colectivas}
              onChange={(value) => {
                setDefensa_asp_col_observaciones_colectivas(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Información Audiovisual */}
          <Typography variant="h6" gutterBottom>
            Información Audiovisual
          </Typography>
          <FormControl fullWidth sx={{ mt: 1, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setInfo_audiovisual_observaciones((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={info_audiovisual_observaciones}
              onChange={(value) => {
                setInfo_audiovisual_observaciones(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Aspectos Físicos */}
          <Typography variant="h6" gutterBottom>
            Aspectos Físicos
          </Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Typography>Puntos Fuertes</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAsp_fisicos_puntos_fuertes((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={asp_fisicos_puntos_fuertes}
              onChange={(value) => {
                setAsp_fisicos_puntos_fuertes(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography>Aspectos que potenciar</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAsp_fisicos_aspectos_potenciar((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={asp_fisicos_aspectos_potenciar}
              onChange={(value) => {
                setAsp_fisicos_aspectos_potenciar(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography>Desarrollo motriz</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAsp_fisicos_desarrollo_motriz((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={asp_fisicos_desarrollo_motriz}
              onChange={(value) => {
                setAsp_fisicos_desarrollo_motriz(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
            <Typography>Observaciones</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAsp_fisicos_observaciones((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={asp_fisicos_observaciones}
              onChange={(value) => {
                setAsp_fisicos_observaciones(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Aspectos Psicológicos */}
          <Typography variant="h6" gutterBottom>
            Aspectos Psicológicos
          </Typography>
          <FormControl fullWidth sx={{ mt: 1, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAsp_psicologicos_observaciones((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={asp_psicologicos_observaciones}
              onChange={(value) => {
                setAsp_psicologicos_observaciones(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* Ámbito Académico */}
          <Typography variant="h6" gutterBottom>
            Ámbito Académico
          </Typography>
          <FormControl fullWidth sx={{ mt: 1, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setAmbito_academico_observaciones((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={ambito_academico_observaciones}
              onChange={(value) => {
                setAmbito_academico_observaciones(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          {/* INTEL */}
          <Typography variant="h6" gutterBottom>
            INTEL
          </Typography>
          <FormControl fullWidth sx={{ mt: 1, mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <SpeechToTextButton
                backendUrl={backendUrl}
                onTranscription={(transcript) => {
                  setIntel_observaciones((prev) => prev + '\n' + transcript);
                  markDirty();
                }}
              />
            </Box>
            <ReactQuill
              theme="snow"
              value={intel_observaciones}
              onChange={(value) => {
                setIntel_observaciones(value);
                markDirty();
              }}
              placeholder="Observaciones..."
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCard}
            sx={{ mt: 3 }}
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                Guardando...
              </>
            ) : (
              'Guardar Técnicas'
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PlayerCardCreator;

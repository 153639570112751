// MediaTechnica.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Fab,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactPlayer from 'react-player';
import axios from 'axios';

/**
 * Composant "MediaTechnica"
 * Gère la liste des médias (vidéos, etc.) associés à une fiche technique donnée (table medias_technica).
 *
 * @param {number|string} itemId - Identifiant de la "fiche technique" (fiches_suivi_technique.id)
 */
const MediaTechnica = ({ itemId }) => {
  const [medias, setMedias] = useState([]);

  // État de la boîte de dialogue (ajout/édition)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState('add'); // 'add' ou 'edit'
  const [currentMedia, setCurrentMedia] = useState({ id: null, titre: '', url: '' });

  // État de la boîte de dialogue de suppression
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);

  // URL du backend et section, si vous l’utilisez
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // ---------------------------------------------------------------------------
  // 1) Charger les médias depuis l'API quand itemId change
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (!itemId) return; // Pas de fiche => pas de medias
    fetchMedias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const fetchMedias = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/api/technique/fiches/${itemId}/medias`,
        {
          // On passe la section en paramètre GET
          params: { section }
        }
      );
      setMedias(response.data); // Tableau de médias depuis la BDD
    } catch (error) {
      console.error('Error fetching medias from DB:', error);
    }
  };

  // ---------------------------------------------------------------------------
  // 2) Ouvrir la boîte de dialogue en mode "add"
  // ---------------------------------------------------------------------------
  const handleAddClick = () => {
    setDialogMode('add');
    setCurrentMedia({ id: null, titre: '', url: '' });
    setDialogOpen(true);
  };

  // ---------------------------------------------------------------------------
  // 3) Ouvrir la boîte de dialogue en mode "edit"
  // ---------------------------------------------------------------------------
  const handleEditClick = (media) => {
    setDialogMode('edit');
    setCurrentMedia(media);
    setDialogOpen(true);
  };

  // ---------------------------------------------------------------------------
  // 4) Sauvegarder (ajout ou édition) => appels Axios
  // ---------------------------------------------------------------------------
  const handleDialogSave = async () => {
    try {
      if (dialogMode === 'add') {
        // POST => /api/technique/fiches/:ficheId/medias
        const response = await axios.post(
          `${backendUrl}/api/technique/fiches/${itemId}/medias`,
          {
            titre: currentMedia.titre,
            url: currentMedia.url,
            section
          }
        );
        // Le backend renvoie le nouvel objet créé avec son id
        const newMedia = response.data;
        setMedias((prev) => [...prev, newMedia]);
      } else {
        // PUT => /api/technique/medias/:id
        await axios.put(
          `${backendUrl}/api/technique/medias/${currentMedia.id}`,
          {
            titre: currentMedia.titre,
            url: currentMedia.url,
            section
          }
        );
        // Mettre à jour localement
        setMedias((prev) =>
          prev.map((m) =>
            m.id === currentMedia.id
              ? { ...m, titre: currentMedia.titre, url: currentMedia.url }
              : m
          )
        );
      }
      setDialogOpen(false);
    } catch (error) {
      console.error('Error saving media:', error);
      alert('Error guardando el media');
    }
  };

  // ---------------------------------------------------------------------------
  // 5) Annuler (fermer la boîte de dialogue)
  // ---------------------------------------------------------------------------
  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  // ---------------------------------------------------------------------------
  // 6) Supprimer => ouvrir le dialogue de confirmation
  // ---------------------------------------------------------------------------
  const handleDeleteClick = (media) => {
    setMediaToDelete(media);
    setDeleteDialogOpen(true);
  };

  // ---------------------------------------------------------------------------
  // 7) Confirmer la suppression => appel Axios
  // ---------------------------------------------------------------------------
  const handleDeleteConfirm = async () => {
    try {
      if (!mediaToDelete) return;
      // DELETE => /api/technique/medias/:id
      await axios.delete(
        `${backendUrl}/api/technique/medias/${mediaToDelete.id}`,
        {
          params: { section }
        }
      );
      // Mise à jour locale
      setMedias((prev) => prev.filter((m) => m.id !== mediaToDelete.id));
    } catch (error) {
      console.error('Error deleting media:', error);
      alert('Error eliminando el media');
    } finally {
      setDeleteDialogOpen(false);
      setMediaToDelete(null);
    }
  };

  // ---------------------------------------------------------------------------
  // 8) Annuler la suppression
  // ---------------------------------------------------------------------------
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setMediaToDelete(null);
  };

  // ---------------------------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------------------------
  return (
    <Box sx={{ position: 'relative', mt: 2 }}>
      

      {/* Liste de médias */}
      {medias.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          No hay videos/medias añadidos para esta ficha técnica.
        </Typography>
      ) : (
        medias.map((media) => (
          <Card key={media.id} sx={{ mb: 1 }}>
            <CardContent>
              {/* En-tête : Titre + Actions (Edit / Delete) */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {media.titre || ''}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => handleEditClick(media)}
                    sx={{ mr: 1 }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDeleteClick(media)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>

              {/* Player vidéo (ReactPlayer) */}
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: 'white',
                  border: 1,
                  borderColor: 'divider'
                }}
              >
                <ReactPlayer url={media.url} controls width="100%" />
              </Box>
            </CardContent>
          </Card>
        ))
      )}

      {/* Bouton flottant pour ajouter un média */}
      <Tooltip title="Añadir un nuevo media">
        <Fab
          color="primary"
          size="small"
          onClick={handleAddClick}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            transform: 'translate(50%, 50%)'
          }}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      {/* Dialog d'ajout/édition */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogCancel}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {dialogMode === 'add' ? 'Agregar Media' : 'Editar Media'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Título (opcional)"
            fullWidth
            margin="normal"
            value={currentMedia.titre}
            onChange={(e) =>
              setCurrentMedia((prev) => ({ ...prev, titre: e.target.value }))
            }
          />
          <TextField
            label="URL"
            fullWidth
            margin="normal"
            value={currentMedia.url}
            onChange={(e) =>
              setCurrentMedia((prev) => ({ ...prev, url: e.target.value }))
            }
            placeholder="https://www.youtube.com/watch?v=abcd1234..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Cancelar</Button>
          <Button onClick={handleDialogSave} variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de confirmation de suppression */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <Typography>¿Está seguro que desea eliminar este media?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancelar</Button>
          <Button onClick={handleDeleteConfirm} variant="contained" color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MediaTechnica;

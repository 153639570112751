import React from 'react';
import { Box } from '@mui/material';

/**
 * Ce composant reçoit une nationalité en prop,
 * puis renvoie un <img> avec le drapeau correspondant si possible.
 * Sinon, il ne renvoie rien (null).
 *
 * Pour matcher, la "nationality" transmise doit correspondre
 * (après mise en minuscules et trim) à l'une des clés de "flagsMap".
 */
const FlagDisplay = ({ nationality }) => {
  if (!nationality) {
    return null;
  }

  // Dictionnaire de nationalités -> URL de drapeau (ISO 3166-1 alpha-2).
  // Les clés sont déjà toutes en minuscules pour simplifier la comparaison.
  // Exemple : "afgano (afg)" => "https://flagcdn.com/af.svg"
  const flagsMap = {
    "afgano (afg)": "https://flagcdn.com/af.svg",
    "alemán (deu)": "https://flagcdn.com/de.svg",
    "andorrano (and)": "https://flagcdn.com/ad.svg",
    "angoleño (ago)": "https://flagcdn.com/ao.svg",
    "antiguano (atg)": "https://flagcdn.com/ag.svg",
    "argelino (dza)": "https://flagcdn.com/dz.svg",
    "argentino (arg)": "https://flagcdn.com/ar.svg",
    "armenio (arm)": "https://flagcdn.com/am.svg",
    "australiano (aus)": "https://flagcdn.com/au.svg",
    "austriaco (aut)": "https://flagcdn.com/at.svg",
    "azerbaiyano (aze)": "https://flagcdn.com/az.svg",
    "bahameño (bhs)": "https://flagcdn.com/bs.svg",
    "bahreiní (bhr)": "https://flagcdn.com/bh.svg",
    "bangladesí (bgd)": "https://flagcdn.com/bd.svg",
    "barbadense (brb)": "https://flagcdn.com/bb.svg",
    "belga (bel)": "https://flagcdn.com/be.svg",
    "beliceño (blz)": "https://flagcdn.com/bz.svg",
    "beninés (ben)": "https://flagcdn.com/bj.svg",
    "bielorruso (blr)": "https://flagcdn.com/by.svg",
    "birmano (mmr)": "https://flagcdn.com/mm.svg",
    "boliviano (bol)": "https://flagcdn.com/bo.svg",
    "bosnio (bih)": "https://flagcdn.com/ba.svg",
    "botsuano (bwa)": "https://flagcdn.com/bw.svg",
    "brasileño (bra)": "https://flagcdn.com/br.svg",
    "bruneano (brn)": "https://flagcdn.com/bn.svg",
    "búlgaro (bgr)": "https://flagcdn.com/bg.svg",
    "burkinés (bfa)": "https://flagcdn.com/bf.svg",
    "burundés (bdi)": "https://flagcdn.com/bi.svg",
    "butanés (btn)": "https://flagcdn.com/bt.svg",
    "caboverdiano (cpv)": "https://flagcdn.com/cv.svg",
    "camerunés (cmr)": "https://flagcdn.com/cm.svg",
    "canadiense (can)": "https://flagcdn.com/ca.svg",
    "catarí (qat)": "https://flagcdn.com/qa.svg",
    "centroafricano (caf)": "https://flagcdn.com/cf.svg",
    "chadiano (tcd)": "https://flagcdn.com/td.svg",
    "checo (cze)": "https://flagcdn.com/cz.svg",
    "chileno (chl)": "https://flagcdn.com/cl.svg",
    "chino (chn)": "https://flagcdn.com/cn.svg",
    "chipriota (cyp)": "https://flagcdn.com/cy.svg",
    "colombiano (col)": "https://flagcdn.com/co.svg",
    "comorense (com)": "https://flagcdn.com/km.svg",
    "congoleño (cog)": "https://flagcdn.com/cg.svg",
    "costarricense (cri)": "https://flagcdn.com/cr.svg",
    "croata (hrv)": "https://flagcdn.com/hr.svg",
    "cubano (cub)": "https://flagcdn.com/cu.svg",
    "danés (dnk)": "https://flagcdn.com/dk.svg",
    "dominicano (dom)": "https://flagcdn.com/do.svg",
    "ecuatoriano (ecu)": "https://flagcdn.com/ec.svg",
    "egipcio (egy)": "https://flagcdn.com/eg.svg",
    "emiratí (are)": "https://flagcdn.com/ae.svg",
    "eritreo (eri)": "https://flagcdn.com/er.svg",
    "eslovaco (svk)": "https://flagcdn.com/sk.svg",
    "esloveno (svn)": "https://flagcdn.com/si.svg",
    "español (esp)": "https://flagcdn.com/es.svg",
    "estadounidense (usa)": "https://flagcdn.com/us.svg",
    "estonio (est)": "https://flagcdn.com/ee.svg",
    "etíope (eth)": "https://flagcdn.com/et.svg",
    "filipino (phl)": "https://flagcdn.com/ph.svg",
    "finlandés (fin)": "https://flagcdn.com/fi.svg",
    "fiyiano (fji)": "https://flagcdn.com/fj.svg",
    "francés (fra)": "https://flagcdn.com/fr.svg",
    "gabonés (gab)": "https://flagcdn.com/ga.svg",
    "gambiano (gmb)": "https://flagcdn.com/gm.svg",
    "georgiano (geo)": "https://flagcdn.com/ge.svg",
    "ghanés (gha)": "https://flagcdn.com/gh.svg",
    "granadino (grd)": "https://flagcdn.com/gd.svg",
    "griego (grc)": "https://flagcdn.com/gr.svg",
    "guatemalteco (gtm)": "https://flagcdn.com/gt.svg",
    "guineano (gin)": "https://flagcdn.com/gn.svg",
    "guyanés (guy)": "https://flagcdn.com/gy.svg",
    "haitiano (hti)": "https://flagcdn.com/ht.svg",
    "holandés (nld)": "https://flagcdn.com/nl.svg",
    "hondureño (hnd)": "https://flagcdn.com/hn.svg",
    "húngaro (hun)": "https://flagcdn.com/hu.svg",
    "indio (ind)": "https://flagcdn.com/in.svg",
    "inglés (eng)": "https://flagcdn.com/gb.svg", // Angleterre => drapeau du Royaume-Uni
    "indonesio (idn)": "https://flagcdn.com/id.svg",
    "iraní (irn)": "https://flagcdn.com/ir.svg",
    "iraquí (irq)": "https://flagcdn.com/iq.svg",
    "irlandés (irl)": "https://flagcdn.com/ie.svg",
    "islandés (isl)": "https://flagcdn.com/is.svg",
    "israelí (isr)": "https://flagcdn.com/il.svg",
    "italiano (ita)": "https://flagcdn.com/it.svg",
    "jamaicano (jam)": "https://flagcdn.com/jm.svg",
    "japonés (jpn)": "https://flagcdn.com/jp.svg",
    "jordano (jor)": "https://flagcdn.com/jo.svg",
    "kazajo (kaz)": "https://flagcdn.com/kz.svg",
    "keniano (ken)": "https://flagcdn.com/ke.svg",
    "kirguís (kgz)": "https://flagcdn.com/kg.svg",
    "kiribatiano (kir)": "https://flagcdn.com/ki.svg",
    "kuwaití (kwt)": "https://flagcdn.com/kw.svg",
    "laosiano (lao)": "https://flagcdn.com/la.svg",
    "lesotense (lso)": "https://flagcdn.com/ls.svg",
    "letón (lva)": "https://flagcdn.com/lv.svg",
    "libanés (lbn)": "https://flagcdn.com/lb.svg",
    "liberiano (lbr)": "https://flagcdn.com/lr.svg",
    "libio (lby)": "https://flagcdn.com/ly.svg",
    "liechtensteiniano (lie)": "https://flagcdn.com/li.svg",
    "lituano (ltu)": "https://flagcdn.com/lt.svg",
    "luxemburgués (lux)": "https://flagcdn.com/lu.svg",
    "macedonio (mkd)": "https://flagcdn.com/mk.svg",
    "malasio (mys)": "https://flagcdn.com/my.svg",
    "malauí (mwi)": "https://flagcdn.com/mw.svg",
    "maliense (mli)": "https://flagcdn.com/ml.svg",
    "maltés (mlt)": "https://flagcdn.com/mt.svg",
    "marfileño (civ)": "https://flagcdn.com/ci.svg",
    "marroquí (mar)": "https://flagcdn.com/ma.svg",
    "mauriciano (mus)": "https://flagcdn.com/mu.svg",
    "mauritano (mrt)": "https://flagcdn.com/mr.svg",
    "mexicano (mex)": "https://flagcdn.com/mx.svg",
    "micronesio (fsm)": "https://flagcdn.com/fm.svg",
    "moldavo (mda)": "https://flagcdn.com/md.svg",
    "monegasco (mco)": "https://flagcdn.com/mc.svg",
    "mongol (mng)": "https://flagcdn.com/mn.svg",
    "montenegrino (mne)": "https://flagcdn.com/me.svg",
    "mozambiqueño (moz)": "https://flagcdn.com/mz.svg",
    "namibio (nam)": "https://flagcdn.com/na.svg",
    "neozelandés (nzl)": "https://flagcdn.com/nz.svg",
    "nepalí (npl)": "https://flagcdn.com/np.svg",
    "nicaragüense (nic)": "https://flagcdn.com/ni.svg",
    "nigerino (ner)": "https://flagcdn.com/ne.svg",
    "nigeriano (nga)": "https://flagcdn.com/ng.svg",
    "norcoreano (prk)": "https://flagcdn.com/kp.svg",
    "noruego (nor)": "https://flagcdn.com/no.svg",
    "omaní (omn)": "https://flagcdn.com/om.svg",
    "pakistaní (pak)": "https://flagcdn.com/pk.svg",
    "palauano (plw)": "https://flagcdn.com/pw.svg",
    "panameño (pan)": "https://flagcdn.com/pa.svg",
    "papú (png)": "https://flagcdn.com/pg.svg",
    "paraguayo (pry)": "https://flagcdn.com/py.svg",
    "peruano (per)": "https://flagcdn.com/pe.svg",
    "polaco (pol)": "https://flagcdn.com/pl.svg",
    "portugués (prt)": "https://flagcdn.com/pt.svg",
    "puertorriqueño (pri)": "https://flagcdn.com/pr.svg",
    "qatarí (qat)": "https://flagcdn.com/qa.svg",
    "reunionés (reu)": "https://flagcdn.com/re.svg", // Si non dispo, forcer "fr.svg"
    "rumano (rou)": "https://flagcdn.com/ro.svg",
    "ruso (rus)": "https://flagcdn.com/ru.svg",
    "ruandés (rwa)": "https://flagcdn.com/rw.svg",
    "samoano (wsm)": "https://flagcdn.com/ws.svg",
    "sancristobaleño (kna)": "https://flagcdn.com/kn.svg",
    "sanmarinense (smr)": "https://flagcdn.com/sm.svg",
    "sanvicentino (vct)": "https://flagcdn.com/vc.svg",
    "santotomense (stp)": "https://flagcdn.com/st.svg",
    "saudí (sau)": "https://flagcdn.com/sa.svg",
    "senegalés (sen)": "https://flagcdn.com/sn.svg",
    "serbio (srb)": "https://flagcdn.com/rs.svg",
    "seychelense (syc)": "https://flagcdn.com/sc.svg",
    "sierraleonés (sle)": "https://flagcdn.com/sl.svg",
    "singapurense (sgp)": "https://flagcdn.com/sg.svg",
    "sirio (syr)": "https://flagcdn.com/sy.svg",
    "somalí (som)": "https://flagcdn.com/so.svg",
    "srilanqués (lka)": "https://flagcdn.com/lk.svg",
    "suazi (swz)": "https://flagcdn.com/sz.svg",
    "sudafricano (zaf)": "https://flagcdn.com/za.svg",
    "sudanés (sdn)": "https://flagcdn.com/sd.svg",
    "sudcoreano (kor)": "https://flagcdn.com/kr.svg",
    "sueco (swe)": "https://flagcdn.com/se.svg",
    "suizo (che)": "https://flagcdn.com/ch.svg",
    "surinamés (sur)": "https://flagcdn.com/sr.svg",
    "sursudanés (ssd)": "https://flagcdn.com/ss.svg",
    "tailandés (tha)": "https://flagcdn.com/th.svg",
    "tanzano (tza)": "https://flagcdn.com/tz.svg",
    "tayiko (tjk)": "https://flagcdn.com/tj.svg",
    "timorense (tls)": "https://flagcdn.com/tl.svg",
    "togolés (tgo)": "https://flagcdn.com/tg.svg",
    "tongano (ton)": "https://flagcdn.com/to.svg",
    "trinitense (tto)": "https://flagcdn.com/tt.svg",
    "tunecino (tun)": "https://flagcdn.com/tn.svg",
    "turcomano (tkm)": "https://flagcdn.com/tm.svg",
    "turco (tur)": "https://flagcdn.com/tr.svg",
    "tuvaluano (tuv)": "https://flagcdn.com/tv.svg",
    "ucraniano (ukr)": "https://flagcdn.com/ua.svg",
    "ugandés (uga)": "https://flagcdn.com/ug.svg",
    "uruguayo (ury)": "https://flagcdn.com/uy.svg",
    "uzbeko (uzb)": "https://flagcdn.com/uz.svg",
    "vanuatuense (vut)": "https://flagcdn.com/vu.svg",
    "venezolano (ven)": "https://flagcdn.com/ve.svg",
    "vietnamita (vnm)": "https://flagcdn.com/vn.svg",
    "virginense (isv)": "https://flagcdn.com/vi.svg",
    "yemení (yem)": "https://flagcdn.com/ye.svg",
    "yibutiano (dji)": "https://flagcdn.com/dj.svg",
    "zambiano (zmb)": "https://flagcdn.com/zm.svg",
    "zimbabuense (zwe)": "https://flagcdn.com/zw.svg"
  };

  // On normalise la nationalité reçue (en minuscules, trim)
  const normalizedNat = nationality.trim().toLowerCase();

  // On cherche la clé correspondante. 
  // Ex.: "Mexicano (MEX)" => "mexicano (mex)".
  const flagUrl = flagsMap[normalizedNat] || null;

  if (!flagUrl) {
    // Si la nationalité n'est pas dans le dictionnaire, on ne renvoie rien
    return null;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <img
        src={flagUrl}
        alt={nationality}
        style={{ width: '40px', height: 'auto' }}
      />
    </Box>
  );
};

export default FlagDisplay;

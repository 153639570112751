import React, { useState, useEffect } from 'react';
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Box,
  Collapse,
  useMediaQuery,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LabelIcon from '@mui/icons-material/Label';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SecurityIcon from '@mui/icons-material/Security';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GroupsIcon from '@mui/icons-material/Groups';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import HubIcon from '@mui/icons-material/Hub';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArticleIcon from '@mui/icons-material/Article'; // <--- NOUVELLE ICÔNE POUR "Noticias"

import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

function Layout({ onLogout }) {
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [crearOpen, setCrearOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setMenuOpen(isLargeScreen);
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUser(userData);
    } else {
      navigate('/login');
    }
  }, [navigate, isLargeScreen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  /**
   * Renvoie la liste des items de menu en fonction du rôle de l'utilisateur.
   */
  const getMenuItems = () => {
    if (!user) return [];

    switch (user.role) {
      case 'super admin':
        // Ajout de "Noticias" après "Seguimiento"
        return [
          'Menu',
          'Jugador@s',
          'Proyectos VBC',
          'Informe',
          'INTEL',
          'Rankings',
          'Seguimiento',
          'Noticias',      // <--- NOUVEAU
          'Crear',
          'Configuración',
        ];
      case 'admin pro':
        return [
          'Menu',
          'Jugador@s',
          'Proyectos VBC',
          'Informe',
          'INTEL',
          'Rankings',
          'Seguimiento',
          'Noticias',      // <--- NOUVEAU
          'Crear',
        ];
      case 'admin cantera':
        return [
          'Menu',
          'Jugador@s',
          'Proyectos VBC',
          'Informe',
          'INTEL',
          'Rankings',
          'Seguimiento',
          'Crear',
        ];
      case 'entrenador':
        return [
          'Jugador@s',
          'Informe',
          'INTEL',
          'Rankings',
          'Seguimiento',
          'Crear',
        ];
      case 'PDP':
        return [
          'Proyectos VBC',
          'Informe',
          'INTEL',
          'Rankings',
          'Seguimiento',
          'Crear',
        ];
      default:
        return [];
    }
  };

  /**
   * Renvoie la liste des items pour la configuration (paramètres) selon le rôle.
   */
  const getSettingsMenuItems = () => {
    if (user?.role === 'super admin') {
      return [
        { name: 'Usuarios', icon: GroupIcon, path: 'usuarios' },
        { name: 'Grupos', icon: HubIcon, path: 'grupos' },
        { name: 'Tags', icon: LabelIcon, path: 'tags' },
        { name: 'Importar', icon: FileUploadIcon, path: 'importar' },
      ];
    } else {
      return [
        { name: 'Grupos', icon: HubIcon, path: 'grupos' },
        { name: 'Tags', icon: LabelIcon, path: 'tags' },
        { name: 'Importar', icon: FileUploadIcon, path: 'importar' },
      ];
    }
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  const toggleCrear = () => {
    setCrearOpen(!crearOpen);
  };

  /**
   * Gère la logique de navigation + mise à jour de l'élément actif lors du clic.
   */
  const handleMenuItemClick = (item, type) => {
    setActiveMenuItem(item);

    switch (item) {
      // Menu principal
      case 'Menu':
        navigate('/menu');
        break;
      case 'Jugador@s':
        navigate('/jugadores');
        break;
      case 'Proyectos VBC':
        navigate('/tecnification');
        break;
      case 'Informe':
        navigate('/buscar-informe');
        break;
      case 'INTEL':
        navigate('/buscar-intel');
        break;
      case 'Rankings':
        // Parent (recherche)
        if (type === 'search') {
          navigate('/buscar-bigboard');
        } else {
          navigate('/big-board');
        }
        break;
      case 'Seguimiento':
        if (type === 'search') {
          navigate('/buscar-seguimiento');
        } else {
          navigate('/seguimiento');
        }
        break;

      // >> NOUVEAU CASE POUR NOTICIAS <<
      case 'Noticias':
        navigate('/news');
        break;

      // Configuration
      case 'Configuración':
        // Géré par toggleSettings(), on ne navigue pas
        break;
      case 'Usuarios':
        navigate('/usuarios');
        break;
      case 'Grupos':
        navigate('/grupos');
        break;
      case 'Tags':
        navigate('/tags');
        break;
      case 'Importar':
        navigate('/importar');
        break;

      // Menu Créar
      case 'Crear':
        // Géré par toggleCrear(), on ne navigue pas
        break;
      case 'Crear Jugador':
        navigate('/crear-jugador');
        break;
      case 'Crear Ficha':
        navigate('/crear-ficha');
        break;

      // Sous-menu Rankings (Créer)
      case 'Rankings (Crear)':
        navigate('/big-board');
        break;

      // Sous-menu Seguimiento (Créer)
      case 'Seguimiento (Crear)':
        navigate('/seguimiento');
        break;

      default:
        // Cas général (si vous aviez d'autres items)
        navigate(`/${item.toLowerCase().replace(/\s+/g, '-')}`);
    }
  };

  /**
   * Retourne l'URL de l'avatar de l'utilisateur
   */
  const getAvatarUrl = (userId) => {
    const url = userId
      ? `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`
      : '/path/to/default-avatar.png';
    return url;
  };

  const handleAvatarClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    onLogout();
  };

  /**
   * Gère le clic sur le logo Valencia App.
   * Redirige l'utilisateur vers la page d'accueil en fonction de son rôle.
   */
  const handleLogoClick = () => {
    if (!user) return;
    if (user.role === 'entrenador') {
      navigate('/jugadores');
    } else if (user.role === 'PDP') {
      navigate('/tecnification');
    } else {
      navigate('/menu');
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: 1201, backgroundColor: '#F25F29' }}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* MENU BURGER + LOGO */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={toggleMenu}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <img
                src="/assets/valenciaapp.png"
                alt="Valencia App Logo"
                style={{
                  height: '60px',
                  width: 'auto',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                onClick={handleLogoClick}
              />
            </Box>
          </Box>

          {/* AVATAR UTILISATEUR */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              onClick={handleAvatarClick}
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
              <span style={{ paddingRight: '10px' }}>
                {user ? `${user.prenom} ${user.nom}` : ''}
              </span>
              <Avatar
                src={getAvatarUrl(user?.id)}
                alt="Avatar"
                sx={{ marginRight: '10px' }}
              />
            </Box>
          </Box>
          <Menu
            id="avatar-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            onClick={(e) => e.stopPropagation()}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ mt: 1 }}
          >
            <MenuItem onClick={handleLogoutClick}>Desconexión</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* DRAWER GAUCHE */}
      <Drawer
        variant={isLargeScreen ? 'persistent' : 'temporary'}
        anchor="left"
        open={menuOpen}
        onClose={toggleMenu}
        sx={{
          width: 25,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 200,
            boxSizing: 'border-box',
            backgroundColor: '#F25F29',
            color: '#ffffff',
            top: 64,
            height: 'calc(100% - 64px)',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Toolbar />
        <List
          sx={{
            overflowY: 'auto',
            flex: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'rgba(255, 255, 255, 0.1)',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.3)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'rgba(255, 255, 255, 0.5)',
            },
          }}
        >
          {getMenuItems().map((item, index) => {
            // ITEM CONFIGURACIÓN AVEC SOUS-MENU
            if (item === 'Configuración') {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    button
                    onClick={() => {
                      setActiveMenuItem(item);
                      toggleSettings();
                    }}
                    sx={{
                      color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                      '&:hover': { color: '#FFCC80' },
                      cursor: 'pointer',
                    }}
                  >
                    <SettingsIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                    <ListItemText primary={item} />
                    {settingsOpen ? (
                      <ExpandLessIcon sx={{ color: '#ffffff' }} />
                    ) : (
                      <ExpandMoreIcon sx={{ color: '#ffffff' }} />
                    )}
                  </ListItem>
                  <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {getSettingsMenuItems().map((menuItem, idx) => (
                        <ListItem
                          button
                          key={idx}
                          sx={{
                            pl: 4,
                            cursor: 'pointer',
                            color:
                              activeMenuItem === menuItem.name
                                ? '#FFCC80'
                                : '#ffffff',
                            '&:hover': { color: '#FFCC80' },
                          }}
                          onClick={() => handleMenuItemClick(menuItem.name)}
                        >
                          <menuItem.icon
                            sx={{
                              color:
                                activeMenuItem === menuItem.name
                                  ? '#FFCC80'
                                  : '#ffffff',
                              mr: 2,
                            }}
                          />
                          <ListItemText
                            primary={menuItem.name}
                            sx={{
                              color:
                                activeMenuItem === menuItem.name
                                  ? '#FFCC80'
                                  : '#ffffff',
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }

            // ITEM CRÉAR AVEC SOUS-MENU
            if (item === 'Crear') {
              return (
                <React.Fragment key={index}>
                  <ListItem
                    button
                    onClick={() => {
                      setActiveMenuItem(item);
                      toggleCrear();
                    }}
                    sx={{
                      color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                      '&:hover': { color: '#FFCC80' },
                      cursor: 'pointer',
                    }}
                  >
                    <AddCircleIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                    <ListItemText primary={item} />
                    {crearOpen ? (
                      <ExpandLessIcon sx={{ color: '#ffffff' }} />
                    ) : (
                      <ExpandMoreIcon sx={{ color: '#ffffff' }} />
                    )}
                  </ListItem>
                  <Collapse in={crearOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        sx={{
                          pl: 4,
                          cursor: 'pointer',
                          color:
                            activeMenuItem === 'Crear Jugador'
                              ? '#FFCC80'
                              : '#ffffff',
                          '&:hover': { color: '#FFCC80' },
                        }}
                        onClick={() => handleMenuItemClick('Crear Jugador')}
                      >
                        <PersonIcon
                          sx={{
                            color:
                              activeMenuItem === 'Crear Jugador'
                                ? '#FFCC80'
                                : '#ffffff',
                            mr: 2,
                          }}
                        />
                        <ListItemText
                          primary="Jugador"
                          sx={{
                            color:
                              activeMenuItem === 'Crear Jugador'
                                ? '#FFCC80'
                                : '#ffffff',
                          }}
                        />
                      </ListItem>

                      <ListItem
                        button
                        sx={{
                          pl: 4,
                          cursor: 'pointer',
                          color:
                            activeMenuItem === 'Crear Ficha'
                              ? '#FFCC80'
                              : '#ffffff',
                          '&:hover': { color: '#FFCC80' },
                        }}
                        onClick={() => handleMenuItemClick('Crear Ficha')}
                      >
                        <AssignmentIcon
                          sx={{
                            color:
                              activeMenuItem === 'Crear Ficha'
                                ? '#FFCC80'
                                : '#ffffff',
                            mr: 2,
                          }}
                        />
                        <ListItemText
                          primary="Ficha"
                          sx={{
                            color:
                              activeMenuItem === 'Crear Ficha'
                                ? '#FFCC80'
                                : '#ffffff',
                          }}
                        />
                      </ListItem>

                      {/* Sous-entrée "Rankings (Crear)" */}
                      <ListItem
                        button
                        sx={{
                          pl: 4,
                          cursor: 'pointer',
                          color:
                            activeMenuItem === 'Rankings (Crear)'
                              ? '#FFCC80'
                              : '#ffffff',
                          '&:hover': { color: '#FFCC80' },
                        }}
                        onClick={() =>
                          handleMenuItemClick('Rankings (Crear)', 'create')
                        }
                      >
                        <ViewKanbanIcon
                          sx={{
                            color:
                              activeMenuItem === 'Rankings (Crear)'
                                ? '#FFCC80'
                                : '#ffffff',
                            mr: 2,
                          }}
                        />
                        <ListItemText
                          primary="Rankings"
                          sx={{
                            color:
                              activeMenuItem === 'Rankings (Crear)'
                                ? '#FFCC80'
                                : '#ffffff',
                          }}
                        />
                      </ListItem>

                      {/* Sous-entrée "Seguimiento (Crear)" */}
                      <ListItem
                        button
                        sx={{
                          pl: 4,
                          cursor: 'pointer',
                          color:
                            activeMenuItem === 'Seguimiento (Crear)'
                              ? '#FFCC80'
                              : '#ffffff',
                          '&:hover': { color: '#FFCC80' },
                        }}
                        onClick={() =>
                          handleMenuItemClick('Seguimiento (Crear)', 'create')
                        }
                      >
                        <VisibilityIcon
                          sx={{
                            color:
                              activeMenuItem === 'Seguimiento (Crear)'
                                ? '#FFCC80'
                                : '#ffffff',
                            mr: 2,
                          }}
                        />
                        <ListItemText
                          primary="Seguimiento"
                          sx={{
                            color:
                              activeMenuItem === 'Seguimiento (Crear)'
                                ? '#FFCC80'
                                : '#ffffff',
                          }}
                        />
                      </ListItem>
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            }

            // ITEM SANS SOUS-MENU (Menu principal)
            return (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() =>
                    handleMenuItemClick(
                      item,
                      item === 'Rankings' || item === 'Seguimiento'
                        ? 'search'
                        : null
                    )
                  }
                  sx={{
                    color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                    '&:hover': { color: '#FFCC80' },
                    cursor: 'pointer',
                  }}
                >
                  {item === 'Menu' && (
                    <DashboardIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}
                  {item === 'Jugador@s' && (
                    <GroupsIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}
                  {item === 'Proyectos VBC' && (
                    <SportsBasketballIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}
                  {item === 'Rankings' && (
                    <ViewKanbanIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}
                  {item === 'Seguimiento' && (
                    <VisibilityIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}
                  {item === 'Informe' && (
                    <AssignmentIndIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}
                  {item === 'INTEL' && (
                    <SecurityIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}

                  {/* NOUVEAU ITEM NOTICIAS */}
                  {item === 'Noticias' && (
                    <ArticleIcon
                      sx={{
                        color: activeMenuItem === item ? '#FFCC80' : '#ffffff',
                        mr: 2,
                      }}
                    />
                  )}

                  <ListItemText primary={item} />
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      </Drawer>

      {/* CONTENU PRINCIPAL */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          transition: 'margin 0.3s ease',
          marginLeft: isLargeScreen && menuOpen ? '200px' : '0px',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;

// components/ExperienciaProfesional.js

import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Autocomplete
} from '@mui/material';
import { Clear } from '@mui/icons-material';

/**
 * Componente para la sección "4. Experiencia Profesional":
 *  - Año del Draft, Posición en el Draft, Equipo Pre-Draft
 *  - PIT, Eurocamp, SL Vegas
 *  - Experiencia NBA, ACB, Europa, Fuera, NCAA
 */

function ExperienciaProfesional({
  player,
  editablePlayer,
  setEditablePlayer,
  isEditing,
  isMobile,
  teams,            // Array de equipos (para 'Pre-Draft Team')
  handleChange      // Función genérica para onChange
}) {
  // Para limpiar selects con un botón de Clear
  const clearSelectValue = (e, fieldName) => {
    e.stopPropagation();
    setEditablePlayer((prev) => ({
      ...prev,
      [fieldName]: ''
    }));
  };

  // === Render para MOBILE ===
  if (isMobile) {
    return (
      <Box
        sx={{
          mb: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            px: 2,
            py: 1,
            backgroundColor: 'background.paper'
          }}
        >
          4. Experiencia Profesional
        </Typography>

        <Box sx={{ p: 2 }}>
          {isEditing ? (
            <Grid container spacing={2}>
              {/** Año del Draft, Posición en el Draft, Equipo Pre-Draft */}
              <Grid item xs={12}>
                <TextField
                  label="Año del Draft"
                  name="añoDraft"
                  type="number"
                  value={editablePlayer.añoDraft || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Posición en el Draft</InputLabel>
                  <Select
                    name="posicionDraft"
                    value={editablePlayer.posicionDraft || ''}
                    onChange={handleChange}
                    label="Posición en el Draft"
                    endAdornment={
                      editablePlayer.posicionDraft && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'posicionDraft')}
                          sx={{ position: 'absolute', right: 32 }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    {/** 1st round: picks #1 - #30 */}
                    {[...Array(30)].map((_, index) => {
                      const pickNum = index + 1;
                      return (
                        <MenuItem key={pickNum} value={`1st Round, #${pickNum}`}>
                          1st Round, #{pickNum}
                        </MenuItem>
                      );
                    })}

                    {/** 2nd round: picks #31 - #60 */}
                    {[...Array(30)].map((_, index) => {
                      const pickNum = index + 31;
                      return (
                        <MenuItem key={pickNum} value={`2nd Round, #${pickNum}`}>
                          2nd Round, #{pickNum}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={teams}
                  inputValue={editablePlayer.equipoPreDraft || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      equipoPreDraft: newInputValue
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pre-Draft Team" fullWidth />
                  )}
                />
              </Grid>

              {/** PIT, Eurocamp, SL Vegas */}
              <Grid item xs={12}>
                <TextField
                  label="PIT"
                  name="pit"
                  type="number"
                  value={editablePlayer.pit || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Eurocamp"
                  name="eurocamp"
                  type="number"
                  value={editablePlayer.eurocamp || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="SL Vegas"
                  name="slvegas"
                  type="number"
                  value={editablePlayer.slvegas || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              {/** Experiencias: NBA, ACB, Europa, Fuera, NCAA */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* Experiencia NBA */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia NBA</InputLabel>
                      <Select
                        name="nbaExperience"
                        value={editablePlayer.nbaExperience || ''}
                        onChange={handleChange}
                        label="Experiencia NBA"
                        endAdornment={
                          editablePlayer.nbaExperience && (
                            <IconButton
                              onClick={(e) => clearSelectValue(e, 'nbaExperience')}
                              sx={{ position: 'absolute', right: 32 }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Experiencia ACB */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia ACB</InputLabel>
                      <Select
                        name="experienciaAcb"
                        value={editablePlayer.experienciaAcb || ''}
                        onChange={handleChange}
                        label="Experiencia ACB"
                        endAdornment={
                          editablePlayer.experienciaAcb && (
                            <IconButton
                              onClick={(e) => clearSelectValue(e, 'experienciaAcb')}
                              sx={{ position: 'absolute', right: 32 }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Experiencia Europa */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia Europa</InputLabel>
                      <Select
                        name="experiencaEuropa"
                        value={editablePlayer.experiencaEuropa || ''}
                        onChange={handleChange}
                        label="Experiencia Europa"
                        endAdornment={
                          editablePlayer.experiencaEuropa && (
                            <IconButton
                              onClick={(e) => clearSelectValue(e, 'experiencaEuropa')}
                              sx={{ position: 'absolute', right: 32 }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Experiencia Fuera de su País */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia Fuera de su País</InputLabel>
                      <Select
                        name="experienca_fuera"
                        value={editablePlayer.experienca_fuera || ''}
                        onChange={handleChange}
                        label="Experiencia Fuera de su País"
                        endAdornment={
                          editablePlayer.experienca_fuera && (
                            <IconButton
                              onClick={(e) => clearSelectValue(e, 'experienca_fuera')}
                              sx={{ position: 'absolute', right: 32 }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Experiencia NCAA */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia NCAA</InputLabel>
                      <Select
                        name="experienca_ncaa"
                        value={editablePlayer.experienca_ncaa || ''}
                        onChange={handleChange}
                        label="Experiencia NCAA"
                        endAdornment={
                          editablePlayer.experienca_ncaa && (
                            <IconButton
                              onClick={(e) => clearSelectValue(e, 'experienca_ncaa')}
                              sx={{ position: 'absolute', right: 32 }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            // READ ONLY (MOBILE)
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {(!!player.añoDraft || !!player.posicionDraft || !!player.equipoPreDraft) && (
                <Box>
                  {!!player.añoDraft && (
                    <Typography>
                      <strong>Año del Draft:</strong> {player.añoDraft}
                    </Typography>
                  )}
                  {!!player.posicionDraft && (
                    <Typography>
                      <strong>Posición en el Draft:</strong> {player.posicionDraft}
                    </Typography>
                  )}
                  {!!player.equipoPreDraft && (
                    <Typography>
                      <strong>Pre-Draft Team:</strong> {player.equipoPreDraft}
                    </Typography>
                  )}
                </Box>
              )}

              {(!!player.pit || !!player.eurocamp || !!player.slvegas) && (
                <Box>
                  {!!player.pit && (
                    <Typography>
                      <strong>PIT:</strong> {player.pit}
                    </Typography>
                  )}
                  {!!player.eurocamp && (
                    <Typography>
                      <strong>Eurocamp:</strong> {player.eurocamp}
                    </Typography>
                  )}
                  {!!player.slvegas && (
                    <Typography>
                      <strong>SL Vegas:</strong> {player.slvegas}
                    </Typography>
                  )}
                </Box>
              )}

              {(!!player.nbaExperience ||
                !!player.experienciaAcb ||
                !!player.experiencaEuropa ||
                !!player.experienca_fuera ||
                !!player.experienca_ncaa) && (
                <Box>
                  {!!player.nbaExperience && (
                    <Typography>
                      <strong>Experiencia NBA:</strong> {player.nbaExperience}
                    </Typography>
                  )}
                  {!!player.experienciaAcb && (
                    <Typography>
                      <strong>Experiencia ACB:</strong> {player.experienciaAcb}
                    </Typography>
                  )}
                  {!!player.experiencaEuropa && (
                    <Typography>
                      <strong>Experiencia Europa:</strong> {player.experiencaEuropa}
                    </Typography>
                  )}
                  {!!player.experienca_fuera && (
                    <Typography>
                      <strong>Experiencia Fuera de su País:</strong> {player.experienca_fuera}
                    </Typography>
                  )}
                  {!!player.experienca_ncaa && (
                    <Typography>
                      <strong>Experiencia NCAA:</strong> {player.experienca_ncaa}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  // === Render para DESKTOP ===
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        4. Experiencia Profesional
      </Typography>

      {isEditing ? (
        <Grid container spacing={2}>
          {/** Año del Draft, Posición en el Draft, Equipo Pre-Draft */}
          <Grid item xs={12} md={4}>
            <TextField
              label="Año del Draft"
              name="añoDraft"
              type="number"
              value={editablePlayer.añoDraft || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Posición en el Draft</InputLabel>
              <Select
                name="posicionDraft"
                value={editablePlayer.posicionDraft || ''}
                onChange={handleChange}
                label="Posición en el Draft"
                endAdornment={
                  editablePlayer.posicionDraft && (
                    <IconButton
                      onClick={(e) => clearSelectValue(e, 'posicionDraft')}
                      sx={{ position: 'absolute', right: 32 }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )
                }
              >
                {/** 1st round #1 - #30 */}
                {[...Array(30)].map((_, index) => {
                  const pickNum = index + 1;
                  return (
                    <MenuItem key={pickNum} value={`1st Round, #${pickNum}`}>
                      1st Round, #{pickNum}
                    </MenuItem>
                  );
                })}
                {/** 2nd round #31 - #60 */}
                {[...Array(30)].map((_, index) => {
                  const pickNum = index + 31;
                  return (
                    <MenuItem key={pickNum} value={`2nd Round, #${pickNum}`}>
                      2nd Round, #{pickNum}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              freeSolo
              options={teams}
              inputValue={editablePlayer.equipoPreDraft || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  equipoPreDraft: newInputValue
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Pre-Draft Team" fullWidth />
              )}
            />
          </Grid>

          {/** PIT, Eurocamp, SL Vegas */}
          <Grid item xs={12} md={4}>
            <TextField
              label="PIT"
              name="pit"
              type="number"
              value={editablePlayer.pit || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Eurocamp"
              name="eurocamp"
              type="number"
              value={editablePlayer.eurocamp || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="SL Vegas"
              name="slvegas"
              type="number"
              value={editablePlayer.slvegas || ''}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          {/** Experiencias: NBA, ACB, Europa, Fuera, NCAA */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Experiencia NBA */}
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Experiencia NBA</InputLabel>
                  <Select
                    name="nbaExperience"
                    value={editablePlayer.nbaExperience || ''}
                    onChange={handleChange}
                    label="Experiencia NBA"
                    endAdornment={
                      editablePlayer.nbaExperience && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'nbaExperience')}
                          sx={{ position: 'absolute', right: 32 }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Experiencia ACB */}
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Experiencia ACB</InputLabel>
                  <Select
                    name="experienciaAcb"
                    value={editablePlayer.experienciaAcb || ''}
                    onChange={handleChange}
                    label="Experiencia ACB"
                    endAdornment={
                      editablePlayer.experienciaAcb && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'experienciaAcb')}
                          sx={{ position: 'absolute', right: 32 }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Experiencia Europa */}
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Experiencia Europa</InputLabel>
                  <Select
                    name="experiencaEuropa"
                    value={editablePlayer.experiencaEuropa || ''}
                    onChange={handleChange}
                    label="Experiencia Europa"
                    endAdornment={
                      editablePlayer.experiencaEuropa && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'experiencaEuropa')}
                          sx={{ position: 'absolute', right: 32 }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Experiencia Fuera de su País */}
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Experiencia Fuera de su País</InputLabel>
                  <Select
                    name="experienca_fuera"
                    value={editablePlayer.experienca_fuera || ''}
                    onChange={handleChange}
                    label="Experiencia Fuera de su País"
                    endAdornment={
                      editablePlayer.experienca_fuera && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'experienca_fuera')}
                          sx={{ position: 'absolute', right: 32 }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Experiencia NCAA */}
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Experiencia NCAA</InputLabel>
                  <Select
                    name="experienca_ncaa"
                    value={editablePlayer.experienca_ncaa || ''}
                    onChange={handleChange}
                    label="Experiencia NCAA"
                    endAdornment={
                      editablePlayer.experienca_ncaa && (
                        <IconButton
                          onClick={(e) => clearSelectValue(e, 'experienca_ncaa')}
                          sx={{ position: 'absolute', right: 32 }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value="SI">SI</MenuItem>
                    <MenuItem value="NO">NO</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // READ ONLY (DESKTOP)
        <Box sx={{ mt: 1 }}>
          {(!!player.añoDraft || !!player.posicionDraft || !!player.equipoPreDraft) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.añoDraft && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Año del Draft:&nbsp;</strong>
                  {player.añoDraft}
                </Typography>
              )}
              {!!player.posicionDraft && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Posición en el Draft:&nbsp;</strong>
                  {player.posicionDraft}
                </Typography>
              )}
              {!!player.equipoPreDraft && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Pre-Draft Team:&nbsp;</strong>
                  {player.equipoPreDraft}
                </Typography>
              )}
            </Box>
          )}

          {(!!player.pit || !!player.eurocamp || !!player.slvegas) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.pit && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>PIT:&nbsp;</strong>
                  {player.pit}
                </Typography>
              )}
              {!!player.eurocamp && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Eurocamp:&nbsp;</strong>
                  {player.eurocamp}
                </Typography>
              )}
              {!!player.slvegas && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>SL Vegas:&nbsp;</strong>
                  {player.slvegas}
                </Typography>
              )}
            </Box>
          )}

          {(!!player.nbaExperience ||
            !!player.experienciaAcb ||
            !!player.experiencaEuropa ||
            !!player.experienca_fuera ||
            !!player.experienca_ncaa) && (
            <Box sx={{ display: 'flex', gap: 4 }}>
              {!!player.nbaExperience && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Experiencia NBA:&nbsp;</strong>
                  {player.nbaExperience}
                </Typography>
              )}
              {!!player.experienciaAcb && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Experiencia ACB:&nbsp;</strong>
                  {player.experienciaAcb}
                </Typography>
              )}
              {!!player.experiencaEuropa && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Experiencia Europa:&nbsp;</strong>
                  {player.experiencaEuropa}
                </Typography>
              )}
              {!!player.experienca_fuera && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Experiencia Fuera de su País:&nbsp;</strong>
                  {player.experienca_fuera}
                </Typography>
              )}
              {!!player.experienca_ncaa && (
                <Typography sx={{ display: 'flex', flex: 1 }}>
                  <strong style={{ minWidth: 'auto' }}>Experiencia NCAA:&nbsp;</strong>
                  {player.experienca_ncaa}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}

export default ExperienciaProfesional;

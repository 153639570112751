import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import axios from 'axios';

const Importar = () => {
  const [formData, setFormData] = useState({
    login: '',
    password: '',
    details: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [playersData, setPlayersData] = useState([]);
  const [logs, setLogs] = useState([]);

  // Pour la fenêtre de dialogue (résultats multiples)
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlayerOptions, setSelectedPlayerOptions] = useState([]);
  const [currentPlayerName, setCurrentPlayerName] = useState('');

  // États et références pour la conversion speech-to-text
  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  // Variables d'environnement (définies dans votre .env par exemple)
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // =========================
  // Fonctions Speech-to-Text
  // =========================
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        audioChunksRef.current = [];
        await sendAudioForTranscription(audioBlob);
      };
      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error('Erreur lors de la capture audio:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const sendAudioForTranscription = async (audioBlob) => {
    const formDataAudio = new FormData();
    formDataAudio.append('audio', audioBlob, 'recording.wav');
    try {
      const response = await axios.post(`${backendUrl}/api/importar/speech-to-text`, formDataAudio, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      const transcription = response.data.transcription;
      // Ajoute la transcription au champ "Detalles"
      setFormData((prev) => ({ ...prev, details: prev.details + '\n' + transcription }));
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'audio au serveur:", error);
    }
  };

  // =========================
  // Import Eurobasket (REST)
  // =========================
  const handleEurobasketImport = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    setPlayersData([]);

    try {
      const response = await axios.post(`${backendUrl}/api/importar`, {
        ...formData,
        section,
        source: 'eurobasket'
      });
      setSuccess(true);
      setPlayersData(response.data.players);
    } catch (err) {
      setError(err.response?.data?.message || 'Erreur lors de l\'importation Eurobasket');
    } finally {
      setLoading(false);
    }
  };

  // =========================
  // Import Proballers (SSE)
  // =========================
  const handleProballersImport = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    setPlayersData([]);
    setLogs([]);

    const url = `${backendUrl}/api/importar/proballers?details=${encodeURIComponent(
      formData.details
    )}&section=${encodeURIComponent(section)}`;

    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'log') {
          setLogs((prevLogs) => [...prevLogs, data.message]);
        } else if (data.type === 'done') {
          setPlayersData(data.players);
          setLoading(false);
          setSuccess(true);
          eventSource.close();
        } else if (data.type === 'error') {
          setError(data.message);
          setLoading(false);
          eventSource.close();
        }
      } catch (parseError) {
        console.error('Erreur de parsing SSE:', parseError);
      }
    };

    eventSource.onerror = (err) => {
      console.error('EventSource erreur:', err);
      setError('Erreur de connexion SSE');
      setLoading(false);
      eventSource.close();
    };
  };

  // =========================
  // Gestion des résultats multiples
  // =========================
  const handleViewOptions = (playerNom, options) => {
    setCurrentPlayerName(playerNom);
    setSelectedPlayerOptions(options);
    setOpenDialog(true);
  };

  const handleOptionClick = async (option) => {
    try {
      setLoading(true);
      setError(null);
      setSuccess(false);
      let finalUrl;
      let isEurobasket = false;

      if (option.link) {
        finalUrl = option.link;
        isEurobasket = true;
      } else if (option.href) {
        finalUrl = 'https://www.proballers.com' + option.href;
      } else {
        throw new Error('Option invalide: aucun lien ni href.');
      }

      if (isEurobasket) {
        const resp = await axios.post(`${backendUrl}/api/importar/player`, {
          url: finalUrl,
          login: formData.login,
          password: formData.password,
          section
        });
        if (resp.data.success) {
          setSuccess(true);
        }
      } else {
        const resp = await axios.post(`${backendUrl}/api/importar/proballers/player`, {
          url: finalUrl,
          section
        });
        if (resp.data.success) {
          setSuccess(true);
        }
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Erreur lors de l\'importation du joueur sélectionné');
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Importación de Datos
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Importación realizada con éxito
          </Alert>
        )}

        {/* Affichage des logs SSE en direct (Proballers) */}
        {logs.length > 0 && (
          <Paper sx={{ p: 2, mb: 2, maxHeight: 200, overflowY: 'auto' }}>
            {logs.map((line, i) => (
              <Typography variant="body2" key={i}>
                {line}
              </Typography>
            ))}
          </Paper>
        )}

        <form>
          <TextField
            fullWidth
            label="Login Eurobasket"
            name="login"
            value={formData.login}
            onChange={handleChange}
            margin="normal"
            required
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Contraseña Eurobasket"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            margin="normal"
            required
            variant="outlined"
          />

         

          <TextField
            fullWidth
            label="Detalles"
            name="details"
            value={formData.details}
            onChange={handleChange}
            margin="normal"
            required
            multiline
            rows={6}
            variant="outlined"
          />

          <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
            

            <Button
              onClick={handleProballersImport}
              variant="contained"
              sx={{
                flex: 1,
                backgroundColor: '#4CAF50',
                '&:hover': { backgroundColor: '#388E3C' }
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Importar '
              )}
            </Button>
          </Box>
        </form>

        {/* Affichage des résultats après l'import */}
        {playersData.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6">Resultados:</Typography>
            {playersData.map((player, index) => (
              <Box key={index} mt={2}>
                {player.multiple ? (
                  <>
                    <Typography variant="subtitle1">
                      {`Múltiples jugador@s encontrados para ${player.nom}:`}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => handleViewOptions(player.nom, player.options)}
                    >
                      Ver opciones
                    </Button>
                  </>
                ) : player.found === false ? (
                  <Typography variant="subtitle1">
                    {`No se encontró jugador para ${player.nom}`}
                  </Typography>
                ) : player.alreadyInDB ? (
                  <Typography variant="subtitle1" color="warning.main">
                    {`Jugador ${player.nom} ya existe en la base de datos`}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" color="success.main">
                    {`Jugador ${player.nom} importado con éxito`}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}

        {/* Dialog pour afficher les options en cas de résultats multiples */}
        <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogTitle>{`Opciones para ${currentPlayerName}`}</DialogTitle>
          <DialogContent>
            <List>
              {selectedPlayerOptions.map((option, idx) => (
                <ListItem key={idx} disablePadding>
                  <ListItemButton onClick={() => handleOptionClick(option)}>
                    <ListItemText
                      primary={option.name || option.text}
                      secondary={option.link || option.href}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};

export default Importar;

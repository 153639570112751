import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Collapse,
  Chip,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Autocomplete,
  MenuItem,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// === Llamada ejemplo para el Autocomplete de equipos 
//     (suponemos un endpoint GET /api/players/teams 
//     que devuelve { teams: [...] } con la lista de equipos).
const fetchTeams = async (backendUrl, section) => {
  const res = await axios.get(`${backendUrl}/api/players/teams`, {
    params: { section },
  });
  return res.data?.teams || [];
};

// === Llamada para el Autocomplete de eventos
//     (suponemos un endpoint GET /api/informe/eventos
//     que devuelve un array de strings con los eventos).
const fetchEventos = async (backendUrl, section) => {
  const res = await axios.get(`${backendUrl}/api/informe/eventos`, {
    params: { section },
  });
  // res.data debería ser algo como ["ABA U19", "Afrobasket U16", ...]
  return res.data || [];
};

// === Construye la URL del avatar usuario
const getAvatarUrl = (userId) => {
  return `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`;
};

// === Convierte "visu" en texto
const getScoutingMethod = (visu) => {
  return visu === 'video' ? 'Video' : visu === 'live' ? 'Live' : '';
};

const InformeTab = ({ playerId, onDataLoaded }) => {
  const [informeData, setInformeData] = useState([]);
  const [openRows, setOpenRows] = useState({});

  // Para la edición
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentInforme, setCurrentInforme] = useState(null);

  // Campos editables
  const [editedDetalles, setEditedDetalles] = useState('');
  const [editedDate, setEditedDate] = useState('');
  const [editedEvento, setEditedEvento] = useState('');
  const [editedEquipo, setEditedEquipo] = useState('');
  const [editedVisu, setEditedVisu] = useState(''); // <-- Para el scouting (video / live)

  // Para la supresión
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [informeToDelete, setInformeToDelete] = useState(null);

  // Autocomplete de equipos y eventos
  const [teams, setTeams] = useState([]);
  const [eventos, setEventos] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // === Cargar informes para un jugador dado ===
  useEffect(() => {
    const fetchInformeData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/informe`, {
          params: { playerId, section },
        });

        setInformeData(response.data);

        if (onDataLoaded) {
          onDataLoaded(response.data.length > 0);
        }

        // Abrir por defecto el primer informe (si existe)
        if (response.data.length > 0) {
          setOpenRows({ [response.data[0].id]: true });
        }
      } catch (error) {
        console.error('Error al recuperar los datos del informe:', error);
      }
    };

    fetchInformeData();
  }, [playerId, backendUrl, section, onDataLoaded]);

  // === Cargar equipos (para Autocomplete de "Equipo") ===
  useEffect(() => {
    const loadTeams = async () => {
      try {
        const _teams = await fetchTeams(backendUrl, section);
        setTeams(_teams);
      } catch (error) {
        console.error('Error al recuperar los equipos:', error);
      }
    };
    loadTeams();
  }, [backendUrl, section]);

  // === Cargar eventos (para Autocomplete de "Evento") ===
  useEffect(() => {
    const loadEventos = async () => {
      try {
        const _eventos = await fetchEventos(backendUrl, section);
        setEventos(_eventos);
      } catch (error) {
        console.error('Error al recuperar los eventos:', error);
      }
    };
    loadEventos();
  }, [backendUrl, section]);

  // === Toggle del acordeón ===
  const toggleRow = (rowId) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  // === Edición de un informe ===
  const handleEditClick = (informe) => {
    setCurrentInforme(informe);

    // Manejar fecha (string ISO) teniendo en cuenta la zona horaria
    if (informe.fecha) {
      let dateObj = new Date(informe.fecha);
      dateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000);
      const isoDate = dateObj.toISOString().split('T')[0];
      setEditedDate(isoDate);
    } else {
      setEditedDate('');
    }

    setEditedDetalles(informe.detalles || '');
    setEditedEvento(informe.evento || '');
    setEditedEquipo(informe.equipo || '');
    setEditedVisu(informe.visu || ''); // <-- scouting

    setOpenEditDialog(true);
  };

  const handleEditSave = async () => {
    if (!currentInforme) return;

    // Limpieza opcional de HTML (para no duplicar <br>)
    const transformedDetalles = editedDetalles
      .replace(/<p><br><\/p>/g, '<br>')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '<br>')
      .replace(/<br><br>/g, '<br>');

    const updateData = {
      detalles: transformedDetalles,
      fecha: editedDate,
      evento: editedEvento,
      equipo: editedEquipo,
      visu: editedVisu, // <-- Se añade en el body
    };

    try {
      await axios.put(
        `${backendUrl}/api/informe/${currentInforme.id}`,
        updateData,
        { params: { section } }
      );

      // Actualizar localmente
      setInformeData((prev) =>
        prev.map((item) =>
          item.id === currentInforme.id
            ? {
                ...item,
                detalles: transformedDetalles,
                fecha: editedDate,
                evento: editedEvento,
                equipo: editedEquipo,
                visu: editedVisu,
              }
            : item
        )
      );

      // Si el usuario ha escrito un nuevo evento que no estaba en la lista,
      // lo añadimos localmente para no perderlo en la Autocomplete.
      if (editedEvento && !eventos.includes(editedEvento)) {
        setEventos((prev) => [...prev, editedEvento]);
      }
      // Re-cargamos la lista de eventos desde la API
      try {
        const updatedEventos = await fetchEventos(backendUrl, section);
        setEventos(updatedEventos);
      } catch (error) {
        console.error('Error while refetching eventos:', error);
      }

      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error al actualizar el informe:', error);
      alert('Error al actualizar el informe.');
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCurrentInforme(null);
  };

  // === Supresión ===
  const handleDeleteClick = (informe) => {
    setInformeToDelete(informe);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!informeToDelete) return;

    try {
      await axios.delete(`${backendUrl}/api/informe/${informeToDelete.id}`, {
        params: { section },
      });
      // On met à jour localement
      setInformeData((prev) => prev.filter((item) => item.id !== informeToDelete.id));
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error al eliminar el informe:', error);
      alert('Error al eliminar el informe.');
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setInformeToDelete(null);
  };

  // === Si pas de informes ===
  if (!informeData.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h6">No hay datos disponibles</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        [theme.breakpoints.down('sm')]: {
          p: 1,
        },
      }}
    >
      {informeData.map((row) => (
        <Card
          key={row.id}
          sx={{
            mb: 2,
            '&:last-child': { mb: 0 },
          }}
        >
          <CardContent
            sx={{
              p: 0,
              '&:last-child': { pb: 0 },
            }}
          >
            {/* Cabecera de la card */}
            <Box
              onClick={() => toggleRow(row.id)}
              sx={{
                p: 2,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                },
              }}
            >
              {/* Avatar + Informaciones principales */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                  minWidth: 0,
                  [theme.breakpoints.down('sm')]: {
                    mb: 1,
                  },
                }}
              >
                <Avatar
                  src={getAvatarUrl(row.usuario_id)}
                  alt={row.usuario_nombre}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ minWidth: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      flexWrap: 'wrap',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        mr: 1,
                      }}
                    >
                      {row.usuario_nombre}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ flexShrink: 0 }}
                    >
                      {new Date(row.fecha).toLocaleDateString()}
                    </Typography>
                  </Box>

                  {row.equipo && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        whiteSpace: 'normal',
                      }}
                    >
                      {row.evento}
                    </Typography>
                  )}

                  {/* Evento + etiqueta de scouting */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      flexWrap: 'wrap',
                      mt: 0.5,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                      }}
                    >
                      {row.equipo}
                    </Typography>
                    {row.visu && (
                      <Chip
                        label={`Método de Scouting: ${getScoutingMethod(row.visu)}`}
                        size="small"
                        sx={{
                          height: '20px',
                          fontSize: '0.75rem',
                          backgroundColor:
                            row.visu === 'live' ? '#e3f2fd' : '#fff3e0',
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              {/* Botones: toggle + editar + eliminar */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* Botón Editar */}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick(row);
                  }}
                  size="small"
                  sx={{ mr: 1 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>

                {/* Botón Eliminar (rojo) */}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(row);
                  }}
                  size="small"
                  sx={{ color: 'red', mr: 1 }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>

                {/* Botón para desplegar */}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleRow(row.id);
                  }}
                  size="small"
                >
                  {openRows[row.id] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </Box>
            </Box>

            {/* Contenido detallado (collapse) */}
            <Collapse in={openRows[row.id]}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: '#FFECB3',
                  borderTop: 1,
                  borderColor: 'divider',
                }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: row.detalles }}
                />
              </Box>
            </Collapse>
          </CardContent>
        </Card>
      ))}

      {/* Diálogo de edición */}
      <Dialog
        open={openEditDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') return; // Empêche la fermeture sur clic extérieur
          handleCloseEditDialog();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Modificar el informe</DialogTitle>
        <DialogContent>
          {currentInforme && (
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                {/* Fecha */}
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Fecha"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={editedDate}
                    onChange={(e) => setEditedDate(e.target.value)}
                  />
                </Grid>

                {/* Evento (Autocomplete con freeSolo) */}
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    freeSolo
                    options={eventos}
                    value={editedEvento}
                    onChange={(_, newValue) => setEditedEvento(newValue || '')}
                    onInputChange={(event, newValue) => {
                      if (event && event.type === 'change') {
                        setEditedEvento(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Evento"
                        variant="outlined"
                        placeholder="Seleccione o escriba el evento"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {/* Equipo (Autocomplete) */}
                <Grid item xs={12} sm={4}>
                <Autocomplete
  options={teams}
  freeSolo
  getOptionLabel={(option) => option || ''}
  isOptionEqualToValue={(option, value) => option === value}
  value={editedEquipo}
  onChange={(_, newValue) => setEditedEquipo(newValue)}
  onInputChange={(event, newInputValue) => {
    if (event && event.type === 'change') {
      setEditedEquipo(newInputValue);
    }
  }}
  renderOption={(props, option) => (
    <li {...props}>{option}</li>
  )}
  renderInput={(params) => (
    <TextField
      {...params} 
      label="Equipo"
      variant="outlined"
      placeholder="Seleccione o escriba el equipo"
      fullWidth
    />
  )}
/>
                </Grid>

                {/* Scouting => visu */}
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    label="Scouting"
                    value={editedVisu}
                    onChange={(e) => setEditedVisu(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="live">Live</MenuItem>
                  </TextField>
                </Grid>

                {/* Detalles (editor Quill) */}
                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                    Detalles:
                  </Typography>
                  <ReactQuill
                    theme="snow"
                    value={editedDetalles}
                    onChange={setEditedDetalles}
                    placeholder="Modificar los detalles..."
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de confirmación de eliminación */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">
          Eliminar Informe
        </DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro de que desea eliminar este informe?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InformeTab;

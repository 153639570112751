// RechercheIntel.jsx

import React, { useEffect, useState, useContext, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Grid,
  useTheme,
  useMediaQuery,
  SwipeableDrawer,
  Fab,
  Divider,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Tooltip,
  Autocomplete,
  Checkbox,
  Pagination,        // <-- Pour la pagination mobile
  TablePagination,   // <-- Pour la pagination en vue desktop
} from '@mui/material';

import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import RechercheIntelContext from './RechercheIntelContext';

// -- Configuration de la virtualisation pour Autocomplete
const LISTBOX_PADDING = 8;
function renderRow(props) {
  const { data, index, style } = props;
  const item = data[index];
  return React.cloneElement(item, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
      style={{
        ...props.style,
        overflowX: 'hidden',
        overflowY: 'scroll',
        maxWidth: '100%',
      }}
    />
  );
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
          style={{
            padding: 0,
            margin: 0,
            overflowX: 'hidden',
          }}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// -- Mapping des postes
const positionMappings = {
  Base: ['Base', 'Point Guard'],
  Escolta: ['Escolta', 'Shooting Guard', 'Guard'],
  Alero: ['Alero', 'Small Forward', 'Swingman'],
  'Ala-Pívot': ['Ala-Pívot', 'Power Forward', 'Power Forward / Center'],
  Pívot: ['Pívot', 'Center'],
};
const positionOptions = Object.keys(positionMappings);

// -- Fonctions utilitaires
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

const getAvatarUrl = (userId) => {
  return userId
    ? `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`
    : '/path/to/default-avatar.png';
};

const getPlayerAvatarUrl = (playerId) => {
  return playerId
    ? `${process.env.REACT_APP_MEDIA_URL}/photos/${playerId}.png?${new Date().getTime()}`
    : '/path/to/default-player-avatar.png';
};

const getShortNationality = (nationality) => {
  if (!nationality) return '';
  const match = nationality.match(/\((.*?)\)/);
  return match ? match[1] : nationality;
};

const getDecimalAge = (fecha, dob) => {
  if (!fecha || !dob) return '';
  const f = new Date(fecha);
  const d = new Date(dob);

  const diffMs = f - d;
  if (diffMs < 0) return '';

  const diffDays = diffMs / (1000 * 60 * 60 * 24);
  const diffYears = diffDays / 365;

  return diffYears.toFixed(1);
};

// -- Composant carte (Mobile)
const MobileIntelCard = ({ intel, onEdit, onDelete, open, onToggle, user }) => {
  const theme = useTheme();
  const decimalAge = getDecimalAge(intel.fecha, intel.player_date_naissance);

  return (
    <Card sx={{ mb: 2, boxShadow: theme.shadows[2] }}>
      <CardHeader
        avatar={
          <Avatar src={getPlayerAvatarUrl(intel.player_id)} alt={intel.player_nom} />
        }
        title={
          user?.role?.toLowerCase() === 'entrenador' || user?.role?.toLowerCase() === 'pdp' ? (
            <Typography>{intel.player_nom}</Typography>
          ) : (
            <Link
              to={`/players/${intel.player_id}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {intel.player_nom}
            </Link>
          )
        }
        subheader={<Typography variant="body2">{new Date(intel.fecha).toLocaleDateString()}</Typography>}
        action={
          <IconButton onClick={() => onToggle(intel.id)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              mb: 2,
              p: 2,
              backgroundColor: '#f5f5f5',
              borderRadius: 1,
            }}
          >
            <Avatar src={getAvatarUrl(intel.usuario_id)} sx={{ width: 24, height: 24 }} />
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {intel.usuario_prenom} {intel.usuario_nom}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Posición:</strong> {intel.player_poste}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Año:</strong> {intel.player_annee_naissance} ({decimalAge})
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Nationalidad:</strong> {getShortNationality(intel.player_nationalite)}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Equipo:</strong> {intel.player_team}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Fuente:</strong> {intel.fuente}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            component="div"
            sx={{
              backgroundColor: '#FFECB3',
              p: 2,
              borderRadius: 1,
              mt: 1,
              mb: 2,
            }}
            dangerouslySetInnerHTML={{ __html: intel.detalles }}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button size="small" startIcon={<EditIcon />} onClick={() => onEdit(intel)}>
            Editar
          </Button>
          <Button
            size="small"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => onDelete(intel)}
          >
            Eliminar
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

// -- Tiroir de filtres (Mobile)
const MobileFilterDrawer = ({
  open,
  onClose,
  players,
  users,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
  anneeNaissance,
  onAnneeNaissanceChange,
  selectedPositions,
  onPositionsChange,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={players}
                getOptionLabel={(player) => player.nom}
                value={selectedPlayer}
                onChange={onPlayerChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccionar Jugador" variant="outlined" />
                )}
                renderOption={(props, player) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={getPlayerAvatarUrl(player.id)}
                      alt={player.nom}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {player.nom}
                  </Box>
                )}
                clearOnEscape
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={users}
                getOptionLabel={(user) => `${user.prenom} ${user.nom}`}
                value={selectedUser}
                onChange={onUserChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccionar Usuario" variant="outlined" />
                )}
                renderOption={(props, user) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={getAvatarUrl(user.id)}
                      alt={user.nom}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {user.prenom} {user.nom}
                  </Box>
                )}
                clearOnEscape
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Año de Nacimiento"
              variant="outlined"
              fullWidth
              value={anneeNaissance}
              onChange={onAnneeNaissanceChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={positionOptions}
              value={selectedPositions}
              onChange={onPositionsChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Seleccionar Posición" />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Checkbox checked={selectedPositions.indexOf(option) > -1} />
                  {option}
                </li>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

// -- Composant principal
const RechercheIntel = ({ onDataLoaded }) => {
  // === On récupère tout depuis le contexte ===
  const {
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    anneeNaissance,
    setAnneeNaissance,
    selectedPositions,
    setSelectedPositions,
    openRows,
    toggleRow,
  } = useContext(RechercheIntelContext);

  // État local pour les INTEL chargés
  const [allIntelData, setAllIntelData] = useState([]);
  const [intelData, setIntelData] = useState([]);

  // Édition / Suppression
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentIntel, setCurrentIntel] = useState(null);

  // On va stocker séparément les champs à éditer :
  const [editedDetalles, setEditedDetalles] = useState('');
  const [editedFuente, setEditedFuente] = useState('');
  const [editedFecha, setEditedFecha] = useState('');

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [intelToDelete, setIntelToDelete] = useState(null);

  // Filtres : listes (players, users) pour l'autocomplete
  const [players, setPlayers] = useState([]);
  const [users, setUsers] = useState([]);

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const [user, setUser] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(true);

  // --- ÉTATS POUR LA PAGINATION ---
  // On peut mettre par défaut 5 éléments/page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Récupération + filtrage initial
  useEffect(() => {
    const fetchAllIntel = async () => {
      try {
        setLoading(true);

        const params = { section };
        const response = await axios.get(`${backendUrl}/api/intel/all`, { params });

        // Récupération user
        const loggedUser = JSON.parse(localStorage.getItem('user'));
        setUser(loggedUser);

        let dataIntel = response.data || [];

        // -- Filtrage par rôle
        const role = loggedUser?.role?.toLowerCase() || '';
        if (role === 'entrenador' || role === 'pdp') {
          dataIntel = dataIntel.filter((item) => item.usuario_id === loggedUser.id);
        } else if (role === 'admin pro') {
          dataIntel = dataIntel.filter(
            (item) =>
              item.player_type?.toLowerCase() === 'pro' ||
              item.usuario_id === loggedUser.id
          );
        } else if (role === 'admin cantera') {
          dataIntel = dataIntel.filter(
            (item) =>
              item.player_type?.toLowerCase() === 'cantera' ||
              item.usuario_id === loggedUser.id
          );
        }

        setAllIntelData(dataIntel);
        setIntelData(dataIntel);

        if (onDataLoaded) {
          onDataLoaded(dataIntel.length > 0);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des Intel:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllIntel();
  }, [backendUrl, section, onDataLoaded]);

  // Filtrage local
  useEffect(() => {
    let filteredData = allIntelData;

    if (selectedPlayer) {
      filteredData = filteredData.filter(
        (intel) => intel.player_id === selectedPlayer.id
      );
    }

    if (selectedUser) {
      filteredData = filteredData.filter(
        (intel) => intel.usuario_id === selectedUser.id
      );
    }

    if (anneeNaissance) {
      filteredData = filteredData.filter(
        (intel) =>
          intel.player_annee_naissance &&
          intel.player_annee_naissance.toString().includes(anneeNaissance)
      );
    }

    if (selectedPositions.length > 0) {
      filteredData = filteredData.filter((intel) => {
        const playerPoste = intel.player_poste;
        return selectedPositions.some((posKey) =>
          positionMappings[posKey].includes(playerPoste)
        );
      });
    }

    setIntelData(filteredData);
    // On réinitialise la page quand les filtres changent
    setPage(0);
  }, [
    allIntelData,
    selectedPlayer,
    selectedUser,
    anneeNaissance,
    selectedPositions,
  ]);

  // Récupération des joueurs (pour l'autocomplete)
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/select`, {
          params: { section },
        });
        setPlayers(
          Array.isArray(response.data) ? response.data : response.data.players || []
        );
      } catch (error) {
        console.error('Erreur lors de la récupération des joueurs:', error);
        setPlayers([]);
      }
    };
    fetchPlayers();
  }, [backendUrl, section]);

  // Récupération des utilisateurs (pour l'autocomplete)
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/users`, {
          params: { section },
        });
        setUsers(
          Array.isArray(response.data) ? response.data : response.data.users || []
        );
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs:', error);
        setUsers([]);
      }
    };
    fetchUsers();
  }, [backendUrl, section]);

  // === Pagination : découpe des données ===
  const totalResults = intelData.length;

  // Calcul du sous-ensemble selon la page et le rowsPerPage
  const paginatedData = React.useMemo(() => {
    if (rowsPerPage === -1) {
      // Cas "Tous"
      return intelData;
    }
    return intelData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [intelData, page, rowsPerPage]);

  // Handlers pour TablePagination (desktop)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newVal = parseInt(event.target.value, 10);
    setRowsPerPage(newVal);
    setPage(0);
  };

  // Handler pour la Pagination mobile (1-based)
  const handleChangeMobilePage = (event, newValue) => {
    // newValue = 1, 2, 3... => on convertit en base 0
    setPage(newValue - 1);
  };

  // Libellé singular/plural
  const informesFoundLabel = totalResults === 1 ? 'INTEL encontrado' : 'INTEL encontrados';

  // -- Gestionnaires d'édition
  const handleEditClick = (intel) => {
    setCurrentIntel(intel);
    setEditedDetalles(intel.detalles);
    setEditedFuente(intel.fuente || '');

    // Conversion de la fecha en format YYYY-MM-DD pour l'input date
    const dateStr = intel.fecha ? new Date(intel.fecha).toISOString().split('T')[0] : '';
    setEditedFecha(dateStr);

    setOpenEditDialog(true);
  };

  const handleEditSave = async () => {
    try {
      // Transformation basique pour éviter trop de <p> vides
      const transformedDetalles = editedDetalles
        .replace(/<p><br><\/p>/g, '<br>')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '<br>')
        .replace(/<br><br>/g, '<br>');

      const payload = {
        detalles: transformedDetalles,
        fuente: editedFuente,
        fecha: editedFecha,
      };

      await axios.put(`${backendUrl}/api/intel/${currentIntel.id}`, payload, {
        params: { section },
      });

      // Mise à jour localement
      setAllIntelData((prevAll) =>
        prevAll.map((item) =>
          item.id === currentIntel.id
            ? {
                ...item,
                detalles: transformedDetalles,
                fuente: editedFuente,
                fecha: editedFecha,
              }
            : item
        )
      );

      setOpenEditDialog(false);
      alert('Intel actualizado con éxito!');
    } catch (error) {
      console.error('Error al actualizar el Intel:', error);
      alert('Error al actualizar el Intel.');
    }
  };

  // -- Gestionnaires de suppression
  const handleDeleteClick = (intel) => {
    setIntelToDelete(intel);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${backendUrl}/api/intel/${intelToDelete.id}`, {
        params: { section },
      });
      setAllIntelData((prevAll) => prevAll.filter((item) => item.id !== intelToDelete.id));
      setOpenDeleteDialog(false);
      alert('Intel eliminado con éxito!');
    } catch (error) {
      console.error('Error al eliminar el Intel:', error);
      alert('Error al eliminar el Intel.');
    }
  };

  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setIntelToDelete(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
        <Typography variant="h6">Cargando...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', pb: isMobile ? 7 : 0 }}>
      {isMobile ? (
        <>
          {/* ====================== VUE MOBILE ====================== */}
          <Box
            sx={{
              mx: 2,
              mt: 2,
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              p: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              {totalResults}
            </Box>
            <Typography variant="subtitle1">
              {informesFoundLabel}
            </Typography>
          </Box>

          <Box sx={{ p: 2 }}>
            {/* Affichage paginé version mobile */}
            {paginatedData.map((intel) => (
              <MobileIntelCard
                key={intel.id}
                intel={intel}
                user={user}
                open={openRows[intel.id] || false}
                onToggle={toggleRow}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
              />
            ))}
          </Box>

          {/* Pagination mobile (seulement si rowsPerPage != -1) */}
          {rowsPerPage !== -1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <Pagination
                color="primary"
                count={Math.ceil(totalResults / rowsPerPage)} // nombre total de pages
                page={page + 1} // on convertit en base 1
                onChange={handleChangeMobilePage}
              />
            </Box>
          )}

          {/* Bouton de filtre mobile */}
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab,
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterListIcon />
          </Fab>

          {/* Tiroir de filtres mobile */}
          <MobileFilterDrawer
            open={openFilterDrawer}
            onClose={() => setOpenFilterDrawer(false)}
            players={players}
            users={users}
            selectedPlayer={selectedPlayer}
            selectedUser={selectedUser}
            onPlayerChange={(_, newValue) => setSelectedPlayer(newValue)}
            onUserChange={(_, newValue) => setSelectedUser(newValue)}
            anneeNaissance={anneeNaissance}
            onAnneeNaissanceChange={(e) => setAnneeNaissance(e.target.value)}
            selectedPositions={selectedPositions}
            onPositionsChange={(_, newValues) => setSelectedPositions(newValues)}
          />
        </>
      ) : (
        <>
          {/* ====================== VUE DESKTOP ====================== */}
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <Autocomplete
                  options={players}
                  getOptionLabel={(player) => player.nom}
                  value={selectedPlayer}
                  onChange={(_, newValue) => setSelectedPlayer(newValue)}
                  ListboxComponent={ListboxComponent}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccionar Jugador" variant="outlined" />
                  )}
                  renderOption={(props, player) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={getPlayerAvatarUrl(player.id)}
                        alt={player.nom}
                        sx={{ mr: 1 }}
                      />
                      {player.nom}
                    </Box>
                  )}
                  clearOnEscape
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <Autocomplete
                  options={users}
                  getOptionLabel={(u) => `${u.prenom} ${u.nom}`}
                  value={selectedUser}
                  onChange={(_, newValue) => setSelectedUser(newValue)}
                  ListboxComponent={ListboxComponent}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccionar Usuario" variant="outlined" />
                  )}
                  renderOption={(props, u) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={getAvatarUrl(u.id)}
                        alt={u.nom}
                        sx={{ width: 30, height: 30, mr: 1 }}
                      />
                      {u.prenom} {u.nom}
                    </Box>
                  )}
                  clearOnEscape
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Año de Nacimiento"
                variant="outlined"
                fullWidth
                value={anneeNaissance}
                onChange={(e) => setAnneeNaissance(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Autocomplete
                multiple
                options={positionOptions}
                value={selectedPositions}
                onChange={(_, newValues) => setSelectedPositions(newValues)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Seleccionar Posición" />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              mb: 3,
              display: 'flex',
              alignItems: 'center',
              p: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: 48,
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}
            >
              {totalResults}
            </Box>
            <Typography variant="h6">
              {informesFoundLabel}
            </Typography>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Creador</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Jugador</TableCell>
                  <TableCell>Posición</TableCell>
                  <TableCell>Año</TableCell>
                  <TableCell>Nationalidad</TableCell>
                  <TableCell>Equipo</TableCell>
                  <TableCell>Fuente</TableCell>
                  <TableCell>Detalles</TableCell>
                  <TableCell>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row) => {
                  const decimalAge = getDecimalAge(row.fecha, row.player_date_naissance);
                  const shortNationality = getShortNationality(row.player_nationalite);

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        {/* Creador */}
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'white',
                                    '& .MuiTooltip-arrow': { color: 'white' },
                                    boxShadow: '0px 2px 8px rgba(0,0,0,0.15)',
                                  },
                                },
                              }}
                              title={
                                <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
                                  <Avatar
                                    src={getAvatarUrl(row.usuario_id)}
                                    sx={{ width: 50, height: 50 }}
                                  />
                                  <Box>
                                    <Typography
                                      variant="body1"
                                      sx={{ fontWeight: 'bold', color: 'black' }}
                                    >
                                      {row.usuario_prenom} {row.usuario_nom}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.7)' }}>
                                      {row.usuario_nombre}
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                            >
                              <Avatar
                                sx={{
                                  mr: 1,
                                  bgcolor: getAvatarColor(
                                    row.usuario_nom + row.usuario_prenom
                                  ),
                                }}
                              >
                                {getInitials(row.usuario_prenom, row.usuario_nom)}
                              </Avatar>
                            </Tooltip>
                          </Box>
                        </TableCell>

                        {/* Fecha */}
                        <TableCell>
                          {new Date(row.fecha).toLocaleDateString()}
                        </TableCell>

                        {/* Joueur */}
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={getPlayerAvatarUrl(row.player_id)}
                              alt={row.player_nom}
                              sx={{ mr: 1 }}
                            />
                            {user?.role?.toLowerCase() === 'entrenador' ||
                            user?.role?.toLowerCase() === 'pdp' ? (
                              <Typography variant="body2">{row.player_nom}</Typography>
                            ) : (
                              <Typography variant="body2">
                                <Link
                                  to={`/players/${row.player_id}`}
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  {row.player_nom}
                                </Link>
                              </Typography>
                            )}
                          </Box>
                        </TableCell>

                        {/* Posición */}
                        <TableCell>{row.player_poste}</TableCell>

                        {/* Año */}
                        <TableCell>
                          {row.player_annee_naissance}
                          {decimalAge && ` (${decimalAge})`}
                        </TableCell>

                        {/* Nationalidad */}
                        <TableCell>{shortNationality}</TableCell>

                        {/* Equipo */}
                        <TableCell>{row.player_team}</TableCell>

                        {/* Fuente */}
                        <TableCell>{row.fuente}</TableCell>

                        {/* Détails (collapse) */}
                        <TableCell>
                          <IconButton onClick={() => toggleRow(row.id)}>
                            {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>

                        {/* Actions */}
                        <TableCell>
                          <IconButton onClick={() => handleEditClick(row)} color="primary">
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteClick(row)} color="error">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {/* Ligne collapsée */}
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={100}>
                          <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Typography
                                variant="body2"
                                style={{
                                  backgroundColor: '#FFECB3',
                                  padding: '10px',
                                  borderRadius: '4px',
                                }}
                                dangerouslySetInnerHTML={{ __html: row.detalles }}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>

            {/* === Pagination desktop === */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
              component="div"
              count={totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}

      {/* Dialog d'édition */}
      <Dialog
        open={openEditDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') return; // Empêche la fermeture sur clic extérieur
          setOpenEditDialog(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Modificar el INTEL</DialogTitle>
        <DialogContent>
          {currentIntel && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                <strong>Fecha:</strong>
              </Typography>
              <TextField
                type="date"
                fullWidth
                value={editedFecha}
                onChange={(e) => setEditedFecha(e.target.value)}
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle1" gutterBottom>
                <strong>Fuente:</strong>
              </Typography>
              <TextField
                fullWidth
                value={editedFuente}
                onChange={(e) => setEditedFuente(e.target.value)}
                placeholder="Modificar la fuente..."
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle1" gutterBottom>
                <strong>Detalles:</strong>
              </Typography>
              <ReactQuill
                theme="snow"
                value={editedDetalles}
                onChange={setEditedDetalles}
                placeholder="Modificar los detalles del INTEL..."
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Eliminar Intel</DialogTitle>
        <DialogContent>
          <Typography>¿Está seguro de que desea eliminar este Intel?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RechercheIntel;
